@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.copay-summary {
  background: #e9e8ee;
  .copay-summary-heading {
    @media all and (min-width: $small-medium) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .bck-btn-wrap {
      display: flex;
      @include fontsize(16px);
      font-family: $primary-fontfamily;
      font-weight: $fw-semibold;
      align-items: center;
      background: transparent;
      color: #fff;
      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-1440) {
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }

      .icon-back {
        background: url(../../assets/images/back.svg) no-repeat;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
          saturate(0%) hue-rotate(120deg) brightness(104%) contrast(102%);
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        display: block;
        margin-right: 5px;
        margin-top: 0px;

        @media all and (min-width: $small-medium) {
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
          margin-top: 0px;
        }

        @media all and (min-width: $desktop) {
          width: 24px;
          height: 24px;
          background-size: 24px 24px;
        }

        @media all and (min-width: $desktop-xl) {
          width: 26px;
          height: 17px;
          background-size: 26px auto;
        }
      }
    }
  }

  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }
    @media all and (min-width: $desktop) {
      padding: 44px 84px;
      .container {
        padding: 20px 0px;
      }
    }
    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  > .container {
    @media all and (min-width: $small-medium) {
      //   max-width: 1272px;
      margin-top: -221px;
    }
  }
  .admin-wrp {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px;
    align-items: center;

    @media all and (min-width: $small-medium) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 13px;
    }

    .admin {
      color: #fff;
      font-family: $sourcesans-fontfamily;
      @include fontsize(12px);
      text-transform: uppercase;
      padding: 3.2px;
      border-radius: 2px;
      border: 1px solid #18d6dc;
      background: #18d6dc;
    }

    .date {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
    }
  }

  .data-head {
    position: relative;

    .bck-btn-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      display: flex;
      @include fontsize(10px);
      font-family: $primary-fontfamily;
      display: none;

      @media all and (min-width: $small-medium) {
        display: flex;
        align-items: center;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }

      .icon-back {
        background: url(../../assets/images/back.svg) no-repeat;
        width: 14px;
        height: 9px;
        background-size: 14px auto;
        display: block;
        margin-right: 5px;
        margin-top: 4px;

        @media all and (min-width: $small-medium) {
          width: 12px;
          height: 8px;
          background-size: 12px auto;
          margin-top: 2px;
        }

        @media all and (min-width: $desktop) {
          width: 17px;
          height: 12px;
          background-size: 17px auto;
        }

        @media all and (min-width: $desktop-xl) {
          width: 25px;
          height: 20px;
          background-size: 25px auto;
        }
      }
    }
    @media all and (min-width: $small-medium) {
      margin-bottom: 43px;
    }
  }

  .container {
    padding: 0;

    @media all and (min-width: $small-medium) {
      padding: 20px 40px 0;
    }

    @media all and (min-width: $desktop) {
      padding: 20px 0 0;
    }

    .copay-data {
      .copayment-summry-wrp {
        @media all and (min-width: $desktop-xl) {
          padding: 0 0 0 20px;
        }
      }

      padding: 30px 20px 24px 20px;
      background-color: $white-color;

      @media all and (min-width: $small-medium) {
        border-radius: 30px 30px 0 0;
        padding: 40px;
      }

      @media all and (min-width: $desktop) {
        padding: 39px 40px 20px 40px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 50px 60px 20px 60px;
      }

      h1 {
        font-family: $primary-fontfamily;
        @include fontsize(18px);
        font-weight: $fw-bold;
        color: $blue;
        margin-bottom: 18px;

        @media all and (min-width: $small-medium) {
          font-weight: $fw-semibold;
          @include fontsize(20px);
          margin-bottom: 20px;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(20px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(24px);
          margin-bottom: 10px;
        }
      }

      .external-page {
        color: $light-green;
        display: block;
      }

      .copayment-content {
        p {
          &:first-child {
            @media all and (min-width: $small-medium) {
              // display: inline;
            }
          }

          &:nth-child(2) {
            @media all and (min-width: $small-medium) {
              display: inline;
            }
          }

          &:nth-child(3) {
            @media all and (min-width: $small-medium) {
              margin-top: 20px;
            }

            @media all and (min-width: $desktop-xl) {
              margin-top: 27px;
            }
          }
        }

        @media all and (min-width: $small-medium) {
          padding-right: 48px;
          line-height: 1;
        }

        @media all and (min-width: $desktop) {
          width: 90%;
          padding-right: 0;
        }

        @media all and (min-width: $desktop-1440) {
          width: 95%;
        }
      }

      p {
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
        text-align: left;
        max-width: none;

        @media all and (min-width: $small-medium) {
          @include fontsize(12px);
          margin-bottom: 20px;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }
      .click-summary {
        color: #540be0;
        font-weight: $fw-semibold;
      }
    }
  }

  .summary-copayment {
    h4 {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      letter-spacing: 0.15px;
      margin-bottom: 7px;

      @media all and (min-width: $small-medium) {
        margin: 28px 0 11px;
        @include fontsize(18px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }
    }

    @media all and (min-width: $desktop) {
      width: 91%;
    }

    @media all and (min-width: $desktop-1440) {
      width: auto;
    }

    p {
      @media all and (min-width: $desktop) {
        width: 90%;
      }

      @media all and (min-width: $desktop-1440) {
        width: 90.5%;
      }
    }
  }

  .summary-copay-table {
    @media all and (min-width: $desktop-1440) {
      width: 100%;
    }

    @media all and (min-width: $desktop-xl) {
      width: 82.5%;
    }
  }
}
