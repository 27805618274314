@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.provider-list-wrap {
  background: #e9e8ee;

  .footer {
    @media all and (min-width: $medium) {
      margin-left: 40px;
      margin-right: 40px;
    }

    @media all and (min-width: $desktop-xl) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }

    @media all and (min-width: $desktop) {
      padding: 44px 84px;

    }

    .container {
      padding: 20px 0px;

      @media all and (min-width: 768px) {
        padding: 20px 10px 0;
      }

      @media all and (min-width: 853px) {
        padding: 20px 30px 0;
      }

      @media all and (min-width: 1160px) {
        padding: 20px 38px 0;
      }

      @media all and (min-width: 1280px) {
        padding: 20px 0 0;
      }
    }

    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }

  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;

    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }

    span {
      display: block;
    }

    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }

    .back-dots-wrap {
      border: 1px solid #fff;
    }

    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }

    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }

    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }

   

    @media all and (min-width: $small-medium) {
      display: none;
    }
  }


  .medium-card-back {
    display: none;
    align-items: center;
    gap: 8px;
    @include fontsize(16px);
    font-weight: $fw-semibold;
    border: none;
    outline: none;
    background: none;

    .medium-card-icon {
      display: block;
      width: 17px;
      height: 14px;
      background: url("../../assets/images/back.svg") no-repeat;
      background-size: 17px 14px;
    }

    @media all and (min-width: $small-medium) {
      display: flex;
    }
  }

  .new-medium-card-btn{
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(99deg) brightness(102%) contrast(101%);
    font-size: 14px;
    font-weight: 600;
    font-family: $primary-fontfamily;
  }



  .container {
    padding: 0;

    // @media all and (min-width: $small-medium) {
    //   padding: 20px 40px 0;
    // }
    @media (min-width: 768px) and (max-width: 852px) {
      padding: 0px 20px 0;
    }

    @media (min-width: 853px) and (max-width: 991.98px) {
      padding: 0px 40px 0;
    }

    @media (min-width: 992px) and (max-width: 1329.98px) {
      padding: 0px 40px 0;
    }

    @media (min-width: 1280px) and (max-width: 1300px) {
      padding: 0px 20px 0;
    }

    @media (min-width: 1300px) and (max-width: 1304.98px) {
      padding: 0px 20px 0;
    }

    @media (min-width: 1305px) and (max-width: 1311.98px) {
      padding: 0px 10px 0;
    }

    @media (min-width: 1312px) and (max-width: 1439.98px) {
      padding: 0px 10px 0;
    }

    @media (min-width: 1440px) and (max-width: 1460px) {
      padding: 0px 20px 0;
    }

    @media (min-width: 1461px) and (max-width: 1500px) {
      padding: 0px 10px 0;
    }


    // @media all and (min-width: $desktop) {
    //   padding: 20px 34px 0px;
    // }
  }

  .prov-mbl-list {
    display: block;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .prov-dsktp-list {
    // display: none;

    // @media (min-width: 768px) {
    display: block;

    .provider-list-search-result-mbl {
      @media all and (min-width: 768px) {
        display: none;
      }
    }

    .prov-dsktp-list-search {
      @media all and (max-width: 767.98px) {
        display: flex;
        justify-content: start;
        margin-left: 20px;
        align-items: center;
        margin-bottom: 24px;
      }

      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 40px;

      .provider-list-search-result {
        @media all and (max-width: 767.98px) {
          padding: 24px 0px 16px;
          margin-bottom: 16px;
          border-bottom: 1px solid rgba(234, 234, 234, 1);
        }

        background: #fff;
        padding: 24px 20px;

        h2 {
          @media all and (max-width: 767.98px) {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }

          text-align: start;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
        }

        p {
          @media all and (max-width: 767.98px) {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }

          font-size: 18px;
          font-weight: 400;
          margin-bottom: 8px;
          line-height: 27px;
        }

        .provider-edit-search {
          @media all and (max-width: 767.98px) {
            line-height: 20px;
          }

          font-size: 14px;
          font-weight: 500;
          color: #540BE0;
        }
      }

      .popup-list-provider {
        // border-bottom: 1px solid #EAEAEA;
        margin: 0;
        padding: 0 !important;
        @media all and (max-width: 767px) {
          display: none;
        }
      }

      .provider-list-range-selector {
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        // margin-bottom: 24px;

        form {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .form-label {
          @media all and (max-width: 767.98px) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
          }

          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0px;

        }

        .form-select {
          border: 1px solid #240151;
          border-radius: 100px;
          padding: 6.5px 30px 6.5px 16px;
          font-size: 12px;
          font-weight: 600;
          font-family: $primary-fontfamily;
          color: #240151;

          option {
            font-size: 12px;
            font-weight: 600;
            font-family: $primary-fontfamily;
            color: #240151;
          }
        }

        .dropdown-icon {
          background: url("../../assets/images/dropdown-icon.svg") no-repeat center;
          display: inline-block;
          width: 16px;
          height: 16px;
          position: absolute;
          right: 8px;
          top: 8px;
          pointer-events: none;
          cursor: pointer;
        }
      }
    }

    .provider-list-map-cntnt-wrp {
      display: flex;
      // justify-content: space-between;
      width: 100%;
      gap: 24px;
      flex-wrap: wrap;

      // @media (min-width:768px) {
      //   align-items: flex-start;
      // }

      .provider-list-customer-selection {
        @media all and (max-width: 767.98px) {
          padding: 0px;
          border: none;
        }

        padding: 24px;
        width: 100%;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        flex-grow: 1;

        @media (min-width: 992px) {
          width: calc(55% - 12px);
        }

        @media (min-width: 1024px) {
          width: calc(57% - 12px);
        }

        @media (min-width: 1200px) {
          width: calc(61% - 12px);
        }

        @media (min-width: 1440px) {
          width: calc(65% - 12px);
        }

      }

      .provider-list-search-map {
        @media all and (max-width: 767.98px) {
          padding: 0px;
          border: none;
        }

        padding: 24px;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        width: 100%;
        flex-shrink: 0;

        @media (min-width: 768px) {
          position: relative;
          max-height: 700px;
          min-height: 400px;
        }

        @media (min-width: 992px) {
          width: calc(45% - 12px);
        }

        @media (min-width: 1024px) {
          width: calc(43% - 12px);
        }

        @media (min-width: 1200px) {
          width: calc(39% - 12px);
        }

        @media (min-width: 1440px) {
          width: calc(35% - 12px);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .provider-list-whole-contnt-wrap {

        .provider-list-inner-contnt-wrap {
          display: flex;
          gap: 8px;
          position: relative;

          &:not(:last-child) {
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid #eaeaea;
          }

          .provider-data-nickname {
            height: 46px;
            width: 46px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              background: #18D6DC;
              display: block;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
              // padding: 14px 12px;
              border-radius: 50%;
              height: 46px;
              width: 46px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .provider-list-data-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;

            .provider-list-data-innerwrp {
              @media all and (max-width: 767.98px) {
                flex-direction: column;
                align-items: start;
                gap: 0px;
                margin-bottom: 0px;
              }

              display: flex;
              gap: 8px;
              align-items: center;
              flex-direction: row;
              // margin-bottom: 16px;
            }

            .provider-list-data-name {
              @media all and (max-width: 767.98px) {
                border-right: none;
                padding-right: 0px;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 4px;
              }

              font-size: 18px;
              font-weight: 600;
              color: #221f20;
              border-right: 1px solid #9f9f9f;
              padding-right: 8px;
              margin-bottom: 0px;
            }

            .provider-list-data-number {
              @media all and (max-width: 767.98px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
              }

              display: block;
              text-decoration: underline;
              color: #221f20;
              font-size: 18px;
              font-weight: 500;

            }

            .provider-list-data-desg {
              @media all and (max-width: 767.98px) {
                font-weight: 400;
                margin-bottom: 8px;
                font-size: 12px;
              }

              font-size: 18px;
              font-weight: 400;
              margin-bottom: 0px;

            }

            .provider-list-data-address {
              @media all and (max-width: 767.98px) {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
              }

              font-size: 18px;
              font-weight: 500;
              color: #B3B3B3;
              display: flex;
              flex-direction: column;
              align-items: start;
              line-height: 26px;

              @media (min-width: 1440px) {
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
              }

            }
          }

          .drop-arrow-right {
            background: url("../../assets/images/drop-arrow-right.svg") no-repeat center;
            width: 16px;
            height: 16px;
            display: inline-block;
            position: absolute;
            right: 0;
            cursor: pointer;
            top: 0;
          }
        }


      }
    }
  }
}

.provider-search-list-wrapper {

  .provider-list-search-result {
    background: #fff;
    padding: 24px 20px;

    h2 {
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 8px;
    }

    .provider-edit-search {
      font-size: 14px;
      font-weight: 500;
      color: #540BE0;
    }
  }

  .popup-list-mbl-provider {
    display: block !important;
    padding: 0 20px;

    @media all and (min-width: 768px) {
      display: none !important;
      padding: 0px;
    }
  }
}

.provider-dropdown-list {
  @media all and (max-width: 767.98px) {
    position: fixed;
    bottom: 60px;
    width: 100%;
    left: 0;
    z-index: 9;

    .wrapper {
      position: relative;
      width: 100%;
    }

    /* Dropdown container */
    .dropdown_c {
      padding: 16px 0;
      // border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      overflow: hidden;
      cursor: pointer;
      border-radius: 40px 40px 0 0px;
    }

    /* Selected item styling */
    .dropdown_c .selected {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #fff;
    }

    /* Black line to replace "Please select" */
    .selected .line {
      width: 120px;
      height: 4px;
      background-color: #240151;
    }

    /* Dropdown content hidden by default */
    .dropdown_c .drop-content {
      // position: absolute;
      bottom: 100%;
      /* Position dropdown above the selected element */
      left: 0;
      right: 0;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      background-color: #fff;
      // border-bottom: 1px solid #ccc; /* Adjust for upward dropdown */
      z-index: 1;
    }

    /* Dropdown content visible when open */
    .dropdown_c .drop-content.open {
      max-height: 490px;
      /* Adjust to fit content */
      // border-top: 1px solid #ccc;
      overflow-y: auto;
    }

    /* Dropdown list styling */
    .drop-content ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .drop-content li {
      padding: 10px;
      // border-bottom: 1px solid #f0f0f0;
      color: #333;
    }

    .drop-content li:hover {
      background-color: #f0f0f0;
    }

    .popup-list-provider {
      border-bottom: 1px solid #EAEAEA;
      margin: 0 20px;
      padding: 24px 0 !important;
    }



    .provider-list-customer-selection {
      padding: 16px 20px 24px;

    }

    .provider-list-range-selector {
      display: flex;
      align-items: center;
      justify-content: end;
      position: relative;
      margin-bottom: 24px;

      form {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .form-label {
        font-size: 12px;
        font-weight: 600;
      }

      .form-select {
        border: 1px solid #240151;
        border-radius: 100px;
        padding: 6.5px 30px 6.5px 16px;
        font-size: 12px;
        font-weight: 600;
        font-family: $primary-fontfamily;
        color: #240151;

        option {
          font-size: 12px;
          font-weight: 600;
          font-family: $primary-fontfamily;
          color: #240151;
        }
      }

      .dropdown-icon {
        background: url("../../assets/images/dropdown-icon.svg") no-repeat center;
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }

    .provider-list-whole-contnt-wrap {
      @media all and (max-width: 767.98px) {
        padding: 0 20px 2px;
      }

      .provider-list-inner-contnt-wrap {
        display: flex;
        gap: 8px;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #eaeaea;

        .provider-data-nickname {
          height: 46px;
          width: 46px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            background: #18D6DC;
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            // padding: 14px 12px;
            border-radius: 50%;
            height: 46px;
            width: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .provider-list-data-wrap {
          display: flex;
          flex-direction: column;

          .provider-list-data-name {
            font-size: 14px;
            font-weight: 500;
            color: #221f20;
            margin-bottom: 4px;
          }

          .provider-list-data-number {
            display: block;
            text-decoration: underline;
            color: #221f20;
            font-size: 12px;
            font-weight: 400;
          }

          .provider-list-data-desg {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 8px;
          }

          .provider-list-data-address {
            font-size: 12px;
            font-weight: 400;
            color: #B3B3B3;
          }
        }

        .drop-arrow-right {
          background: url("../../assets/images/drop-arrow-right.svg") no-repeat center;
          width: 16px;
          height: 16px;
          display: inline-block;
          position: absolute;
          right: 0;
          cursor: pointer;
          top: 0;
        }
      }
    }
  }

}

.mbl-map-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.dsktp-map-list {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.provider-list-card {
  position: relative;
  background: #fff;

  @media all and (min-width: $small-medium) {
    background-color: #fff;
    padding: 40px 40px 10px;
    border-radius: 30px 30px 0 0;
  }

  .toggle-container {
    @media all and (max-width: 767.98px) {
      display: none;
    }

    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: end;
    margin-bottom: 8px;

    p {
      // margin: 0 0 0 11px;
      // width: auto;
      font-size: 14px;
      font-weight: 600;
      color: #221f20;
    }

    .toggle-button {
      width: 36px;
      height: 20px;
      background-color: #ccc;
      border-radius: 30px;
      position: relative;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &.toggled {
        background-color: #18D6DC;

        .toggle-circle {
          left: 17px;
        }
      }
    }

    .toggle-circle {
      width: 16px;
      height: 16px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 3px;
      transition: left 0.3s ease;
    }
  }
}

.modal-overlay {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9;

  @media (min-width: 768px) {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
  }

  .modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 0 20px 41px;

    @media (min-width: 768px) {
      width: 100%;
      margin: 0 20px 20px;
    }

    .modal-content {
      position: relative;
    }

    .close-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 9999999999;

      .close-icon {
        background: url("../../assets/images/close-modal-icon.svg") no-repeat center;
        width: 24px;
        height: 24px;
        display: inline-block;

      }
    }

    .provider-list-inner-contnt-wrap {
      display: flex;
      gap: 8px;
      position: relative;
      padding-bottom: 16px;
      padding-right: 14px;
      margin-bottom: 16px;
      border-bottom: 1px solid #eaeaea;

      @media (min-width: 768px) {
        margin-bottom: 8px;
        padding-bottom: 8px;
      }

      .provider-data-nickname {
        height: 46px;
        width: 46px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          background: #18D6DC;
          display: block;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          // padding: 14px 12px;
          border-radius: 50%;
          height: 46px;
          width: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .provider-list-data-wrap {
        display: flex;
        flex-direction: column;
        align-items: start;

        .provider-list-data-name {
          font-size: 14px;
          font-weight: 500;
          color: #221f20;
          margin-bottom: 4px;
        }

        .provider-list-data-number {
          display: block;
          text-decoration: underline;
          color: #221f20;
          font-size: 12px;
          font-weight: 400;
        }

        .provider-list-data-desg {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .provider-list-data-address {
          font-size: 12px;
          font-weight: 400;
          color: #B3B3B3;
          text-align: start;
        }
      }

    }

    .button-wrap {
      display: flex;
      flex-direction: column;

      .submit-btn {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background: #240151;
        padding: 12px;
        width: 100%;
        border-radius: 40px;
        margin-bottom: 16px;
        border: 1px solid transparent;
        font-family: $primary-fontfamily;

        @media (min-width: 768px) {
          margin-bottom: 8px;
        }
      }

      .cancel-btn {
        font-size: 16px;
        font-weight: 600;
        color: #240151;
        padding: 12px;
        width: 100%;
        border-radius: 40px;
        background: transparent;
        border: 1px solid #240151;
        font-family: $primary-fontfamily;
      }
    }
  }


}

.provider-list-wrp {
  @media all and (min-width: $small-medium) {
    margin-top: -194px;
  }
}

.prov-list-no-data {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #221F20;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #EAEAEA;
  border-radius: 10px;
  min-height: 358px;
  height: 100%;
  max-height: 468px;
  @media all and (max-width: 767.98px) {
    border: none;
    border-radius: 0px;
  }
}

.drop-c-data-name {
  text-align: start;
  @media (min-width: 768px) {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 190px;
    white-space: break-spaces;
    display: block;
  }
}

.search-provider-logo {
  padding: 100px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 100%;
    width: 320px;
    display: block;
  }
}