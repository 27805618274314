@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.home-page {}

.homepage-content {
  background: $light-purple;

  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
    }

    @media all and (min-width: $desktop) {
      padding: 44px 86px;

      .container {
        padding: 20px 0px;
      }
    }

    .container {
      @media all and (min-width: $small-medium) {
        padding: 0px 10px;
      }

      @media all and (min-width: 853px) {
        padding: 0px 30px;
      }

      @media all and (min-width: 992px) {
        padding: 0px 30px;
      }

      @media (min-width: 1170px) and (max-width: 1200px) {
        padding: 0px 38px;
      }

      @media all and (min-width: 1200px) {
        padding: 0px 40px;
      }

      @media all and (min-width: 1280px) {
        padding: 0px 0px;
      }
    }

    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }

    p {
      @include fontsize(14px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      color: $white-color;
      letter-spacing: 0.1px;

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
      }
    }
  }

  @media all and (min-width: $small-medium) {
    min-height: calc(100vh - 73.2px);
    height: 100%;
  }

  @media all and (min-width: $desktop) {
    min-height: calc(100vh - 101px);
  }

  @media all and (min-width: $desktop-xl) {
    min-height: calc(100vh - 101.4px);
  }

  .crd-main {
    border-radius: 20px;
    background: #c8f7f9;
    display: block;
    align-items: center;
    padding: 17px 20px 16px 15px;
    color: $blue;
    font-family: $primary-fontfamily;
    @include fontsize(16px);
    font-weight: $fw-semibold;
    width: 48.5%;
    line-height: 1;
    cursor: pointer;

    @media all and (min-width: $small-medium) {
      justify-content: space-between;
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(22px);
    }

    &.id-card {
      @media all and (min-width: $small-medium) {
        width: 48%;
        padding: 24px;
      }

      // @media all and (min-width: $desktop) {
      //   width: 38%;
      //   padding: 13px 18px 13px 18px;
      // }

      // @media all and (min-width: $desktop-1440) {
      //   padding: 13px 13px 13px 18px;
      // }

      // @media all and (min-width: $desktop-xl) {
      //   width: 40%;
      //   padding: 13px 17px 13px 18px;
      // }
    }

    &.provider-wrp {
      @media all and (min-width: $small-medium) {
        width: 48%;
        // padding: 13px 10px 13px 10px;
        padding: 25px;
      }

      // @media all and (min-width: $desktop) {
      //   width: 52%;
      //   padding: 13px 18px 13px 18px;
      // }

      // @media all and (min-width: $desktop-1440) {
      //   padding: 13px 18px 13px 22px;
      // }
    }

    .icon-wrap {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $light-green;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0px;
      }
    }

    .id-card-title,
    .claim-title {
      @include fontsize(18px);
      font-weight: $fw-bold;
      font-family: $primary-fontfamily;
      color: $light-green;
      display: block;
      margin-bottom: 22px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0px;
        margin-left: 10px;
      }

      @media all and (min-width: $medium) {
        margin-left: 20px;
      }

      @media all and (min-width: $desktop) {
        margin-left: 16px;
        @include fontsize(20px);
      }
    }

    .card-name,
    .claim-number {
      @include fontsize(12px);
      font-weight: $fw-semibold;
      font-family: $primary-fontfamily;
      color: $blue;
      display: block;
      margin-bottom: 7px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0px;
      }
    }

    .card-type,
    .claim-validity {
      @include fontsize(10px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      color: $blue;
      display: block;

      @media all and (min-width: $small-medium) {
        @include fontsize(12px);
        font-weight: $fw-semibold;
      }
    }
  }

  .card-title-wrap {
    @media all and (min-width: $small-medium) {
      display: flex;
      align-items: center;
      position: relative;
      background: rgba(36, 1, 81, 0.1);
      border-radius: 24px;
      margin-bottom: 18px;
    }
  }

  .left-block {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 26px;

    @media all and (min-width: $small-medium) {
      border-bottom: 0;
      margin-bottom: 0px;
    }
  }

  .span-breaker {
    display: none;
    @include fontsize(12px);
    font-weight: $fw-semibold;

    @media all and (min-width: $small-medium) {
      display: block;
    }
  }

  .card-name-wrap {
    @media all and (min-width: $small-medium) {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .homepage-details {
    margin-top: -86px;

    @media all and (min-width: $small-medium) {
      margin-top: -182px;
    }

    .container {
      @media all and (min-width: $small-medium) {
        padding: 0 20px;
      }

      @media all and (min-width: 853px) {
        padding: 0 40px;
      }

      @media all and (min-width: 992px) {
        padding: 0 40px;
      }

      @media all and (min-width: 1280px) {
        padding: 0px;
      }

      @media (min-width: 1280px) and (max-width: 1300px) {
        padding: 0px 20px;
      }

      @media (min-width: 1301px) and (max-width: 1330px) {
        padding: 0px 10px;
      }

      @media (min-width: 1440px) and (max-width: 1450px) {
        padding: 0px 25px;
      }

      @media (min-width: 1451px) and (max-width: 1500px) {
        padding: 0px 10px;
      }
    }
  }

  .container-box {
    padding: 39px 20px 15px 20px;
    background-color: $white-color;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    @media all and (min-width: $small-medium) {
      padding: 40px 40px 10px;
    }

    @media all and (min-width: $desktop) {
      padding: 38px 41px 0px 41px;
    }

    // @media all and (min-width: $desktop-xl) {
    //   padding: 50px 60px 60px 60px;
    // }

    .para-wrp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 42px;

      @media all and (min-width: $small-medium) {
        padding-bottom: 60px;
      }

      @media all and (min-width: $desktop-xl) {
        padding-bottom: 56px;
      }
    }

    .time-wrp {
      display: none;

      @media all and (min-width: $small-medium) {
        display: flex;
        align-items: flex-end;
      }

      .time {
        color: #9c9c9c;
        font-family: $primary-fontfamily;
        @include fontsize(10px);
        font-weight: 400;
        letter-spacing: 0.25px;

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(16px);
        }
      }

      .date {
        color: #9c9c9c;
        @include fontsize(10px);
        font-family: $primary-fontfamily;
        font-weight: 400;
        letter-spacing: 0.25px;

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(16px);
        }
      }

      .ltl-wrp {
        display: flex;
        align-items: center;
        margin-right: 20px;

        @media all and (min-width: $desktop-xl) {
          margin-right: 25px;
        }
      }

      .icon-timer {
        background: url(../../assets/images/time.svg) no-repeat;
        height: 14px;
        width: 14px;
        background-size: 14px auto;
        display: block;
        margin-right: 5px;

        @media all and (min-width: $desktop) {
          height: 18px;
          width: 18px;
          background-size: 18px auto;
          margin-right: 2px;
        }

        @media all and (min-width: $desktop-xl) {
          height: 20px;
          width: 20px;
          background-size: 20px auto;
        }
      }
    }
  }

  .claim-word {

    // display: none;
    @media all and (min-width: $small-medium) {
      display: inline-block;
    }
  }

  .container {
    padding: 0;

    @media all and (min-width: $small-medium) {
      padding: 20px 10px 0;
    }

    @media all and (min-width: $desktop) {
      // padding: 20px 23px 0;
      padding: 20px 34px 0;
    }
  }

  h1 {
    font-family: $primary-fontfamily;
    @include fontsize(24px);
    font-weight: $fw-regular;
    color: $blue;
    margin-bottom: 9px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 2px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(32px);
      margin-bottom: 10px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(42px);
      margin-bottom: 5px;
    }
  }

  p {
    color: $blue;
    font-family: $primary-fontfamily;
    @include fontsize(14px);
    font-weight: $fw-regular;
    // padding-bottom: 42px;
    letter-spacing: 0.25px;

    @media all and (min-width: $small-medium) {
      @include fontsize(12px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }

  .icon-card {
    width: 28px;
    height: 28px;
    display: block;

    // @media all and (min-width: $small-medium) {
    //   width: 24px;
    //   height: 17px;
    // }

    // @media all and (min-width: $desktop) {
    //   width: 40px;
    //   height: 27px;
    // }

    // @media all and (min-width: $desktop-1440) {
    //   width: 45px;
    //   height: 30px;
    // }

    // @media all and (min-width: $desktop-xl) {
    //   width: 49px;
    //   height: 35px;
    // }

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke: #c8f7f9;
      }
    }
  }

  .icon-provider {
    width: 28px;
    height: 28px;
    display: block;

    // @media all and (min-width: $small-medium) {
    //   width: 28px;
    //   height: 24px;
    // }

    // @media all and (min-width: $desktop) {
    //   width: 36px;
    //   height: 36px;
    // }

    // @media all and (min-width: $desktop-xl) {
    //   width: 40px;
    //   height: 40px;
    // }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .banner-content {
    &.home-health-plan {
      padding: 12px 0 0;
    }
  }

  .detail-wrp {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @media all and (min-width: $small-medium) {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 30px;
      padding-bottom: 25px;
      gap: 24px;
      justify-content: normal;
    }

    @media all and (min-width: $desktop) {
      border-bottom: none;
      margin-bottom: 0px;
      padding-bottom: 13px;
    }

    .search-wrp {
      @media all and (min-width: $small-medium) {
        width: 49.5%;
        margin-bottom: 0;
      }

      @media all and (min-width: $desktop) {
        width: 57.8%;
      }

      @media all and (min-width: $desktop-1440) {
        width: 63.1%;
      }

      @media all and (min-width: $desktop-xl) {
        width: 68.3%;
      }
    }

    .left-block-wrp {
      display: flex;
      justify-content: space-between;

      @media all and (min-width: $small-medium) {
        width: 68%;
      }

      @media all and (min-width: $desktop) {
        width: 43.2%;
      }
    }
  }

  .left-block-wrp {
    margin-bottom: 10px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 0;
    }
  }

  // .search-wrp {
  //     border-radius: 6px;
  //     border: 1px solid #281651;
  //     display: flex;
  //     align-items: center;
  //     padding: 18px 20px;
  //     justify-content: space-between;
  //     margin-bottom: 25px;

  //     .benefit-wrp {
  //         display: flex;
  //         align-items: center;
  //     }
  // }

  .report-content {
    background: #fff;
    margin-bottom: 25px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 0px;
    }

    .btn {
      margin-top: 22px;
    }

    @media all and (min-width: $small-medium) {
      padding: 0;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      border: 0;
      background: transparent;
      box-shadow: none;
    }

    @media all and (min-width: $desktop) {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 25px;
      // align-items: flex-start;
    }

    .left-block {
      @media all and (min-width: $small-medium) {
        order: 1;
      }

      @media all and (min-width: $desktop) {
        width: 68%;
      }

      @media all and (min-width: $desktop-1440) {
        width: 68.5%;
      }

      @media all and (min-width: $desktop-xl) {
        width: 68.5%;
      }
    }

    .claim-index {
      @media all and (min-width: $small-medium) {
        order: 3;
      }

      @media all and (min-width: $desktop) {
        width: 100%;
        padding: 19px 21px 30px;
      }

      @media all and (min-width: $desktop-1440) {
        width: 100%;
      }

      @media all and (min-width: $desktop-xl) {
        width: 100%;
        padding-bottom: 40px;
      }
    }
  }

  .banner-content {
    &.home-health-plan {
      .progress-bar {
        @media all and (max-width: 767px) {
          margin-bottom: 22px;
        }
      }

      .paln-wrp {
        @media all and (max-width: 767px) {
          padding-left: 0;
          position: relative;
        }

        &::after {
          @media all and (max-width: 767px) {
            display: none;
          }
        }
      }

      .rtl-block {
        @media all and (min-width: $small-medium) {
          >h3 {
            margin-bottom: 15px;
            width: 100%;
          }

          .claim-body {
            border: 1px solid #eaeaea;
            width: 334px;
            border-radius: 10px;
            flex-shrink: 0;

            // &:last-child {
            //   border-bottom: 0;
            // }
          }

          .claim-btn {
            margin-top: auto;
          }

          .claims-blocks {

            // display: flex;
            // gap: 10px;
            // overflow-x: auto;
            // white-space: nowrap;
            // padding-bottom: 10px;
            .slick-track {
              display: flex;
              margin: 0;

              .slick-slide {
                height: inherit;
                padding: 0 10px;

                >div {
                  height: 100%;

                  .claim-body {
                    height: 100%;
                  }
                }
              }
            }
          }
        }

        @media all and (min-width: $small-medium) {
          >.claim-heading-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 31px;

            h3 {
              @include fontsize(20px);
              font-weight: $fw-semibold;
              margin-bottom: 0px;
            }

            .all-claims {
              @include fontsize(16px);
              font-weight: $fw-semibold;
              color: $voilet-blue;
              position: relative;
              display: none;
              font-family: $primary-fontfamily;

              &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $voilet-blue;
              }

              @media all and (min-width: $small-medium) {
                display: block;
              }
            }
          }
        }
      }

      .health-plan-wrp {
        @media all and (min-width: $small-medium) {
          position: relative;
        }

        &.paln-wrp {
          @media all and (min-width: $desktop) {
            margin-bottom: 0;
            order: 3;
            padding: 6px 5px;
          }
        }
      }

      .health-body {
        @media all and (min-width: $desktop) {
          padding-bottom: 0px;
          padding-top: 0;
          display: flex;
          justify-content: space-between;
          gap: 20px;
        }
      }
    }

    @media all and (min-width: $small-medium) {
      border-radius: 8px;
      background: #fff;
      padding: 39px 40px 15px 40px;
    }

    @media all and (min-width: $desktop) {
      padding: 40px;
    }

    h3 {
      text-align: left;
      @include fontsize(16px);
      font-weight: $fw-bold;
      margin-bottom: 14px;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
        letter-spacing: normal;
        margin-bottom: 20px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(20px);
        margin-bottom: 31px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }
    }

    h4 {
      text-align: left;
      color: #cbcbcb;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      margin-bottom: 7px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 5px;
        @include fontsize(10px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(12px);
        margin-bottom: 9px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(14px);
      }
    }
  }

  .claim-detail {
    h3 {
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;

      @media all and (min-width: $small-medium) {
        color: $blue;
        @include fontsize(12px);
      }
    }
  }
}

.id-card-name {

  // @media all and (min-width: $small-medium) {
  //   max-width: 39%;
  // }
  @media all and (min-width: $desktop) {
    max-width: 48%;
  }

  @media all and (min-width: $desktop-1600) {
    max-width: 50%;
  }
}

.id-card-breaker {
  @media all and (min-width: $desktop) {
    margin-right: auto;
  }
}

.find-provider-icon {
  background: url("../../assets/images/find-provider-icon.png") no-repeat center;
  display: inline-block;
  width: 48px;
  height: 48px;
}

.find-provider-card {
  @media all and (max-width:767.98px) {

    width: 100% !important;
  }

  @media (min-width:768px) {
    width: auto !important;
  }

  @media (min-width: 992px) {
    min-width: 303px !important;
    padding: 24px !important;
  }

  .find-provider-card-title-wrap {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 16px;

    @media all and (max-width: 767.98px) {
      align-items: start;
    }

    @media (min-width: 768px) {
      gap: 0px;
    }
  }

  .icon-wrap {
    margin-bottom: 0px !important;
  }

  .id-card-title {
    margin-bottom: 4px !important;
    font-size: 18px !important;

    @media (min-width: 768px) {
      margin-bottom: 0px !important;
    }
  }

  .id-card-name {
    // font-size: 10px !important;
    font-weight: 500 !important;

    @media (min-width: 768px) {
      font-weight: 600 !important;
    }
  }

  .mobile-card-nm {
    display: block !important;

    @media (min-width: 768px) {
      display: none !important;
    }
  }

  .desktop-card-nm {

    @media all and (max-width: 767.98px) {
      display: block !important;
      margin-left: 64px;
      margin-top: -25px;
      line-height: 1.2;
    }

    @media (min-width: 768px) {
      display: block !important;
      max-width: 100%;

    }
  }
}