@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.plan-wrap {
    background: #E9E8EE;
    position: relative;
    z-index: 0;
    overflow: hidden;

    .footer {
        @media all and (min-width: $medium) {
          padding-left: 40px;
          padding-right: 40px;
        }
        @media all and (min-width: $desktop-xl) {
            padding-left: 60px;
            padding-right: 60px;
        }
      }
    @media all and (min-width: $small-medium) {
        min-height: calc(100vh - 73px);
    }

    @media all and (min-width: $desktop) {
        min-height: calc(100vh - 101px);
    }

    @media all and (min-width: $desktop-xl) {
        min-height: calc(100vh - 101.4px);
    }


    @media all and (min-width:$small-medium) {
        padding: 20px 20px 0;
    }

    .container {
        padding: 0;
        @media all and (max-width:767px) {
            // padding-bottom: 42px;
        }

        @media all and (min-width:$desktop) {
            padding: 0 23px;
        }
    }

    .network-wrps {
        h3 {
            text-align: left;
            color: $blue;
            font-family: $primary-fontfamily;
            @include fontsize(18px);
            font-weight: $fw-bold;
            letter-spacing: 0.15px;

            @media all and (min-width:$small-medium) {
                @include fontsize(16px);
            }

            @media all and (min-width:$desktop) {
                @include fontsize(20px);
                font-weight: $fw-semibold;

            }

            @media all and (min-width:$desktop-1600) {
                @include fontsize(26px);
                font-weight: $fw-semibold;

            }
        }

        @media all and (min-width:$small-medium) {
            padding: 20px 40px 40px;
            background-color: $white-color;
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;
            row-gap: 40px;

            .data-network{
                box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
                border: 1px solid #EAEAEA;
                width: 100%;

                @media all and (min-width:$desktop){
                    width: 74.5%;
                }

            }
        }

        @media all and (min-width:$desktop) {
            padding: 25px 40px;
            flex-direction: row;
        }

        @media all and (min-width:$desktop-xl) {
            padding: 60px;
        }

        .network-provider-wrap{
            padding: 22px 30px;
            box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
            border: 1px solid #ECECEC;
            border-radius: 20px;
            display: none;
            p{
                margin-bottom: 30px;
                @include fontsize(16px);
                @media all and (min-width:$desktop-1600) {
                    @include fontsize(18px);
                }
                @media all and (min-width:$desktop-xl) {
                    @include fontsize(20px);
                }
            }

            h2{
                text-align: left;
                margin-bottom: 20px;
            }
            .logo-wrap{
                border-radius: 20px;
                border: 1px solid #C8F7F9;
                background-color: #C8F7F9;
                width: 45%;
                margin-bottom: 20px;

                @media all and (min-width:$desktop) {
                    width: 100%;
                    margin-bottom: 15px;
                }
                
            }
            .logo-head{
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 10px;
                background-color: #fff;
                border-radius: 20px 20px 0 0;
                img{
                    height: 100%;
                    display: block;
                    max-width: 100%;
                }
            }
            .icon-health-smart{
                background: url('../../assets/images/cigna-logo.png') no-repeat;
                height: 38px;
                width: 100px;
                background-size: 100px auto;
            }
            .icon-atena{
                background: url('../../assets/images/phcs-logo.png') no-repeat;
                height: 38px;
                width: 87px;
                background-size: 87px auto;
            }
            .logo-body{
                text-align: center;
                min-height: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #240151;
                font-family: "Montserrat";
                font-size: 0.875rem;
                font-weight: 600;
                border-radius: 0 0 20px 20px;
            }
            @media all and (min-width:$small-medium){
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;

                h2{
                    width: 100%;
                    font-size: 16px;
                }
            }
            @media all and (min-width:$desktop){
                display: flex;
                flex-direction: column;
                padding: 23px 25px;
                width: 23%;
                justify-content: flex-start;

                h2{
                    letter-spacing: 0px;
                    margin-bottom: 24px;
                    @include fontsize(20px);
                    font-weight: $fw-semibold;
                }
                .logo-head{
                    height: 95px;
                }
                .logo-body{
                    @include fontsize(12px);
                    font-family: $primary-fontfamily;
                }
            }
            @media all and (min-width:1600px) {
                h2{
                    font-size: 26px;
                    font-weight: 600;
                }
            }
        }
    }

    .data-network {
        @media all and (max-width:767px) {
            background: rgba(255, 255, 255, 0.85);
            padding: 15px 20px 20px;
            // border-radius: 0 0 20px 20px;
        }

        >h3 {
            @media all and (min-width:$small-medium) {
                display: block;
                padding: 23px 23px;
                margin-bottom: 0;
                background-color: #F9F7FE;
                border-radius: 10px 10px 0 0;
                border-bottom: 1px solid #EAEAEA;

            }
        }
    }

    .tab-wrp {
        @media all and (min-width:768px) {
        }

        .in {
            @media all and (min-width:768px) {
                display: none;
            }
        }

        .out {
            @media all and (min-width:768px) {
                display: none;
            }
        }

        .active {
            @media all and (min-width:768px) {
                display: block;
            }
        }
    }

    .tab-head {
        display: none;

        @media all and (min-width:768px) {
            display: flex;
            padding: 25px 23px 0;
            &.tabbing-wrapping{
                flex-wrap: wrap;
            }
        }


        .tab-btn {
            @media all and (min-width:768px) {
                border-radius: 50px;
                background: #DDDBE0;
                padding: 14px 10px;
                margin-right: 6px;
                color: #9F9F9F;
                font-family: $primary-fontfamily;
                @include fontsize(15px);
                font-weight: $fw-semibold;
                width: 161px;
                margin-bottom: 10px;

                &:last-child {
                    margin-right: 0;
                    width: 147px;
                }
            }

            &.active {
                @media all and (min-width:768px) {
                    background: $light-green;
                    color: $white-color;
                }
            }
        }
    }

    .cover-table {
        @media all and (min-width:$small-medium) {
            margin-top: 20px;
        }

        @media all and (min-width:$desktop) {
            margin-top: 0;
        }

        @media all and (min-width:$desktop-1440) {
            margin-top: 10px;
        }

        @media all and (min-width:$desktop-1600) {
            margin-top: 0;
        }
    }
    .card-headings{
        background-color: $light-green;
        padding: 14px 0px;
        position: relative;
        .container{
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            align-items: center;

            @media all and (min-width:$small-medium){
                padding: 0 24px;
            }
        }
        span{
            display: block;
        }
        .card-head-text{
            color: $white-color;
            @include fontsize(18px);
            font-weight: $fw-medium;
            font-family: $primary-fontfamily;

            @media all and (min-width:$small-medium){
                @include fontsize(30px);
                font-weight: $fw-semibold;
                color: $light-green;
            }
        }
        .back-dots-wrap{
            border: 1px solid #fff;
        }
        .back-heading{
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;

            @media all and (min-width:$small-medium){
                display: none;
            }
        }
        .card-back-icon{
            display: block;
            width: 100%;
            height: 100%;
            background: url('../../assets/images/card-back.svg');
            background-size: 28px 28px;
        }
        .heading-dot{
            width: 4px;
            height: 4px;
            border: 1px solid #fff;
            border-radius: 50%;
        }
        .medium-card-back{
            display: none;
            align-items: center;
            gap: 8px;
            @include fontsize(16px);
            font-weight: $fw-semibold;
            border: none;
            outline: none;
            background: none;

            .medium-card-icon{
                display: block;
                width: 17px;
                height: 14px;
                background: url('../../assets/images/back.svg') no-repeat;
                background-size: 17px 14px;
            }
            @media all and (min-width:$small-medium){
                display: flex;
            }

        }
        @media all and (min-width:$small-medium){
            background-color: transparent;
            padding: 37px 0;
            margin-bottom: 20px;
        }
    }
    .idcard-wrap{
        padding: 0 20px;

        @media all and (min-width:$small-medium){
            padding: 0;
            margin-bottom: 40px;
        }
        @media all and (min-width:$desktop){
            margin-bottom: 0px;
            width: 44%;
        }
    }
}

.no-data-new{
    text-align: center;
    font-size: 22px;
    color: #221F20;
    font-weight: 700;
}

.header-popup{
    background: #fff;
    border-radius: 8px;
    padding: 10px 13px;
    position: absolute;
    top: 86%;
    z-index: 99;
    right: 25px;
    min-width: 130px;
    .pdf-icon{
        background: url(../../assets/images/adobe.svg) no-repeat;
        width: 14px;
        height: 15px;
        background-size: 14px auto;
          display: block;
          margin-right: 5px;

          @media all and (min-width: $desktop) {
            margin-right: 5px;
          }

          @media all and (min-width: $desktop-1600) {
            width: 18px;
            height: 18px;
            background-size: 18px auto;
          }

          @media all and (min-width: $desktop-xl) {
            width: 25px;
            height: 23px;
            background-size: 25px auto;
          }
    }
    h2{
        color: #9C9C9C;
      font-family: "Montserrat";
      line-height: 1.5;
      letter-spacing: 0.03px;
      font-weight: 600;
      @include fontsize(10px);
      text-align: start;
    }
    .popup-text{
        display: flex;
        align-items: center;
        color: #9C9C9C;
      font-family: "Montserrat";
      line-height: 1.5;
      letter-spacing: 0.03px;
      font-weight: 600;
      margin-top: 10px;
      @include fontsize(10px)
    }
}