@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.search-wrp {
  border-radius: 6px;
  border: 0.1rem solid #281651;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;

  input {
    @include fontsize(16px);
    color: $blue;
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    letter-spacing: 0.15px;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(22px);
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      letter-spacing: 0.15px;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(20px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }
    }
  }

  .search-input {
    padding: 17px 60px 20px 36px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 0;

    @media all and (min-width: $small-medium) {
      padding: 15px 40px 15px 20px;
    }

    @media all and (min-width: $desktop) {
      padding: 20px 70px 20px 36px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 20px 90px 20px 36px;
    }
  }

  .benefit-wrp {
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;

    @media all and (min-width: $small-medium) {
      left: 8px;
    }

    @media all and (min-width: $desktop) {
      left: 19px;
      @include fontsize(20px);
      line-height: 1;
    }
  }

  .logo-search {
    width: 12px;
    // height: 16px;
    margin-right: 5px;

    @media all and (min-width: $small-medium) {
      width: 8px;
      margin-right: 3px;
      background-size: 8px auto;
    }

    @media all and (min-width: $desktop) {
      margin-right: 5px;
      width: 12px;
      background-size: 12px auto;
      margin-top: 2px;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .icon-search {
    width: 36px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @media all and (min-width: $small-medium) {
      width: 26px;
      height: 15px;
      right: 10px;
    }

    @media all and (min-width: $desktop) {
      right: 16px;
      width: 48px;
      height: 25px;
    }

    @media all and (min-width: $desktop-xl) {
      right: 38px;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

///search-btn

.benefit-search-wrppp {
  .search-input {
    text-align: left;
    @include fontsize(16px);
    color: $blue;
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    letter-spacing: 0.15px;
    cursor: pointer;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(22px);
    }
  }
}
