@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.open-message {
  background: #e9e8ee;

  @media all and (min-width: $small-medium) {
    min-height: calc(100vh - 73.19px);
  }

  @media all and (min-width: $desktop) {
    min-height: calc(100vh - 100.6px);
    > .container {
      // max-width: 1272px;
    }
  }

  @media all and (min-width: $desktop-xl) {
    min-height: calc(100vh - 101.4px);
  }

  .open-admin-wrp {
    padding-bottom: 7px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 25px;

    @media all and (min-width: $small-medium) {
      position: relative;
      padding-bottom: 22px;
      margin-bottom: 41px;
    }
  }

  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;
    z-index: 2;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
        margin: 0;
        padding-right: 0;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      background-color: transparent;
      padding: 57px 0 25px;
      margin-bottom: 20px;
    }
  }
  .admin-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (min-width: $small-medium) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 13px;
    }

    @media all and (min-width: $desktop-xl) {
      max-width: 255px;
    }

    .admin {
      color: $white-color;
      font-family: $sourcesans-fontfamily;
      @include fontsize(12px);
      text-transform: uppercase;
      padding: 4px;
      border-radius: 4px;
      border: 1px solid #18d6dc;
      font-weight: 600;
      background: #18d6dc;

      @media all and (min-width: $desktop) {
        @include fontsize(12px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }

    .date {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      letter-spacing: 0.25px;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
        font-weight: 600;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }
  }

  .container {
    // padding: 0;

    @media all and (min-width: $small-medium) {
      // padding: 12px 40px 0px;
    }

    @media all and (min-width: $desktop) {
      // padding: 12px 0 0px;
    }

    .message-data {
      padding: 27px 20px 40px 20px;
      background-color: $white-color;

      @media all and (min-width: $small-medium) {
        border-radius: 30px 30px 0 0;
        padding: 40px;
        min-height: 845px;
        border-bottom: 2px solid #eaeaea;
      }

      @media all and (min-width: $desktop) {
        padding: 39px 41px 57px 41px;
        min-height: 580px;
      }

      @media all and (min-width: $desktop-1440) {
        min-height: 752px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 50px 60px 60px 60px;
        min-height: 920px;
      }

      h1 {
        font-family: $primary-fontfamily;
        @include fontsize(18px);
        font-weight: $fw-bold;
        margin-bottom: 25px;
        color: $blue;

        @media all and (min-width: $small-medium) {
          @include fontsize(20px);
          font-weight: $fw-semibold;
          margin-bottom: 16px;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(20px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(24px);
        }
      }

      p {
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 21px;
        text-align: left;
        max-width: none;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
          line-height: 1.3;
        }
        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }

        .click {
          color: #540be0;
          display: block;
          font-weight: $fw-semibold;
          margin-top: 22px;
          @media all and (min-width: $small-medium) {
            margin-top: 17px;
          }
        }

        .number {
          color: #9c9c9c;
          font-family: $primary-fontfamily;
          @include fontsize(16px);
          font-style: normal;
          font-weight: 500;
          opacity: 0.5s;

          &:hover {
            color: #00d0be;
          }
        }
      }

      .save-detail-wrp {
        padding-top: 8px;
        display: flex;
        align-items: center;
        margin-top: 48px;

        @media all and (min-width: $small-medium) {
          padding-top: 10px;
          margin-top: 44px;
        }

        .save-text {
          color: #540be0;
          font-family: $primary-fontfamily;
          @include fontsize(14px);
          margin-left: 9px;
          font-weight: $fw-semibold;

          @media all and (min-width: $small-medium) {
            @include fontsize(14px);
          }

          @media all and (min-width: $desktop) {
            @include fontsize(14px);
          }

          @media all and (min-width: $desktop-1440) {
            margin-left: 11px;
            letter-spacing: 0.1px;
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(20px);
          }
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 53px;
          height: 29px;

          @media all and (min-width: $desktop) {
            width: 72px;
            height: 39px;
          }

          @media all and (min-width: $desktop-xl) {
            width: 70px;
            height: 35px;
          }
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          background-color: transparent;
          border: 1px solid #dedede;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 22px;
          width: 22px;
          left: 4px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #dedede;
          -webkit-transition: 0.4s;
          transition: 0.4s;

          @media all and (min-width: $desktop) {
            width: 31px;
            height: 31px;
          }

          @media all and (min-width: $desktop-xl) {
            width: 31px;
            height: 31px;
          }
        }

        input:checked + .slider {
          background-color: $light-green;
          border: 1px solid transparent;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px $light-green;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px) translateY(-50%);
          -ms-transform: translateX(26px) translateY(-50%);
          transform: translateX(26px) translateY(-50%);
          background-color: $white-color;
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
    }
  }

  .message-data {
    background-color: $white-color;

    .message-head {
      position: relative;
    }

    .bck-btn-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 21px;
      @include fontsize(14px);
      font-family: $primary-fontfamily;
      display: none;

      @media all and (min-width: $small-medium) {
        margin-top: -10px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
        margin-top: -12px;
      }

      .icon-back {
        background: url(../../assets/images/back.svg) no-repeat;
        width: 14px;
        height: 9px;
        background-size: 14px auto;
        display: block;
        margin-right: 5px;
        margin-top: 4px;

        @media all and (min-width: $small-medium) {
          width: 12px;
          height: 8px;
          background-size: 12px auto;
        }

        @media all and (min-width: $desktop) {
          width: 17px;
          height: 12px;
          background-size: 17px auto;
        }

        @media all and (min-width: $desktop-xl) {
          width: 25px;
          height: 20px;
          background-size: 25px auto;
        }
      }
    }
  }
}
