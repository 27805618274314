@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.member-resource-wrap {
  h1 {
    @include fontsize(16px);
    font-weight: $fw-semibold;
    font-family: $primary-fontfamily;
    margin-bottom: 20px;
    text-align: left;
    color: $blue;

    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
      padding-right: 55px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(18px);
      padding-right: 141px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(42px);
      padding-right: 360px;
      margin-bottom: 20px;
      line-height: 1;
    }
  }

  p {
    color: $blue;
    font-family: $primary-fontfamily;
    @include fontsize(14px);
    font-weight: $fw-medium;
    margin-bottom: 20px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 25px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }

  h2 {
    text-align: left;
    @include fontsize(15px);
    font-weight: $fw-bold;
    font-family: $primary-fontfamily;
    margin-bottom: 20px;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
      max-width: 510px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
      max-width: 720px;
    }
  }

  .resource-content {
    padding: 30px 20px 130px;

    .para-wrp {
      @media all and (min-width: $small-medium) {
        padding-left: 0px;
      }
    }

    @media all and (min-width: $small-medium) {
      padding: 20px 40px 50px;
      padding-left: 0;
      background: none;
      border-radius: 8px;
    }

    @media all and (min-width: $desktop) {
      padding: 30px 100px 20px 40px;
      padding-left: 0;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 40px 122px 174px 60px;
      padding-left: 0;
    }

    .admin-wrp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 20px;
      @media all and (min-width: $small-medium) {
        justify-content: flex-end;
        flex-direction: row-reverse;
        gap: 13px;
      }

      .admin {
        color: #fff;
        font-family: $sourcesans-fontfamily;
        @include fontsize(12px);
        text-transform: uppercase;
        padding: 3.2px;
        border-radius: 4px;
        border: 1px solid #18d6dc;
        background: #18d6dc;
        font-weight: $fw-semibold;

        @media all and (min-width: $small-medium) {
          // border: 2px solid #dddbe0;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(12px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }

      .date {
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-semibold;
        letter-spacing: 0.25px;

        @media all and (min-width: $small-medium) {
          font-weight: $fw-semibold;
          @include fontsize(12px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }
    }
  }

  .resource-head {
    background: $light-green;
    padding: 15px 20px;
    color: #fff;
    font-family: $primary-fontfamily;
    @include fontsize(18px);
    font-weight: $fw-medium;
    text-align: center;
    position: relative;

    @media all and (min-width: $small-medium) {
      display: none;
    }

    .icon-resouce {
      background: url(../../../assets/images/card-back.svg) no-repeat;
      width: 28px;
      height: 28px;
      background-size: 28px 28px;
      display: block;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}
