@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.member-screen {
  background: #e9e8ee;
  .footer {
    @media all and (min-width: $medium) {
      margin-left: 40px;
      margin-right: 40px;
    }
    @media all and (min-width: $desktop-xl) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
  @media all and (min-width: $small-medium) {
    min-height: calc(100vh - 73.2px);
    height: 100%;
  }

  @media all and (min-width: $desktop) {
    min-height: calc(100vh - 101px);
  }

  @media all and (min-width: $desktop-xl) {
    min-height: calc(100vh - 101.4px);
  }

  @media all and (min-width: $desktop-1440) {
  }

  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }
    @media all and (min-width: $desktop) {
      padding: 44px 84px;
      
    }
    .container {
      padding: 20px 0px;
      @media all and (min-width: 768px) {
        padding: 20px 10px;
        
      }
      @media all and (min-width: 853px) {
        padding: 20px 30px;
      }
      @media all and (min-width: 1024px) {
        padding: 20px 30px;
      }
      @media all and (min-width: 1300px) {
        padding: 20px 0px;
      }
    }
    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
    .member-heading {
      color: #18d6dc;
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  .member-img-data {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media all and (min-width: $desktop) {
      align-items: flex-start;
    }
  }
  > .container {
    @media all and (min-width: $small-medium) {
      margin-top: -212px;
    }
  }
  .container {
    padding: 0;

    @media all and (min-width: $small-medium) {
      // padding: 20px 40px;
      padding: 20px 20px 0;
    }

    @media all and (min-width: 853px) {
      padding: 0 40px;
    }
    @media (min-width: 1024px) and (max-width: 1160px){
      padding: 0 40px;
    }
    @media (min-width: 1161px) and (max-width: 1279px){
      padding: 0 36px;
    }
    @media all and (min-width: $desktop) {
    padding: 0 24px;
    }
    @media (min-width: 1300px) and (max-width: 1400px){
      padding: 0 10px;
    }
    @media all and (min-width: 1400px) {
      padding: 0px;
      }
      @media (min-width: 1440px) and (max-width: 1450px){
        padding: 0 22px;
      }
      @media (min-width: 1451px) and (max-width: 1499px){
        padding: 0 10px;
      }
  }

  .member-data {
    background: #e9e8ee;
    padding: 30px 20px;

    @media all and (min-width: $small-medium) {
      background: url(../../assets/images/banner.png) no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      border-radius: 8px 8px 0 0;
      // padding: 70px 40px;
      padding: 35px 25px;
    }

    @media all and (min-width: $desktop) {
      padding: 40px;
      align-items: flex-end;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 60px;
    }

    .img-wrp {
      width: 50px;
      border-radius: 50%;
      margin-bottom: 10px;
      height: 50px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0;
        margin-right: 15px;
        border-radius: 50%;
        border: 0.5px solid #fff;
      }

      @media all and (min-width: $desktop) {
        width: 60px;
        height: 60px;
      }

      @media all and (min-width: $desktop-xl) {
        width: 80px;
        height: 80px;
      }

      img {
        width: 100%;
        object-fit: cover;
        display: block;
        border-radius: 50%;
        height: 100%;
      }
    }

    h3 {
      text-align: left;
      font-family: $primary-fontfamily;
      font-weight: $fw-medium;
      @include fontsize(24px);
      margin-bottom: 0;
      text-transform: capitalize;

      @media all and (min-width: $small-medium) {
        color: $white-color;
        font-family: $primary-fontfamily;
        font-weight: $fw-regular;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(32px);
      }

      @media all and (min-width: $desktop-1440) {
        margin-top: 10px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(42px);
      }
    }

    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: space-between;
    }

    .logout {
      @media all and (max-width: 767px) {
        display: none;
      }

      @media all and (min-width: $small-medium) {
        background-color: transparent;
        color: #fff;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-style: normal;
        font-weight: $fw-semibold;
        letter-spacing: 0.25px;
        display: flex;
        align-items: center;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }

      .logout-icon {
        @media all and (min-width: $small-medium) {
          background: url(../../assets/images/logout-btn.svg) no-repeat;
          height: 14px;
          width: 14px;
          background-size: 14px auto;
          display: block;
          margin-right: 10px;
        }

        @media all and (min-width: $desktop) {
          height: 18px;
          width: 18px;
          background-size: 18px auto;
        }
      }
    }
  }
  .member-content-card {
    background-color: $white-color;
    padding-bottom: 15px;
    @media all and (min-width: $small-medium) {
      padding-bottom: 0px;
      border-radius: 30px 30px 0px 0px;
    }
  }
  .member-content {
    @media all and (min-width: $small-medium) {
      border-bottom: 2px solid #eaeaea;

      padding: 25px 0;
      margin: 0 25px;
      border-radius: 30px 30px 0px 0px;
    }

    @media all and (min-width: $desktop) {
      padding: 25px 0px 40px;
      margin: 0 40px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 40px 0px;
      margin: 0px 60px;
    }

    .tab-wrp {
      @media all and (max-width: 767px) {
        background-color: $white-color;
      }
    }

    .tab-head-wrap {
      padding: 10px 20px;

      @media all and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        .tab-head {
          &:first-child {
            order: 4;
          }
          &:nth-child(2) {
            order: 5;
          }
          &:nth-child(3) {
            order: 6;
          }
          &:nth-child(4) {
            order: 3;
          }
          &:nth-child(5) {
            order: 1;
          }
          &:nth-child(6) {
            order: 2;
          }
          &:last-child {
            order: 8;
          }
        }
        .cms-links{
          order: 7;
          padding-top: 10px;
          li{
            margin-bottom: 5px;
            &:last-child{
              margin-bottom: 0;
            }
          }
          a{
            display: block;
            @include fontsize(14px)
          }
        }
      }
      @media all and (min-width: $small-medium) {
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 20px;
      }
    }

    .main-head {
      @media all and (max-width: 767px) {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #eaeaea;
        color: #281651;
        font-family: $primary-fontfamily;
        @include fontsize(16px);
        font-weight: 600;
        letter-spacing: 0.25px;
      }

      @media all and (min-width: $small-medium) {
        border-radius: 50px;
        background: #f2f2f2;
        color: #9f9f9f;
        padding: 13px 20px;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: $fw-semibold;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
        padding: 15px 20px;
      }
    }

    .tab-head {
      display: flex;
      width: 100%;
      align-items: center;
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      letter-spacing: 0.25px;
      justify-content: space-between;
      position: relative;
      margin-right: 15px;
      &:first-child {
        .main-head {
          // border-top: 1px solid #eaeaea;
        }
      }
      &:last-child {
        .main-head {
          border-bottom: 0;
        }
      }

      @media all and (min-width: $small-medium) {
        display: inline-block;
        width: auto;
        margin-right: 10px;
      }

      &.active {
        .main-head {
          @media all and (min-width: $small-medium) {
            background: #281651;
            color: #fff;
          }
        }
      }

      &.logout {
        @media all and (min-width: $small-medium) {
          display: none;
        }
      }
      &.security-tab {
        @media all and (min-width: $small-medium) {
          display: none;
        }
      }
      &.more-info {
        @media all and (min-width: $small-medium) {
          display: none;
        }
      }
      &.change-password {
        // @media all and (max-width: 767px) {
        //   display: none;
        // }
      }
    }

    .tab-wrp {
      .icon-member {
        @media all and (max-width: 767px) {
          background: url(../../assets/images/member-icon.svg) no-repeat;
          height: 31px;
          width: 30px;
          background-size: 30px auto;
          display: block;
          margin-right: 10px;
        }
      }

      .icon-insurance {
        @media all and (max-width: 767px) {
          background: url(../../assets/images/insurance.svg) no-repeat;
          height: 31px;
          width: 30px;
          background-size: 30px auto;
          display: block;
          margin-right: 10px;
        }
      }

      .icon-account {
        @media all and (max-width: 767px) {
          background: url(../../assets/images/account.svg) no-repeat;
          height: 31px;
          width: 30px;
          background-size: 30px auto;
          display: block;
          margin-right: 10px;
        }
      }

      .icon-logout {
        @media all and (max-width: 767px) {
          background: url(../../assets/images/logout.svg) no-repeat;
          height: 31px;
          width: 30px;
          background-size: 30px auto;
          display: block;
          margin-right: 10px;
        }
      }

      .icon-arrow {
        @media all and (max-width: 767px) {
          background: url(../../assets/images/arrow-right.svg) no-repeat;
          width: 8px;
          height: 15px;
          background-size: 8px auto;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          cursor: pointer;
        }
      }

      .in {
        display: none;
      }

      .out {
        display: none;
      }

      .over {
        display: none;
      }

      .active {
        display: block;
      }
    }
  }
  .mob-member-details {
    padding: 20px 20px 0;
    @media all and (min-width: 768px) {
      display: none;
    }
  }
  .logo-wrap {
    width: 52px;
    height: 18px;
    margin-bottom: 20px;
    img {
      display: block;
      width: 100%;
    }
  }
  .member-profile-img {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
  }
  .member-image-block {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .member-profile-name {
    span {
      display: block;
    }
  }
  .member-profile-name {
    @include fontsize(20px);
    font-weight: $fw-regular;
  }
  .member-profile-email {
    @include fontsize(12px);
    font-weight: $fw-medium;
  }
  .tab-popup {
    &.active {
      @media all and (max-width: 767px) {
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        background-color: $white-color;
        z-index: 99;
        overflow: auto;
      }
    }
  }
}
