/*
 Table Of Contents
 1.) Eric Meyer's Reset
 2.) Global Typography
	 2.0.1) Font Face
	 2.0.2) Global Headings
	 2.0.3) Form Elements
	 2.0.4) List styles
	 2.0.5) General Text Formatting
	 2.0.6) Link elements
	 2.0.7) Table Elements
	 2.0.8) Button Styles
	 2.0.9) Sprite Icons
 3.) Site Wide Content
 4.) Header Style
 5.) Footer Style
 ===============================================*/

/*
 1.) Eric Meyer's Reset
 ----------------------------------------*/
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
 1.) Global Typography
 ----------------------------------------*/
*,
:before,
:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  font-family: $primary-fontfamily;
  @include fontsize(16px);
  line-height: 1.2;
  font-weight: $fw-regular;
  // padding-bottom: 57px;
}

/*fonts */

@font-face {
  font-family: "source_sans_proregular";
  src: url("../fonts/sourcesanspro-regular-webfont.woff2") format("woff2"),
    url("../fonts/sourcesanspro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "source_sans_prosemibold";
  src: url("../fonts/sourcesanspro-semibold-webfont.woff2") format("woff2"),
    url("../fonts/sourcesanspro-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "source_sans_probold";
  src: url("../fonts/sourcesanspro-bold-webfont.woff2") format("woff2"),
    url("../fonts/sourcesanspro-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

//loader
.loading-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
}

.loading-screen {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/*
 2.0.2) Global Headings
 -----------------------------*/

h1 {
  color: $blue;
  font-family: $primary-fontfamily;
  @include fontsize(36px);
  font-weight: 700;

  @media all and (min-width: $desktop) {
    @include fontsize(36px);
  }

  @media all and (min-width: $desktop-1440) {
    @include fontsize(36px);
  }

  @media all and (min-width: $desktop-xl) {
    @include fontsize(44px);
  }
}

h2 {
  color: $blue;
  text-align: center;
  font-family: $primary-fontfamily;
  @include fontsize(14px);
  letter-spacing: 0.25px;

  @media all and (min-width: $small-medium) {
    @include fontsize(18px);
  }

  @media all and (min-width: $desktop) {
    @include fontsize(20px);
  }

  @media all and (min-width: $desktop-xl) {
    @include fontsize(30px);
  }
}

h3 {
  text-align: center;
  color: $blue;
  font-family: $primary-fontfamily;
  @include fontsize(20px);
  font-weight: $fw-semibold;
  margin-bottom: 16px;

  @media all and (min-width: $small-medium) {
    @include fontsize(18px);
    letter-spacing: 0.225px;
    margin-bottom: 10px;
  }

  @media all and (min-width: $desktop) {
    @include fontsize(20px);
    font-weight: $fw-bold;
  }

  @media all and (min-width: $desktop-xl) {
    @include fontsize(30px);
  }
}

/*
 2.0.3) Form Elements
 -----------------------------*/
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  font-size: 100%;
  color: inherit;
  background: #fff;
  border: 1px solid #ccc;
  font-family: "Open Sans", sans-serif;
}

input,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

input {
  line-height: normal;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  border: none;
  outline: 0;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  border: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="file"] {
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  line-height: normal;
  overflow: visible;
}

button[disabled],
input[disabled] {
  cursor: default;
  color: #999;
  border-color: #ccc;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/*
 2.0.4) List Styles
 ---------------------------------*/
ul,
ol,
dl {
  font-size: 100%;
  line-height: 1.5;
}

li,
dt {
  line-height: 1.5em;
}

dl {
  margin: 0 0 1em 0;
}

dd {
  margin: 0 0 1em 0.8em;
}

ul {
  list-style-type: none;
  margin: 0 0 0 0;
}

ol {
  list-style-type: decimal;
  margin: 0 0 1em 0;
}

ul ul,
ol ul {
  list-style-type: circle;
  margin-top: 0;
}

ol ol {
  list-style-type: lower-latin;
  margin-top: 0;
}

/*
 2.0.5) General Text Formatting
 -----------------------------------*/
p {
  color: $blue;
  font-family: $primary-fontfamily;
  @include fontsize(14px);
}

blockquote,
cite,
q,
var,
dfn {
  font-style: italic;
}

blockquote {
  background: transparent;
  color: #666666;
}

small {
  font-size: 12px;
  line-height: 1.75;
}

pre,
code,
kbd,
tt,
samp,
var {
  font-size: 100%;
}

pre {
  font-size: 100%;
  line-height: 1.5;
  margin: 0 0 10px 0;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre,
code {
  color: #880000;
}

kbd,
samp,
var {
  color: #666666;
  font-weight: bold;
}

acronym,
abbr {
  border-bottom: 1px #aaa dotted;
  font-variant: small-caps;
  cursor: help;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  max-width: 100%;
  height: auto;
}

a,
span,
img {
  display: inline-block;
}

strong {
  font-weight: 600;
  letter-spacing: 0.25px;
}

body img {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  -o-user-select: none;
  user-select: none;
}

select::-ms-expand {
  display: none;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Good browsers  */
  opacity: 0;
}

/*
 Link style
 -------------------------*/
a {
  color: initial;
  text-decoration: none;

  &:focus,
  &:hover {
    color: initial;
    text-decoration: none;
    outline: none;
  }
}

/*
 Button Styles
 -------------------------*/
.btn,
input.btn,
button.btn {
  width: 100%;
  font-family: $primary-fontfamily;
  @include fontsize(15px);
  font-style: normal;
  font-weight: $fw-semibold;
  line-height: normal;
  letter-spacing: 0.188px;
  display: inline-block;
  color: $white-color;
  padding: 11.5px 0px;
  text-align: center;
  // transition: all 0.5s;
  height: 44px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50px;
  // background: linear-gradient(265deg, #f57d20 -22.3%, #240151 100%);
  background: #240151;

  @media all and (min-width: $small-medium) {
    @include fontsize(18px);
    height: 54px;
  }

  @media all and (min-width: $desktop) {
    @include fontsize(20px);
  }

  @media all and (min-width: $desktop-xl) {
    @include fontsize(24px);
    height: 62px;
  }

  @media all and (max-width: 320px) {
    @include fontsize(12px);
  }
}

// .btn:hover,
// .btn:focus,
// input.btn:hover,
// input.btn:focus,
// button.btn:hover,
// button.btn:focus {
//   background: transparent;
//   border: 1px solid $blue;
//   color: $blue;
// }
.btn:hover {
  background: transparent;
  border: 1px solid $blue;
  color: $blue;
}

button {
  border: none;
}

//form-input
.form-block {
  margin-bottom: 20px;
}

.form-label {
  color: $blue;
  font-family: $primary-fontfamily;
  @include fontsize(14px);
  display: block;
  margin-bottom: 3px;
  font-weight: $fw-semibold;

  @media all and (min-width: $small-medium) {
    margin-bottom: 10px;
  }

  @media all and (min-width: $desktop) {
    @include fontsize(16px);
    letter-spacing: 0.25px;
    margin-bottom: 12px;
  }

  @media all and (min-width: $desktop-xl) {
    @include fontsize(20px);
  }

  .label-text {
    color: $blue;
    @include fontsize(14px);
    font-weight: $fw-regular;
    letter-spacing: 0.25px;
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }
}

.form-control {
  border-radius: 50px;
  background: $light-gray;
  padding: 10px 55px 10px 13px;
  border: 1px solid transparent;
  height: 44px;
  width: 100%;
  color: $blue;
  font-family: $primary-fontfamily;
  caret-color: #18d6dc;
  @include fontsize(12px);

  &::placeholder {
    color: $gray;
    font-family: $primary-fontfamily;
    @include fontsize(16px);
    font-weight: $fw-semibold;
  }

  @media all and (min-width: $small-medium) {
    border: 2px solid transparent;
    @include fontsize(14px);
    height: 54px;
  }

  @media all and (min-width: $desktop) {
    @include fontsize(16px);
  }

  @media all and (min-width: $desktop-xl) {
    @include fontsize(20px);
    height: 62px;
  }
}

.form-control:focus {
  background-color: #c8f7f9;
  border: 1px solid #18d6dc;

  @media all and (min-width: $small-medium) {
    border: 2px solid #18d6dc;
  }
}

.mob-hide {
  display: none;

  @media all and (min-width: $small-medium) {
    display: block;
  }
}

/* Containers
----------------------------------------------------------------------------------------------------*/
.container {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @media all and (min-width: $desktop-1440) {
    max-width: 1320px;
  }

  @media all and (min-width: $desktop-1600) {
    max-width: 1440px;
  }

  @media all and (min-width: $desktop-xl) {
    max-width: 1720px;
  }
}

.type-number {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    -webkit-appearance: textfield;
    appearance: textfield;
  }
}

.error {
  color: red;
  display: block;
  margin-top: 5px;
  font-size: 14px !important;
  font-weight: $fw-regular !important;
  font-family: $primary-fontfamily !important;

  @media all and (min-width: $desktop) {
    font-size: 16px !important;
  }
}

.has-header {
  padding-bottom: 58px;

  @media all and (min-width: $small-medium) {
    padding: 64px 0 0 0;
  }

  @media all and (min-width: $desktop) {
    padding-top: 98px;
  }

  @media all and (min-width: $desktop-xl) {
    padding-top: 70px;
  }
}

.open-popup {
  overflow: hidden;
}

//
.react-tabs__tab:focus:after {
  display: none;
}

//date
.date-wrap {
  .date-label-wrp {
    position: relative;
  }

  .form-control input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem;
    position: relative;
    width: 100%;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

//for pixel perfection
body {
  // opacity: 0.5;
  // background: url('../images/pixel-perfect-image/375-all-screen/sign-in-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/sign-in-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/signin-1440.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/signin-1920.png') no-repeat center top;
  //forget-password
  // background: url('../images/pixel-perfect-image/375-all-screen/froget-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/froget-pass-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/forget-1440.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/forget-1920.png') no-repeat center top;
  //signup
  // background: url('../images/pixel-perfect-image/375-all-screen/sign-up-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/signup-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/signup-1440.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/signup-1920.png') no-repeat center top;
  //verify phone
  // background: url('../images/pixel-perfect-image/375-all-screen/verify-phone-375.png') no-repeat center top;

  //verify email
  // background: url('../images/pixel-perfect-image/375-all-screen/verify-email.png') no-repeat center top;

  //verify authINsight
  // background: url('../images/pixel-perfect-image/375-all-screen/auth-insight-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/auth-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/auth-1440.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/auth-1920.png') no-repeat center top;

  //popup-375
  // background: url('../images/pixel-perfect-image/375-all-screen/popup-auth.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/375-all-screen/fail-popup.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/375-all-screen/success-pop.png') no-repeat center top;
  //popup-768
  // background: url('../images/pixel-perfect-image/768-all-screen/authenticating-processing.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/authenticating-successful.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/authenticating-failed.png') no-repeat center top;
  // //popup-1440
  // background: url('../images/pixel-perfect-image/1440-all-screen/authenticating-processing.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/authenticating-successful.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/authenticating-failed.png') no-repeat center top;

  //popup-1920
  // background: url('../images/pixel-perfect-image/1920-all-screen/authenticating-processing.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/authenticating-successful.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/authenticating-failed.png') no-repeat center top;

  // loading screen
  // background: url('../images/pixel-perfect-image/375-all-screen/loading-screen.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/loading-screen.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/loading-screen.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/loading-screen.png') no-repeat center top;
  // background-size: cover;
  // home
  // background: url('../images/pixel-perfect-image/375-all-screen/home-320.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/home-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/homepage-1280by720\ \(1\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/homepage-v4\ \(2\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/homepage-v4\ \(3\).png') no-repeat center top;
  //plan
  // background: url('../images/pixel-perfect-image/375-all-screen/id-card-320.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/id-card-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/id-card\ _\ hsa-plan-new\ \(2\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/id-card\ _\ hsa-plan-new\ \(3\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/id-card\ _\ hsa-plan-new\ \(4\).png') no-repeat center top;
  //id card
  //benefits
  // background: url('../images/pixel-perfect-image/375-all-screen/rx-benefits.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/768-all-screen/rx-benefits\ \(1\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/rx-benefits\ \(3\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1440-all-screen/rx-benefits\ \(2\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1920-all-screen/rx-benefits\ \(3\).png') no-repeat center top;
  //copay-summary
  // background: url('../images/pixel-perfect-image/copay-summary/rx-benefits\ _\ copay\ summary\ \(1\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/copay-summary/rx-benefits\ _\ copay\ summary\ \(2\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/copay-summary/rx-benefits\ _\ copay\ summary\ \(3\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/copay-summary/rx-benefits\ _\ copay\ summary\ \(4\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/copay-summary/rex-1920.png') no-repeat center top;
  //my-care
  // background: url('../images/pixel-perfect-image/care-benefit/my-care-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/my-care-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/my-care-1280.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/1440-my-care.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/1920-my-carre.png') no-repeat center top;
  //claim-expand

  // background: url('../images/pixel-perfect-image/exand-claim/expand-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/exand-claim/tab-expand.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/exand-claim/1280-expand.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/exand-claim/1440-expand.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/exand-claim/expand-1920.png') no-repeat center top;

  // background: url('../images/pixel-perfect-image/care-benefit/my-claim-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/my-claim-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/my-claim-1280.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/1440-claim.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/care-benefit/1920-my-claim.png') no-repeat center top;

  //message
  // background: url('../images/pixel-perfect-image/message/inbox-375.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/message/inbox-768.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/message/inbox-1280.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/message/1440-inbox.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/message/inbox-1920.png') no-repeat center top;
  //member-1280
  // background: url('../images/pixel-perfect-image/member-screen/member-screen\ _\ member-resources.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/member-screen/member-screen\ _\ member-resources\ \(1\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/member-screen/member-screen\ _\ member-resources\ \(2\).png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/member-screen/member-screen\ _\ member-resources\ \(3\).png') no-repeat center top;
  //auth-1280
  // background: url('../images/pixel-perfect-image/1280-all-screen/sign-in.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/sign-up.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/authenticate-your-insights.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/forgot-your-password.png') no-repeat center top;
  // background: url('../images/pixel-perfect-image/1280-all-screen/verify-email\ &\ phone-number.png') no-repeat center top // background-position-y: -133px;
  // background-position-y: -269px;
  // background-size: 375px auto;

  //     position: relative;
  //     height: 5000px;
  //     // padding-top: 30px;

  //  &::after {
  //     content: " ";
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     background: url('../images/copay-summary-desk.jpg') no-repeat center top;
  //     // background: url('../images/home-screen-mob.jpg') no-repeat center top;
  //     opacity: 0.5;
  //     z-index: 999;
  //     pointer-events: none;
  //   }
}

.MuiTypography-h4 {
  font-size: 18px;
}

.no-data-found {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 235px) !important;
}

.cms-page {
  .member-content {
    padding: 20px 30px;
    min-height: calc(100vh - 295px);

    @media all and (min-width: $small-medium) {
      min-height: calc(100vh - 370px);
    }

    @media all and (min-width: $desktop) {
      min-height: calc(100vh - 320px);
    }
  }

}

.cms-page-content {
  * {
    all: revert
  }

  p,
  span {
    font-size: 18px;
    margin: 10px 0;
  }


}

.default-msg-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 295px);

  @media all and (min-width: $small-medium) {
    min-height: calc(100vh - 370px);
  }

  @media all and (min-width: $desktop) {
    min-height: calc(100vh - 422px);
  }
}

.provider-list-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  flex-direction: row;

  @media all and (max-width: 767.98px) {
    align-items: flex-end;
    gap: 8px;
  }

  .entry-wrp {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: start;
    margin-left: 0px;
    margin-bottom: 0px;
    justify-content: end;

    .entry-text {
      font-size: 14px;
      font-family: $primary-fontfamily;
      font-weight: 500;
      color: #221F20;
      text-transform: capitalize;

    }

    .select-wrap {
      display: flex;
      align-items: center;
      gap: 22px;
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      padding: 8px 16px;
      position: relative;
      cursor: pointer;
      width: 92px;
      height: 36px;

      @media all and (max-width: 767.98px) {
        width: 70px;
      }
    }

    select {
      border: none;
      font-size: 14px;
      font-family: $primary-fontfamily;
      font-weight: 400;
      color: #240151;
      background: transparent;
      appearance: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 16px;
      // font-size: 16px;
      // font-weight: 400;
    }

    .icon-arrow {
      background: url("../images/chevron-down-blue.svg") no-repeat center;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 16px;
      pointer-events: none;

      @media all and (max-width: 767.98px) {
        right: 10px;
      }
    }
  }

  .pagination-list {
    display: flex;
    gap: 10px;
    margin: 27px 0 0;
    // margin-left: auto;
    margin-right: 0px;

    @media all and (max-width: 767.98px) {
      gap: 5px;
    }

    @media (min-width: 768px) {
      margin-left: 0px;
      margin-right: 0px;
    }

    li {
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      padding: 0;
      cursor: pointer;

      @media all and (max-width:768px) {
        width: 38px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      a {
        padding: 7px 21px;
        display: block;
        color: #9f9f9f;

        @media all and (max-width:768px) {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
        }
      }
    }

    .selected {
      background: #F6F7FA;

      a {
        color: #221F20 !important;
      }
    }

    .previous {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 0px !important;

      a {
        display: block;

        height: 100%;
        width: 100%;

        &::after {
          background: url("../images/arrow-right.svg") no-repeat;
          background-size: 6px auto;
          content: "";
          height: 10px;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 11px;
          // top: 40%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 6px;
          transform: rotate(180deg);

          @media (min-width: 768px) {
            top: 36%;
          }
        }
      }
    }

    .next {
      display: block;
      position: relative;

      a {
        cursor: pointer;
        display: block;
        height: 100%;

        // width: 100%;
        &::after {
          background: url("../images/arrow-right.svg") no-repeat;
          background-size: 6px auto;
          content: "";
          height: 10px;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 6px;
        }
      }
    }
  }
}