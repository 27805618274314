@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.message-wrap {
  .footer {
    @media all and (min-width: $medium) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;
    .container {
      padding: 0 20px;
      @media all and (min-width: 768px) {
        padding: 0 10px;
      }
      @media all and (min-width: 853px) {
        padding: 0 30px;
      }
      @media all and (min-width: 1024px) {
        padding: 0 30px;
      }
    }
    .msh-head {
      color: #18d6dc;
    }

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }
    @media all and (min-width: $desktop) {
      padding: 44px 84px;
      .container {
        padding: 20px 0px;
      }
    }
    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    margin-bottom: 15px;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }

  background: #e9e8ee;
  position: relative;
  z-index: 0;
  // min-height: calc(100vh - 332.6px);
  min-height: calc(100vh - 117.6px);

  @media all and (min-width: $small-medium) {
    min-height: calc(100vh - 73px);
  }

  @media all and (min-width: $desktop) {
    min-height: calc(100vh - 101px);
  }

  @media all and (min-width: $desktop-xl) {
    min-height: calc(100vh - 101.4px);
  }

  .wave-img-wrp {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    display: none;

    @media all and (min-width: $small-medium) {
      display: block;
    }

    @media all and (min-width: $desktop) {
      width: 43%;
    }

    @media all and (min-width: $desktop-1440) {
      width: 43%;
    }

    img {
      mix-blend-mode: screen;

      @media all and (min-width: $desktop-1440) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  // &::before {
  //     content: "";
  //     position: absolute;
  //     background: url(../../assets/images/ihp-life-wave.png) no-repeat;
  //     width: 100%;
  //     height: 500px;
  //     top: 0;
  //     right: 0;
  //     z-index: 999;
  // }

  .react-tabs__tab-list {
    border-bottom: 0;
    margin-bottom: 26px !important;
    padding: 0 21px 0;

    @media all and (min-width: $small-medium) {
      margin-bottom: 20px !important;
      padding: 0 38px 0;
    }
  }

  .react-tabs__tab {
    color: $light-green;
    font-family: $primary-fontfamily;
    font-style: normal;
    font-weight: $fw-semibold;
    letter-spacing: 0.15px;
    @include fontsize(16px);
    margin-bottom: 0 !important;
    margin-right: 10px;
    padding: 10px 25px;
    border-radius: 50px;
    background: transparent;
    border: 1px solid $light-green;

    @media all and (min-width: $small-medium) {
      padding: 11px 48px 10px;
      background: #f2f2f2;
      color: #9f9f9f;
      border: 0;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(18px);
    }

    @media all and (min-width: $desktop-xl) {
      margin-right: 15px;
      padding-bottom: 14px;
      @include fontsize(20px);
    }

    &.react-tabs__tab--selected {
      border: 0;
      background: $light-green;
      color: $white-color;
    }
  }

  .tab-content {
    padding: 39px 18px 30px;
    border-radius: 30px 30px 0 0;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    min-height: calc(100vh - 295px);

    @media all and (min-width: $small-medium) {
      padding: 2px 0px 20px;
      margin: 0 31px;
      border-bottom: 2px solid #eaeaea;
      min-height: calc(100vh - 370px);
    }

    @media all and (min-width: $desktop) {
      flex-wrap: wrap;
      align-items: flex-start;
      min-height: calc(100vh - 422px);
    }
    // .msz-scrol-wrp{
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   min-height: calc(100vh - 295px);

    // @media all and (min-width: $small-medium) {
    //   min-height: calc(100vh - 370px);
    // }

    // @media all and (min-width: $desktop) {
    //   min-height: calc(100vh - 422px);
    // }
    // }

    .message-card-wrap {
      @media all and (min-width: $desktop) {
        width: calc(50% - 25px);
        margin-right: 25px;
      }
    }
  }

  .search-wrp {
    border: 0;
    border-radius: 50px;
    background: #fff;
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;

    @media all and (min-width: $small-medium) {
      width: 37.5%;
      margin-bottom: 20px;
      border: 1px solid $light-green;
      position: relative;
      margin-right: 41px;
    }

    @media all and (min-width: $desktop) {
      // max-width: 783px;
      margin-bottom: 20px;
    }

    input {
      @include fontsize(14px);
      color: $blue;
      font-family: $primary-fontfamily;
      font-weight: $fw-semibold;
      letter-spacing: 0.15px;
      padding: 18px 19px;
      border: 0;
      border-radius: 50px;

      @media all and (min-width: $small-medium) {
        @include fontsize(16px);
        padding: 15px 43px 14px 12px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: #b3b3b3;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: $fw-semibold;
        letter-spacing: 0.15px;

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(22px);
        }
      }
    }
    .search-btn {
      position: absolute;
      display: none;
      width: 28px;
      height: 28px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      right: 10px;
      border: none;
      outline: none;
      background: none;
      .search-icon {
        display: block;
        width: 100%;
        height: 100%;
        background: url("../../assets/images/search-icon.svg") no-repeat;
        background-size: 28px auto;
        background-position: center;
        cursor: auto;
      }
      @media all and (min-width: $small-medium) {
        display: block;
      }
    }
  }
}
.message-wrap {
  .message-container-wrap {
    .container {
      @media all and (min-width: 853px) {
        padding: 0 40px;
      }
      @media (min-width: 1024px) and (max-width: 1160px){
        padding: 0 40px;
      }
      @media (min-width: 1161px) and (max-width: 1279px){
        padding: 0 36px;
      }
      @media all and (min-width: $desktop) {
      padding: 0 24px;
      }
      @media (min-width: 1300px) and (max-width: 1400px){
        padding: 0 10px;
      }
      @media all and (min-width: 1400px) {
        padding: 0px;
        }
    }
    @media all and (min-width: $small-medium) {
      margin-top: -201px;
      // padding: 0 20px;
    }
  }
}
