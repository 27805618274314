@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.msz-scrol-wrp {
  @media all and (min-width: $desktop) {
    display: flex;
    flex-wrap: wrap;
    margin-right: 4px;
  }

  > div {
    // height: 636px !important;
    width: 100% !important;

    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      max-height: 100% !important;
      // opacity: 1 !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .thumb-vertical {
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: inherit;
    background-color: $blue;
    width: 4px;
    left: -1px;
    // height: 636px !important;

    @media all and (max-width: 767px) {
      // height: auto !important;
    }

    @media all and (min-width: $small-medium) {
      // height: 70px !important;
    }
    @media all and (min-width: 1700px) {
      // max-height: 150px !important;
      // height: calc(100vh - 500px) !important;
      // height: calc(100vh - 708px) !important;
    }
  }

  .track-vertical {
    background-color: #c6c3ca;
    position: absolute;
    width: 1px;
    display: block !important;
    right: -20px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;

    @media all and (max-width: 767px) {
      background-color: transparent;
    }

    @media all and (min-width: $small-medium) {
      height: calc(100vh - 384px) !important;
    }

    @media all and (min-width: $desktop) {
      height: calc(100vh - 530px) !important;
    }

    @media all and (min-width: $desktop) {
      right: -30px;
    }

    @media all and (min-width: $desktop-1440) {
      height: calc(100vh - 550px) !important;
    }
    @media all and (min-width: $desktop-xl) {
      right: -37px;
    }
  }
}
.tabbing-wrap {
  padding: 8px 0 3px;
  @media all and (min-width: $small-medium) {
    background: #fff;
    padding: 38px 0 0;
    border-radius: 30px 30px 0 0;
  }
}
.react-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.react-tabs__tab-panel {
  width: 100%;
}
.inbox-card {
  width: 100%;
  padding-bottom: 14px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(40, 22, 81, 0.15);
  &:last-of-type {
    border-bottom: 0;
  }
  &.active-inbox-message {
    .top-new-msz {
      h3 {
        font-size: 1.125rem !important;
        font-weight: 600 !important;
        @media all and (min-width: $small-medium) {
          font-size: 18px !important;
        }
      }
    }

    .bottom-new-msz {
      p {
        @include fontsize(12px);
        font-weight: 400 !important;
        color: $blue !important;
        @media all and (min-width: $small-medium) {
          @include fontsize(16px);

        }
      }
    }
  }
  @media all and (min-width: $small-medium) {
    padding: 20px 0px 20px 88px;
    border-bottom: 2px solid #ececec;
    margin-bottom: 0px;
    min-height: 105px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 54px;
      height: 54px;
      background: url("../../assets/images/msg-inbox-icon.png") no-repeat center;
      background-size: 54px auto;
      top: 17px;
      left: 10px;
    }
  }

  @media all and (min-width: $desktop) {
    // width: calc(50% - 40px);
    // margin-right: 40px;
    // margin-bottom: 40px;
    min-height: 120px;
  }

  @media all and (min-width: $desktop-1440) {
    // width: calc(33.33% - 40px);
    // margin-right: 40px;
    // margin-bottom: 40px;
  }

  .admin-wrp {
    display: flex;
    align-items: center;
    gap: 10px;

    @media all and (min-width: $small-medium) {
      flex-direction: row-reverse;
    }
  }

  .claim-wrp {
    // @media all and (min-width:$desktop) {
    //     margin-bottom: 0;g107

    // }

    &.first-claim-wrap {
      h3 {
        @include fontsize(16px);
        font-weight: 400;
        width: 55%;
        margin-right: 10px;
        color: $blue;

        @media all and (min-width: $small-medium) {
          @include fontsize(18px);
        }
      }

      .top-new-msz {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
      }

      .bottom-new-msz {
        display: flex;
        justify-content: space-between;

        p {
          width: 74%;
          margin-right: 10px;
          @media all and (min-width: $small-medium) {
             width: 100%;
          }
        }
      }
    }
  }

  .claim-name {
    h3 {
      color: #053149;
      font-family: $primary-fontfamily;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      letter-spacing: 0.15px;
      text-align: left;
      margin-bottom: 0;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }
    }

    p {
      color: #9F9F9F;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-regular;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
        -webkit-line-clamp: 3;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
        -webkit-line-clamp: 3;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }
    .msg-title-wrp {
      display: flex;
      justify-content: space-between;
      @media all and (min-width: $small-medium) {
        flex-direction: column;
        align-items: flex-end;
      }
    }

    @media all and (min-width: $small-medium) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;

      .msg-content {
        width: 65%;
      }
    }
  }

  .message-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
  }

  .rgt-icon {
    color: #9F9F9F;
    text-align: right;
    @include fontsize(12px);
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    .arrow-right {
      background: url(../../assets/images/claim-arrow.svg) no-repeat;
      height: 11px;
      width: 7px;
      background-size: 7px auto;
      display: block;
      margin-left: 6px;
    }
    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
      font-weight: $fw-medium;
    }
  }
  .mag-time {
    @include fontsize(12px);
    font-weight: $fw-semibold;
    color: #9F9F9F;
    padding-left: 10px;
    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
      font-weight: $fw-medium;
    }
  }

  .from-admin {
    color: #fff;
    @include fontsize(12px);
    font-family: $sourcesans-fontfamily;
    font-weight: $fw-semibold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #18d6dc;
    margin-right: 10px;
    background: #18d6dc;
    white-space: nowrap;
    @media all and (min-width: $small-medium) {
      margin-right: 0px;
    }
  }

  .new {
    color: #fff;
    @include fontsize(12px);
    font-family: $sourcesans-fontfamily;
    font-weight: $fw-semibold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 5px 8.5px 2px;
    border-radius: 4px;
    border: 1px solid $blue;
    background: $blue;

    @media all and (min-width: $small-medium) {
    }
  }
}

// .no-data-scrollbar {
//   display: none;
// }

.message-pagination {
  &.pagintion {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (min-width: $small-medium) {
      // justify-content: flex-end;
    }

    .pagination-list {
      display: flex;

      li {
        // width: 20px;
        // height: 20px;
        // border-radius: 50%;
        // border: 1px solid #bbb8c1;
        // margin-right: 4px;
        // display: flex;
        // align-items: center;
        // cursor: pointer;
        // justify-content: center;

        @media all and (min-width: $small-medium) {
          //   width: 26px;
          //   height: 26px;
          margin-right: 6px;
        }

        @media all and (min-width: $desktop) {
          //   width: 28px;
          //   height: 28px;
        }

        a {
          color: #9f9f9f;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: $fw-semibold;
          width: auto;
          padding: 10px;
          height: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid #9f9f9f;
          margin-right: 4px;

          @media all and (min-width: $desktop) {
            width: auto;
            height: 28px;
            font-size: 0.875rem;
            padding: 10px;
            border-radius: 4px;
          }
        }

        &.selected {
          a {
            border: 1px solid $light-green;
            color: #fff;
            background: $light-green;
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        &.previous {
          a {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              top: 50%;
              background: url(../../assets/images/arrow-right.svg) no-repeat;
              width: 6px;
              height: 10px;
              background-size: 6px auto;
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        &.next {
          a {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              top: 50%;
              width: 6px;
              height: 10px;
              background: url(../../assets/images/arrow-right.svg) no-repeat;
              background-size: 6px auto;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .entry-wrp {
      display: flex;
      align-items: center;

      @media all and (min-width: $small-medium) {
        margin-right: 40px;
      }
    }

    .entry-text {
      color: #221F20;
      text-align: center;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: 600;
      margin-right: 10px;
      text-transform: capitalize;

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
        color: #221F20;
      }
    }

    .select-wrap {
      position: relative;
      border-radius: 14px;
      border: 1px solid #281651;
      width: 45px;
      height: 24px;
      cursor: pointer;

      @media all and (min-width: $desktop) {
        width: 56px;
        height: 29px;
      }

      .icon-arrow {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background: url(../../assets/images/down-arrow.svg) no-repeat;
        width: 8px;
        height: 5px;
        background-size: 8px auto;
        pointer-events: none;
      }

      select {
        color: #281651;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-semibold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        background: transparent;
        border: 1px solid transparent;
        width: 100%;
        padding: 4px 10px;
        cursor: pointer;

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
        }
      }
    }
  }
}
