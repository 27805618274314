@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.health-plan-wrp {
  &.paln-wrp {
    @media all and (min-width: $small-medium) {
      border-radius: 8px;
      border: 1px solid #d4d4d4;
      background: #fff;
      margin-bottom: 40px;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 0;
    }

    h3 {
      text-align: left;
      @include fontsize(16px);
      font-weight: $fw-bold;
      margin-bottom: 10px;

      @media all and (min-width: $small-medium) {
        color: $white-color;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: $fw-bold;
        letter-spacing: 0.15px;
        margin-bottom: 0;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(24px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(26px);
      }
    }

    h4 {
      text-align: left;
      color: #9e9aa6;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      margin-bottom: 10px;

      @media all and (min-width: $small-medium) {
        @include fontsize(10px);
        margin-bottom: 8px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
        margin-bottom: 6px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(16px);
      }
    }

    .progress-value {
      display: flex;
      justify-content: space-between;

      .value {
        @include fontsize(16px);
        color: $blue;
        font-family: $primary-fontfamily;
        font-weight: $fw-semibold;
        letter-spacing: 0.15px;

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(20px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(22px);
        }
      }
    }

    .progress-wrp {
      border-radius: 50px;
      background: rgba(40, 22, 81, 0.05);
      height: 8px;
      margin-bottom: 4px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 8px;
      }
    }

    .actual-progress {
      background: $blue-secondary;
      border-radius: 50px;
      height: 8px;
    }

    .progress-left {
      margin-bottom: 24px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 20px;
      }
    }

    .progress-right {
      margin-bottom: 20px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 24px;
      }
    }

    .btn {
      @media all and (min-width: $small-medium) {
        display: none;
      }
    }

    .health-plan-head {
      @media all and (min-width: $small-medium) {
        padding: 20px;
        border-radius: 8px 8px 0 0;
        background: #00d0be;
      }

      @media all and (min-width: $small-medium) {
        padding: 20px 17px 20px 17px;
      }

      @media all and (min-width: $desktop) {
        padding: 17px;
      }
    }

    .health-body {
      @media all and (min-width: $small-medium) {
        padding: 22px 38px;
      }

      @media all and (min-width: $desktop) {
        padding: 40px 38px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 37px 38px 30px;
      }
    }

    .progress-main {
      @media all and (min-width: $small-medium) {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #cfccd3;
        margin-bottom: 20px;
      }
    }

    .progress-bar {
      @media all and (min-width: $small-medium) {
        width: 47.5%;
        margin-bottom: 18px;
      }

      @media all and (min-width: $desktop) {
        margin-bottom: 38px;
      }
    }

    .chart-wrp {
      display: none;

      .chart-head {
        display: flex;
        justify-content: flex-end;
      }

      @media all and (min-width: $small-medium) {
        display: block;
      }
      @media all and (min-width: $desktop) {
        width: 61%;
      }

      .select-wrp {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          @include fontsize(12px);
          font-weight: $fw-semibold;
          color: $light-green;
          font-family: $primary-fontfamily;
        }

        label {
          color: #737373;
          text-align: right;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          margin-right: 5px;
        }

        .select-option {
          border-radius: 25px;
          border: 1px solid #dedede;
          padding: 4px 10px;
          color: $blue;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: $fw-semibold;
          width: 62px;
        }

        .select-main {
          position: relative;

          .icon-arrow {
            background: url(../../../assets/images/down-arrow.svg) no-repeat;
            height: 8px;
            width: 10px;
            background-size: 10px auto;
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 3px;
          }
        }
        @media all and (min-width: $desktop) {
          position: absolute;
          top: 28px;
          right: 22px;
        }
      }

      .open-chart {
        cursor: pointer;

        .icon-open {
          background: url(../../../assets/images/icon-chart-open.svg) no-repeat;
          width: 20px;
          height: 20px;
          background-size: 20px auto;
          display: block;
        }
      }
    }
  }
  .chart-claim-status {
    position: absolute;
    top: 36px;
    right: 155px;
    width: 97px;
    min-height: 84px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    padding: 15px 13px;
    background-color: #fff;
    display: none;
    z-index: 20;

    @media all and (min-width: $small-medium) {
      display: block;
      top: 156px;
      right: 205px;
    }
    @media all and (min-width: $desktop) {
      top: 36px;
      right: 155px;
    }

    .number-claims-wrap {
      display: flex;
      align-items: center;
      gap: 3px;

      span {
        @include fontsize(10px);
        font-weight: $fw-semibold;
        font-family: $primary-fontfamily;
      }
    }
    .claim-dot {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #18d6dc;
    }
    .claim-type {
      display: flex;
      @include fontsize(10px);
      font-weight: $fw-regular;
      padding: 2px 0 0 18px;
      gap: 3px;
    }
  }
  .apexcharts-xaxis-label tspan {
    font-size: 10px;
    fill: $blue !important;
  }
}
