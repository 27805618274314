@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.quick-content {
  &.quick-access-content {
    .quick-body {
      @media all and (min-width: $small-medium) {
        padding: 18px;
      }

      @media all and (min-width: $small-medium) {
        padding: 20px 18px 43px 18px;
      }
      @media all and (min-width: $desktop) {
        padding: 0px 18px 0px 18px;
      }
    }
  }

  border-radius: 10px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;

  @media all and (min-width: $small-medium) {
    order: 2;
    margin-bottom: 26px;
  }
  @media all and (min-width: $desktop) {
    margin-bottom: 0px;
    width: 29.5%;
  }
  @media all and (min-width: $desktop-1440) {
    // width: 32%;
  }

  .quick-head {
    border-radius: 8px 8px 0px 0px;
    padding: 18px 15px;

    @media all and (min-width: $desktop) {
      padding: 23px;
    }

    h3 {
      margin-bottom: 0;
      @include fontsize(18px);
      font-weight: $fw-bold;
      @media all and (min-width: $desktop) {
        @include fontsize(20px);
        font-weight: $fw-semibold;
      }
    }
  }

  .quick-body {
    padding: 0 14px 15px;

    @media all and (min-width: $small-medium) {
      padding: 20px 18px;
    }

    &.scroll-wrp {
      .track-horizantal {
        display: none;

        @media all and (min-width: $small-medium) {
          display: none;
        }
        @media all and (min-width: $desktop) {
          display: none;
        }
      }
    }

    .icon-wrap {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media all and (min-width: $small-medium) {
        margin-bottom: 10px;
        height: 60px;
      }

      @media all and (min-width: $desktop) {
        height: auto;
        padding: 0 6px;
        margin-bottom: 7px;
      }
    }

    .walmart-title-wrap {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .walmart-title {
      @include fontsize(14px);
      font-weight: $fw-bold;
      color: #053149;
      font-family: $assistant-fontfamily;
    }
    .walmart-logo {
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
    .copay-care-wrap {
      border: 1px dashed #b3b3b3;
      border-radius: 60px;
      text-align: center;
      padding: 6px 10px;
      margin-bottom: 23px;

      @media all and (min-width: $desktop) {
        margin: 0 6px 16px;
      }
    }
    .copay-care-block {
      display: block;
      @include fontsize(12px);
      font-weight: $fw-medium;
      color: #540be0;
    }

    p {
      margin-bottom: 22px;
      color: $drk-gray;
      font-family: $primary-fontfamily;
      @include fontsize(13px);
      font-weight: $fw-medium;
      padding-bottom: 0;
      letter-spacing: normal;
      padding-right: 15px;

      @media all and (min-width: $small-medium) {
        padding-right: 0;
        @include fontsize(12px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
        line-height: 1.3;
        margin-bottom: 20px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
        padding-right: 12px;
      }
    }
    .walmart-text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      button{
        color: #9C9C9C;
        text-decoration: underline;
        margin-bottom: 10px;
        font-family: "Montserrat";
        line-height: 1.5;
        letter-spacing: 0.03px;
        font-weight: 600;
        font-size: 0.75rem;
      }
      &.expanded{
        p{
          display: block;
        }
      }
      p {
        width: 100%;
        line-height: 1.5;
        letter-spacing: 0.03px;
        font-weight: 600;
        margin-bottom: 9px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
        word-break: break-word;
      }
      @media all and (min-width: $small-medium) {
        p {
          margin-bottom: 20px;
        }
      }
      @media all and (min-width: $desktop) {
        padding: 0 7px;
        p {
          @include fontsize(14px);
          margin-bottom: 7px;
        }
      }
    }
    .btn {
      border-radius: 40px;
      border: 2px solid $light-green;
      background: #fff;
      color: $light-green;
      height: 46px;
      font-family: $primary-fontfamily;
      margin-top: auto;
      @include fontsize(16px);
      font-weight: $fw-semibold;

      @media all and (min-width: $small-medium) {
        height: auto;
        @include fontsize(12px);
        width: 200px;
        padding: 10px;
        margin: 0 auto;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
        width: 261px;
        padding: 12px;
        border: 1px solid $light-green;
      }

      @media all and (min-width: $desktop-xl) {
        width: 300px;
        @include fontsize(16px);
      }

      &:hover {
        border: 1px solid transparent;
        background: $light-green;
        color: $white-color;
      }
    }

    .icon-telehealth {
      color: $white-color;
      font-family: $sourcesans-fontfamily;
      @include fontsize(12px);
      letter-spacing: 0.04px;
      text-transform: uppercase;
      border-radius: 4px;
      border: 1px solid #18d6dc;
      background-color: #18d6dc;
      padding: 4px 6px;

      @media all and (min-width: $desktop) {
        @include fontsize(12px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(14px);
      }
    }

    > div {
      max-height: none !important;
      overflow: auto !important;
      margin: 0 !important;

      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        max-height: none !important;
        overflow: auto !important;
        margin: 0 !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .track-horizantal {
        opacity: 1 !important;
        background: #cfccd3;
        height: 1px;

        .thumb-horizantal {
          height: 2px;
          background: $blue;
        }
      }
    }
  }

  .quick-box {
    display: flex;
    flex-direction: column;

    @media all and (min-width: $small-medium) {
      margin-bottom: 20px;
    }

    &.llyod-wrp {
      // margin-bottom: 0px;

      .icon-wrap {
        @media all and (min-width: $desktop) {
          margin-bottom: 16px;
        }
      }

      .walmart-logo {
        width: 73px;

        @media all and (min-width: $small-medium) {
          width: 60px;
          margin-top: 4px;
        }

        @media all and (min-width: $desktop) {
          width: 90px;
          margin-top: 0;
        }
      }
    }

    @media all and (min-width: $small-medium) {
      margin-right: 0px;
      width: 100%;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 43px;
    }

    @media all and (min-width: $desktop-xl) {
      width: 100%;
    }

    &:last-child {
      // margin-bottom: 0;
    }
  }

  .quick-box-main {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: space-between;
    }
  }
}
