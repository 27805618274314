@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.form-block {
  .contact-us-textarea {
    min-height: 145px;
    border-radius: 20px;
    padding: 10px 15px 10px 13px;
  }
  textarea {
    resize: none;
    resize: none;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    &::placeholder {
      color: #b3b3b3;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: $fw-semibold;

      @media all and (min-width: $small-medium) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }
    }
  }
}
.contact-us-btn {
  margin-top: 0px !important;
}

.contact-us-mail {
  &::placeholder {
    color: #b3b3b3;
    font-family: $primary-fontfamily;
    @include fontsize(14px);
    font-weight: $fw-semibold;

    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }
}

.contact-us-page {
  h1 {
    margin-bottom: 20px;
  }
  .error{
    margin-bottom: 0;
  }
  .signin-wrp{
    max-width: 670px;
  }
  textarea{
    color: $blue;
    font-family: $primary-fontfamily;
    @include fontsize(12px);
    &::placeholder {
      color: #b3b3b3;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: $fw-semibold;

      @media all and (min-width: $small-medium) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }
    }
  
    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
    }
  
    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }
  
    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }
}

.thank-contact{
  h1{
      text-align: center;
      margin-top: 24px;
      color: green;
      font-size: 24px;
  }
}

.need-assit{
  font-size: 16px !important;

@media all and (min-width: $desktop-xl) {
  font-size: 20px !important;
}
}
