@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";


.claim-detail-wrapper {
    &:last-child {
        .cost-breakdown {
            .cost-breakdown-columns {
                @media all and (min-width:$small-medium) {
                    padding-bottom: 0;
                }
            }
        }
    }

    .desc-main-wrp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        h3 {
            color: #053149;
            font-family: $primary-fontfamily;
            @include fontsize(12px);
            letter-spacing: 0.25px;
            margin-bottom: 0;

            @media all and (min-width:$desktop) {
                @include fontsize(14px);
            }
        }

        p {
            color: #9E9AA6;
            font-family: $primary-fontfamily;
            @include fontsize(12px);
            margin-bottom: 0;

            @media all and (min-width:$desktop) {
                @include fontsize(14px);
            }
        }

        &.new-des-wrp {
            margin-bottom: 10px;
            padding-bottom: 10px;
            padding-top: 10px;
            margin-top: 10px;
            border-bottom: 1px solid rgba(158, 154, 166, 0.5);
            border-top: 1px solid rgba(158, 154, 166, 0.5);

            @media all and (min-width:$small-medium) {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    .claim-header {
        background: #F9F9F9;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        @media all and (min-width:$small-medium) {
            margin-bottom: 20px;
        }

        @media all and (min-width:$desktop) {
            padding: 20px;
        }


        .claim-icon-wrp {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .main-claim-txt {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .claim-no {
                    color: #053149;
                    font-family: $primary-fontfamily;
                    @include fontsize(12px);
                    font-weight: $fw-bold;
                    letter-spacing: 0.25px;
                    margin-right: 6px;

                    @media all and (min-width:$desktop) {
                        @include fontsize(16px);
                    }
                }
            }

            h4 {
                color: #053149;
                font-family: $primary-fontfamily;
                @include fontsize(12px);
                font-weight: $fw-bold;
                letter-spacing: 0.25px;

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }
            }

            h5 {
                color: #07284B;
                font-family: $primary-fontfamily;
                @include fontsize(16px);
                font-weight: $fw-regular;
                letter-spacing: 0.25px;

                @media all and (min-width:$small-medium) {
                    margin-left: -20px;
                }

                @media all and (min-width:$desktop) {
                    @include fontsize(18px);
                }
            }
        }

        .service-date-wrp {
            border-radius: 2px;
            border: 1px solid #053149;
            padding: 4px;

            .service {
                color: #053149;
                font-family: $primary-fontfamily;
                @include fontsize(8px);
                font-weight: $fw-bold;
                letter-spacing: 0.5px;
                display: block;
                text-transform: capitalize;

                @media all and (min-width:$desktop) {
                    @include fontsize(10px);
                }
            }

            .date {
                color: #053149;
                font-family: $primary-fontfamily;
                @include fontsize(12px);
                font-style: normal;
                font-weight: $fw-bold;
                letter-spacing: 0.5px;
                text-transform: uppercase;

                @media all and (min-width:$desktop) {
                    @include fontsize(14px);
                }
            }
        }
    }

    .paid-btn {
        color: #00D0BE;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        border: 2px solid #00D0BE;
        background-color: transparent;
        text-transform: uppercase;
        padding: 2px;

        @media all and (min-width:$desktop) {
            @include fontsize(14px);
        }

        @media all and (min-width:$desktop-xl) {
            @include fontsize(16px);
        }
    }

    h2 {
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(16px);
        font-weight: 700;
        letter-spacing: 0.15px;
        text-align: left;
        margin-bottom: 10px;
        padding-left: 20px;

        @media all and (min-width:$desktop) {
            @include fontsize(20px);
            margin-bottom: 12px;
        }

        @media all and (min-width:$desktop-xl) {
            padding-left: 35px;
            @include fontsize(26px);

        }
    }

    .member-head {
        background: rgba(233, 232, 238, 0.3);
        padding: 12px 25px 0;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;



        @media all and (min-width:$medium) {
            padding: 20px 30px;
        }

        @media all and (min-width:$desktop-xl) {
            padding: 20px 43px 20px 37px;
            margin-bottom: 40px;
        }

        .member-detail {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            padding-right: 10px;



            .detail {
                width: 33.33%;
                margin-bottom: 10px;

                &.small {
                    width: 10%;
                }

                @media all and (min-width:$medium) {
                    margin-bottom: 20px;
                }

                @media all and (min-width:$desktop) {
                    width: 20%;
                    margin-bottom: 0;
                }

            }

            h3 {
                font-family: $primary-fontfamily;
                color: $blue;
                @include fontsize(14px);
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.25px;
                text-align: left;
                margin-bottom: 2px;

                @media all and (min-width:$medium) {
                    @include fontsize(14px);

                }

                @media all and (min-width:$desktop-1440) {
                    @include fontsize(16px);
                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(18px);
                }
            }

            h4 {
                font-family: $primary-fontfamily;
                color: $blue;
                @include fontsize(14px);
                font-style: normal;
                text-align: left;
                letter-spacing: 0.25px;
                margin-bottom: 0;

                @media all and (min-width:$medium) {
                    @include fontsize(16px);
                }

                @media all and (min-width:$desktop-1440) {
                    @include fontsize(18px);
                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(20px);
                }
            }
        }
    }

    .cost-breakdown {


        padding: 0 20px;

        @media all and (min-width:$desktop-xl) {
            padding: 0 44px 0 35px;
        }

        h2 {
            @include fontsize(14px);

            @media all and (min-width:$desktop) {
                @include fontsize(18px);
            }

            @media all and (min-width:$desktop-xl) {
                @include fontsize(20px);
                padding-left: 22px;
            }

            .summary {
                text-decoration: underline;
            }
        }

        .cost-breakdown-columns {
            border-left: 4px solid #00D0BE;
            padding-left: 39px;
            max-height: 300px;
            overflow: auto;
        }

        .product-wrp {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(158, 154, 166, 0.50);
            padding-bottom: 3px;
            margin-bottom: 3px;

            @media all and (min-width:$small-medium) {
                padding-bottom: 8px;
                margin-bottom: 8px;
            }

            .name {
                color: #737373;
                font-family: $primary-fontfamily;
                @include fontsize(14px);
                font-weight: 400;

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(18px);
                }
            }

            .price {
                color: #053149;
                text-align: right;
                font-family: $primary-fontfamily;
                @include fontsize(14px);

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(18px);
                }
            }
        }

        .final-column {

            text-transform: uppercase;
            display: flex;
            margin-top: 15px;
            align-items: center;

            @media all and (min-width:$desktop-xl) {
                margin-top: 8px;
            }

            .icon-member {
                background: url(../../../assets/images/member-response-icon.svg) no-repeat;
                height: 20px;
                width: 20px;
                background-size: 20px auto;
                display: block;
                margin-right: 8px;
                border-radius: 50%;

                @media all and (min-width:$desktop) {
                    height: 28px;
                    width: 28px;
                    background-size: 28px auto;

                }

                @media all and (min-width:$desktop-xl) {
                    height: 35px;
                    width: 35px;
                    background-size: 35px auto;

                }
            }

            h3 {
                color: #737373;
                font-family: $primary-fontfamily;
                @include fontsize(14px);
                margin-bottom: 0;

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(18px);
                }
            }

            .price {
                margin-left: auto;
                color: $orange;
                font-family: $primary-fontfamily;
                @include fontsize(14px);

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(18px);
                }
            }
        }
    }



}