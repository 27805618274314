@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

//acc
.account-resource-content {
  &.resource-content {
    @media all and (min-width: $small-medium) {
      background: none;
      // padding: 100px 40px 49px;
      padding: 25px;
    }

    @media all and (min-width: $desktop) {
      // padding: 40px 47px 50px;
      padding: 25px 40px;
    }

    @media all and (min-width: $desktop-1440) {
      padding: 25px 77px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 60px 100px;
    }

    @media all and (max-width: 767px) {
      position: relative;
      z-index: 0;
      background: #fff;
      padding-top: 20px;
    }
  }

  .btn-wrap {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #eaeaea;
    }

    .btn {
      display: none;

      @media all and (min-width: $small-medium) {
        display: block;
        width: 186px;
        padding: 10px;
        height: auto;
        @include fontsize(14px);
        font-family: $primary-fontfamily;
      }

      @media all and (min-width: $desktop) {
        padding: 12px 10px;
        @include fontsize(16px);
        width: 200px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 15px 10px;
        @include fontsize(20px);
        width: 300px;
      }
    }
    .delete-acc-btn {
      width: 100%;
      color: $blue;
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      position: relative;
      @include fontsize(16px);
      display: flex;
      align-items: center;
      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
        width: 30%;
        padding-right: 25px;
      }
      @media all and (min-width: $desktop) {
        @include fontsize(16px);
        width: 25%;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
      .icon-delete {
        background: url(../../../assets/images/delete-nw-icon.svg) no-repeat
          center;
        height: 30px;
        width: 30px;
        background-size: 30px auto;
        display: block;
        margin-right: 10px;
        @media all and (min-width: $desktop) {
          height: 30px;
          width: 30px;
          background-size: 30px auto;
        }
      }
      .icon-arrow-right {
        position: absolute;
        display: block;
        width: 8px;
        height: 15px;
        background: url("../../../assets/images/arrow-right.svg") no-repeat
          center;
        background-size: 8px auto;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 10px;
      }
    }
  }

  .forget-pass {
    margin-top: 100px;
    color: $orange;
    cursor: pointer;
    font-family: $primary-fontfamily;
    @include fontsize(12px);
    display: flex;
    align-items: center;

    @media all and (min-width: $small-medium) {
      margin-top: 0;
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }

    .icon-forget {
      background: url(../../../assets/images/lock-forget.svg) no-repeat;
      height: 20px;
      width: 20px;
      background-size: 20px auto;
      display: block;
      margin-right: 7px;

      @media all and (min-width: $desktop) {
        height: 30px;
        width: 30px;
        background-size: 30px auto;
      }

      @media all and (min-width: $desktop-xl) {
        height: 34px;
        width: 34px;
        background-size: 34px auto;
      }
    }
  }

  .edit-img-upload {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaeaea;

    .edit-image-text {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      color: #540be0;
      @include fontsize(14px);
      font-weight: $fw-semibold;
    }
    .edit-image-icon {
      display: block;
      width: 18px;
      height: 18px;
      background: url("../../../assets/images/edit-image-icon.svg") no-repeat
        center;
      background-size: 18px auto;
    }

    .upload-wrp {
      height: 24px;
      width: 24px;
      position: absolute;
      top: 7px;
      left: -5px;
      display: block;
      cursor: pointer;
      margin: 0 0 -21px 0;
      z-index: 221;
      .camera-label{
        cursor: pointer;
      }
    }

    .upload-icon {
      background: url(../../../assets/images/upload.svg) no-repeat;
      height: 24px;
      width: 24px;
      background-size: 24px auto;
      display: block;

      // position: absolute;
      // z-index: 9;
      // top: 7px;
      // left: -5px;
    }

    .cross-wrp-icon {
      height: 24px;
      width: 24px;
      background-size: 24px auto;
      background-color: $white-color;
      position: absolute;
      z-index: 999;
      // top: 7px;
      display: block;
      border-radius: 50%;
      cursor: pointer;
      // left: -5px;
    }

    .cross-icon {
      background: url(../../../assets/images/popup-close.svg) no-repeat;
      display: block;
      height: 24px;
      width: 24px;
      background-size: 24px auto;
      border-radius: 50%;
    }

    .circle-wrp {
      height: 80px;
      width: 80px;
      border-radius: 50%;

      @media all and (min-width: $small-medium) {
        position: static;
        margin: 0;
      }

      .upload-wrp {
        position: relative;
      }
    }
    @media all and (min-width: $small-medium) {
      display: flex;
      align-items: center;
      gap: 15px;
      padding-bottom: 22px;
      .edit-image-text {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #540be0;
        @include fontsize(14px);
        font-weight: $fw-semibold;
      }
      .edit-image-icon {
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../../assets/images/edit-image-icon.svg") no-repeat
          center;
        background-size: 18px auto;
      }
    }
  }

  .img-wrp {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $white-color;
    border-radius: 50%;
    border: 1px solid #540be0;
    padding: 5px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .profile-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 111;
      cursor: pointer;
    }
  }

  button[disabled],
  input[disabled] {
    border-color: transparent;
    color: #6a6666;
  }
  .form-main-block {
    padding: 30px 0px 23px;
    border-radius: 10px;
    background: #fff;

    @media all and (min-width: $small-medium) {
      background-color: $white-color;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      // padding: 63px 0 0px;
      padding-top: 20px;
      padding-left: 0;
      padding-right: 0;
    }

    @media all and (min-width: $desktop-1440) {
      padding-top: 28px;
    }
  }
}
