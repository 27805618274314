@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  &.d-none{
    display: none;
  }
  @media all and (min-width: $small-medium) {
    position: fixed;
  }
  .header-wrap {
    background-color: transparent;
    padding: 20px 0;
    @media all and (min-width: $small-medium) {
      background-color: $light-green;
    }
    @media all and (min-width: $desktop) {
      padding: 36px 84px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 23px 0;
    }
  }

  .user-name {
    color: $gray-1;
    font-family: $primary-fontfamily;
    @include fontsize(10px);
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 55px;
    text-align: center;

    @media all and (min-width: $desktop) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(18px);
    }
  }

  .logo {
    width: 79px;

    &.curs-def{
      cursor: default;
    }

    @media all and (min-width: $small-medium) and (max-width: 852px) {
      width: 70px !important;
    }
    @media all and (min-width: $small-medium) {
      width: 95px;
      display: flex;
      align-items: center;
    }

    // @media all and (min-width: $desktop) {
    //   width: auto;
    //   height: auto;
    // }
  }

  .header-rtl {
    @media all and (min-width: $small-medium) {
      display: flex;
      align-items: center;
    }

    .profile-wrp {
      cursor: pointer;
      margin-left: 20px;

      @media all and (min-width: $desktop) {
        margin-left: 42px;
      }

      @media all and (min-width: $desktop-1440) {
        margin-left: 37px;
      }

      @media all and (min-width: $desktop-xl) {
        margin-left: 37px;
      }
    }

    .btn {
      border: 2px solid $light-green;
      color: $light-green;
      background: transparent;
      // margin-right: 20px;
      font-family: $primary-fontfamily;
      @include fontsize(10px);
      font-weight: $fw-semibold;
      letter-spacing: 0.5px;
      padding: 7.4px 9px;
      height: auto;
      line-height: 1.2;
      border-radius: 6px;

      &:hover {
        background: $light-green;
        color: $blue;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
        // margin-right: 42px;
        border-radius: 10px;
        padding: 10px 14px;
      }

      @media all and (min-width: $desktop-1440) {
        // margin-right: 37px;
      }

      @media all and (min-width: $desktop-xl) {
        // margin-right: 37px;
        @include fontsize(18px);
        padding: 13px 25px;
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (min-width: $small-medium) and (max-width: 852px) {
      padding: 0 20px 0 20px !important;
    }
    @media all and (min-width: $small-medium) {
      padding: 0 20px 0 40px;
    }

    @media all and (min-width: $desktop) {
      padding: 0;
    }
  }

  .navigation {
    @media all and (min-width: $small-medium) and (max-width: 852px) {
      width: 88.5% !important;
    }
    @media all and (min-width: $small-medium) {
      display: flex;
      width: 81.5%;
      align-items: center;
    }
    @media all and (min-width: $medium) and (max-width: 1107px) {
      width: 87.5% !important;
    }
    @media all and (min-width: $medium) {
      width: 80.5%;
    }
    @media all and (min-width: $desktop) and (max-width: 1307px) {
      width: 83.5% !important;
    }
    li {
      display: none;

      @media all and (min-width: $small-medium) {
        margin-right: 9px;
      }
      @media all and (min-width: $medium) {
        margin-right: 21px;
      }

      &:last-child {
        @media all and (min-width: $small-medium) {
          margin-right: 0;
          margin-left: auto;
        }
      }

      @media all and (min-width: $desktop) {
        margin-right: 25px;
      }
      i {
        flex: none;
      }
      a {
        display: block;

        @media all and (min-width: $small-medium) {
          display: flex;
          align-items: center;
        }

        @media all and (min-width: $small-medium) {
          &:hover {
            .icon-home {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .icon-message {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .icon-card {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .icon-care {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .menu-text {
              color: $fish-boy;
            }
          }
          &.active-menu {
            .icon-home {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .icon-message {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .icon-card {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .icon-care {
              svg {
                path {
                  stroke: $fish-boy;
                }
              }
            }

            .menu-text {
              color: $fish-boy;
            }
          }
        }
      }

      .menu-text {
        color: $gray-1;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-regular;
        letter-spacing: 0.5px;

        @media all and (min-width: $small-medium) {
          @include fontsize(12px);
        }
        @media all and (min-width: $medium) {
          @include fontsize(14px);
        }
        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }

      @media all and (min-width: $small-medium) {
        display: block;
      }

      &.message-wrp {
        display: block;
      }

      .icon-message {
        width: 28px;
        height: 28px;
        display: block;
        svg {
          path {
            fill: $white-color;
          }
        }

        @media all and (min-width: $small-medium) {
          width: 14px;
          height: 13px;
          margin-right: 10px;
        }
        @media all and (min-width: $medium) {
          width: 26px;
          height: 26px;
          margin-right: 10px;
        }

        @media all and (min-width: $desktop) {
          width: 26px;
          height: 26px;
        }

        @media all and (min-width: $desktop-xl) {
          width: 28px;
          height: 25px;
          margin-right: 15px;
        }

        svg {
          height: 100%;
          width: 100%;
          display: block;

          path {
            @media all and (min-width: $small-medium) {
              fill: #b6b6b6;
            }
          }
        }
      }

      .icon-home {
        width: 18px;
        height: 13px;
        display: block;

        @media all and (min-width: $small-medium) {
          margin-right: 7px;
          width: 18px;
          height: 13px;

          svg {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        @media all and (min-width: $medium) {
          margin-right: 7px;
          width: 26px;
          height: 26px;
        }

        @media all and (min-width: $desktop) {
          width: 26px;
          height: 26px;
          margin-right: 4px;
        }

        @media all and (min-width: $desktop-xl) {
          width: 25px;
          height: 25px;
          margin-right: 15px;
        }
      }

      .icon-card {
        width: 15px;
        height: 12px;
        display: block;

        @media all and (min-width: $small-medium) {
          margin-right: 10px;

          svg {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        @media all and (min-width: $desktop) {
          width: 23px;
          height: 18px;
          margin-right: 10px;
        }

        @media all and (min-width: $desktop-xl) {
          width: 31px;
          height: 22px;
          margin-right: 13px;
        }
      }

      .icon-care {
        width: 14px;
        height: 18px;
        display: block;

        @media all and (min-width: $small-medium) {
          margin-right: 10px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        @media all and (min-width: $desktop) {
          width: 25px;
          height: 20px;
          margin-right: 12px;
        }

        @media all and (min-width: $desktop-xl) {
          width: 31px;
          height: 23.3px;
          margin-right: 15px;
        }
      }
    }
  }

  .icon-profile {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 2px;
    border: 0.5px solid #fff;

    @media all and (min-width: $desktop) {
      width: 30px;
      height: 30px;
      margin-top: 3px;
    }

    @media all and (min-width: $desktop) {
      width: 34px;
      height: 34px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .logout-btn {
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    span {
      font-weight: 400 !important;
    }
    &:hover {
      span {
        color: #18d6dc !important;
      }
      .icon-logout {
        svg {
          path {
            stroke: #18d6dc;
            &:nth-child(2) {
              fill: #18d6dc;
            }
          }
        }
      }
    }
    &.active-menu {
      span {
        color: #18d6dc !important;
      }
      .icon-logout {
        svg {
          path {
            stroke: #18d6dc;
            &:nth-child(2) {
              fill: #18d6dc;
            }
          }
        }
      }
    }
  }
  .icon-logout {
    display: flex;
    width: 20px;
    height: 16px;
    justify-content: center;
    align-items: center;
    @media all and (min-width: $medium) {
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.cms-logo{
  display: none;
  @media all and (min-width:768px) {
    display: block;
  }
}
