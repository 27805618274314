@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";
.spouse-wrap{
    border: 1px solid #EAEAEA;
    margin-bottom: 16px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    padding: 8px 14px;
    border-radius: 10px;

    table{
        width: 100%;

        tr{
            &:not(:last-child){
                border-bottom: 1px solid #EAEAEA;
            }
        }

        th{
            text-align: left;
            padding: 10px 0;
            @include fontsize(14px);
            font-weight: $fw-semibold;
            font-family: $primary-fontfamily;
            color: $voilet-blue;

            &:last-child{
                text-align: right;
            }
        }
        td{
            text-align: left;
            padding: 9px 0;
            @include fontsize(12px);
            font-weight: $fw-semibold;
            font-family: $primary-fontfamily;
            color: $blue;

            &:last-child{
                text-align: right;
                @include fontsize(14px);
                font-weight: $fw-medium;
            }

        }
    }
    

    @media all and (min-width:768px) {
        border: none;
        box-shadow: none;
        padding: 15px 21px 0px 23px;
        table{
        th{
            @include fontsize(18px);
            font-weight: $fw-semibold;

        }
        td{
            @include fontsize(18px);
            font-weight: $fw-regular;
            &:last-child{
                @include fontsize(18px);
                font-weight: $fw-regular;
            }
        }
    }
        
    }
}