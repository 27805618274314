@import "../../../../assets/styles/mixin.scss";
@import "../../../../assets/styles/variable.scss";
// .header{
//     @media all and (max-width:767px){
//         display: none;
//     }

// }
.drug-search-wrap {
  .header-banner {
    min-height: 250px;
    background: url("../../../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }
    @media all and (min-width: $desktop) {
      padding: 44px 84px;
      .container {
        padding: 20px 0px;
      }
    }
    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    margin-bottom: 24px;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  .drug-content {
    @media all and (min-width: $small-medium) {
      margin-top: -201px;
    }

    .container {
      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
  }
  .drug-block {
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    padding-bottom: 20px;

    @media all and (min-width: $small-medium) {
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      padding: 36px 39px 25px;
    }
  }
  .drug-head {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 40px;

    @media all and (min-width: $small-medium) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 17px;
    }
  }
  .drug-search {
    margin-bottom: 15px;
    width: 100%;

    @media all and (min-width: $small-medium) {
      width: 38%;
    }
    @media all and (min-width: $desktop) {
      width: 40%;
    }
  }
  .search-wrap {
    position: relative;
  }
  .drug-search-input {
    display: block;
    width: 100%;
    padding: 18px 40px 18px 20px;
    border: 1px solid $light-green;
    border-radius: 50px;
    outline: none;
    @include fontsize(14px);
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    color: $light-green;

    &::placeholder {
      @include fontsize(14px);
      font-family: $primary-fontfamily;
      font-weight: $fw-semibold;
      color: #b3b3b3;
    }
    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
      &::placeholder {
        @include fontsize(16px);
      }
    }
  }
  .search-btn {
    position: absolute;
    display: block;
    width: 27px;
    height: 14px;
    border: none;
    outline: none;
    background: none;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 14px;
    @media all and (min-width: $small-medium) {
      width: 28px;
      height: 28px;
    }
  }
  .search-icon {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../../../assets/images/drug-search-icon.svg") no-repeat;
    background-size: 27px auto;
    @media all and (min-width: $small-medium) {
      background-size: 28px 28px;
    }
  }
  .copayment-wrap {
    display: flex;
    align-items: center;
    background-color: #c8f7f9;
    width: 249px;
    border-radius: 50px;
    justify-content: flex-end;
    padding: 4px 4px 4px 18px;

    @media all and (min-width: $small-medium) {
      width: 307px;
      margin-bottom: 20px;
      padding: 4px 18px 4px 4px;
      flex-direction: row-reverse;
    }
    @media all and (min-width: $desktop) {
      width: 311px;
    }
  }
  .copayment-text {
    @include fontsize(12px);
    font-weight: $fw-semibold;
    font-family: $primary-fontfamily;
    color: $light-green;
    margin-right: auto;
    display: block;
    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
      margin-right: 0;
      margin-left: auto;
    }
  }
  .copayment-icon-wrp {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $light-green;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    @media all and (min-width: $small-medium) {
      margin-right: 10px;
      margin-left: 0;
    }
  }
  .copayment-icon {
    display: block;
    width: 22px;
    height: 22px;
    background: url("../../../../assets/images/copayment-icon.svg") no-repeat;
    background-size: 22px auto;
  }
  .drug-table {
    @media all and (min-width: $small-medium) {
      // border-bottom: 2px solid #EAEAEA;
    }
    table {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    thead tr {
      th {
        padding: 22px 10px;
        color: $white-color;
        @include fontsize(12px);
        font-weight: $fw-bold;
        font-family: $primary-fontfamily;
        background: $light-green;
        text-align: left;
        width: 25%;

        &:first-child {
          border-radius: 10px 0 0 0;
          @media all and (min-width: $small-medium) {
            border-radius: 10px 0 0 10px;
          }
        }
        &:last-child {
          border-radius: 0 10px 0 0;
          @media all and (min-width: $small-medium) {
            border-radius: 0 10px 10px 0;
          }
        }
        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
          padding: 20px 40px;
          font-weight: $fw-semibold;
          &:not(:first-child) {
            text-align: right;
            padding: 20px 15px;
          }

          &:last-child {
            padding: 20px 40px 20px 15px;
          }
        }
      }
    }
    tbody {
      tr {
        @media all and (min-width: $small-medium) {
          border-bottom: 2px solid #eaeaea;
          td {
            padding: 22px 41px;
            &:not(:first-child) {
              text-align: right;
              padding: 22px 11px;
            }
            &:last-child {
              padding: 20px 40px 20px 15px;
            }
          }
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 10px;
              @media all and (min-width: $small-medium) {
                border-radius: 0;
              }
            }
            &:last-child {
              border-radius: 0 0 10px 0;
              @media all and (min-width: $small-medium) {
                border-radius: 0;
              }
            }
          }
        }
      }
    }
    td {
      background-color: #f2f2f2;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      font-family: $primary-fontfamily;
      color: $blue;
      padding: 13px 6px;
      border: 1px solid #fff;
      border-collapse: collapse;
      &:first-child {
        background-color: #f9f7fe;
      }
      @media all and (min-width: $small-medium) {
        border: none;
        @include fontsize(16px);
        font-weight: $fw-regular;
      }
    }
  }
  .pagination-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .entries-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .entries-text {
    @include fontsize(12px);
    font-weight: $fw-semibold;
    font-family: $primary-fontfamily;
    color: $blue;
    @media all and (min-width: $small-medium) {
      @include fontsize(16px);
    }
  }
  .entries-select {
    width: 53px;
    height: 29px;
    border: 1px solid $light-green;
    border-radius: 50px;
    position: relative;
    z-index: 10;

    select {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      border-radius: 50px;
      padding: 2px 21px 2px 6px;
      @include fontsize(12px);
      font-weight: $fw-semibold;
    }
  }
  .entries-btn {
    display: block;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 16px;
    height: 16px;
    pointer-events: none;
  }
  .entries-icon {
    display: block;
    width: 100%;
    height: 100%;
    background: url("../../../../assets/images/select-arrow.svg");
    background-size: 16px auto;
  }
  .page-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .page-number {
    display: flex;
    width: 29px;
    height: 29px;
    border: 1px solid #dedede;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: #cecece;
    cursor: pointer;
    @include fontsize(12px);
    font-weight: $fw-semibold;

    &.active {
      background-color: $light-green;
      border-color: $light-green;
      color: $white-color;
    }
  }
  .previous-icon {
    display: block;
    width: 16px;
    height: 16px;
    background: url("../../../../assets/images/page-arrow.svg");
    background-size: 16px auto;
    transform: rotateY(180deg);
  }
  .previous-page {
    display: none;
  }
  .next-icon {
    display: block;
    width: 16px;
    height: 16px;
    background: url("../../../../assets/images/page-arrow.svg");
    background-size: 16px auto;
  }
}
