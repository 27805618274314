@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";
.claims-expand-wrap {
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
        margin: 0;
        padding-right: 0;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      background-color: #e9e8ee;
      padding: 57px 0 25px;
    }
  }
}
.main-claim-nw {
  &.claim-expand-wrp {
    &.shadow-claim {
      .no-data-container {
        @media all and (min-width: $desktop) {
          padding: 0;
        }
      }

      .no-data {
        min-height: calc(100vh - 200px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;

        @media all and (min-width: $small-medium) {
          margin-top: 20px;
        }

        p {
          @include fontsize(18px);
          font-weight: $fw-semibold;

          @media all and (min-width: $desktop) {
            @include fontsize(22px);
          }
        }
      }

      @media all and (min-width: $small-medium) {
        padding: 0 20px;
        background: #e9e8ee;
        min-height: calc(100vh - 100.6px);
        border-radius: 0;
      }

      @media all and (min-width: $desktop) {
        padding: 0;
      }

      @media all and (min-width: $desktop-xl) {
        min-height: calc(100vh - 101.4px);
      }

      box-shadow: none;
      border-bottom: 0;

      .claim-container {
        background-color: #fff;
        .claim-side-wrap {
          display: none;
        }
        @media all and (min-width: $small-medium) {
          // margin-top: 20px;
          border: 1px solid #ececec;
          box-shadow: 0px 0px 17px 7px rgb(0 0 0 / 4%);
          border-radius: 30px 30px 0 0;
          padding-bottom: 0;
        }
        @media all and (min-width: $desktop) {
          .claim-side-wrap {
            width: 24%;
            background: #f9f7fe;
            padding: 20px;
          }
          .side-claim-block {
            margin-bottom: 20px;
            padding: 10px 15px;
            cursor: pointer;
            .side-claim-name {
              @include fontsize(14px);
              font-weight: $fw-semibold;
              color: #9f9f9f;
            }
            .side-claim-number {
              @include fontsize(12px);
              font-weight: $fw-regular;
              color: #9f9f9f;
            }

            &:hover {
              background: #fff;
              border-radius: 50px;

              .side-claim-name {
                color: $blue;
              }
              .side-claim-number {
                color: $blue;
              }
            }
          }
        }
      }

      @media all and (min-width: $small-medium) {
        padding: 30px 20px 0;
      }

      .claim-detail-wrapper {
        .claim-header {
          @media all and (max-width: 767px) {
            background: transparent;
          }
        }
      }
    }

    .claim-detail-wrapper {
      padding: 24px 20px 21px;
      @media all and (min-width: $small-medium) {
        padding: 0;
        .head-insurnce {
          background-color: transparent;
        }
      }
      @media all and (min-width: $desktop) {
        // width: 76%;
      }
    }

    @media all and (min-width: 768px) {
      border-radius: 8px;
      border: 1px solid #ececec;
      background: #fff;
      box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
      padding-top: 0;
    }

    .secondary-header {
      @media all and (min-width: 768px) {
        display: none;
      }
    }

    .head-insurnce {
      @media all and (min-width: 768px) {
        background: $white-color;
        padding: 0;
        border-radius: 8px 8px 0 0;
        border: none;
        box-shadow: none;
        margin-bottom: 16px;
      }

      h2 {
        @media all and (min-width: 768px) {
          color: #053149;
          font-family: $primary-fontfamily;
          @include fontsize(18px);
          font-weight: $fw-semibold;
          letter-spacing: 0.15px;
          margin-bottom: 20px;
          padding: 0;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(20px);
          color: #053149;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(26px);
        }
      }
    }

    .claim-insuranced-wrp {
      .claim-main-wrap {
        ul {
          margin-bottom: 0;
          li {
            &:first-child {
              justify-content: flex-start;
              .claim-expand-key {
                padding-right: 0px;
                @include fontsize(14px);
                font-weight: $fw-medium;
                color: $blue;

                @media all and (min-width: $small-medium) {
                  font-weight: $fw-semibold;
                }
              }
              .claim-expand-value {
                padding-left: 0px;
                @include fontsize(14px);
                font-weight: $fw-medium;
                color: $blue;
                margin-left: 4px;

                @media all and (min-width: $small-medium) {
                  @include fontsize(18px);
                  font-weight: $fw-regular;
                  margin-left: 0px;
                }
              }
            }
            &:last-child {
              background: none;
            }
            &:nth-last-child(2)::after {
              display: block;
            }
            .claim-expand-value {
              @media all and (min-width: $small-medium) {
                @include fontsize(18px);
                font-weight: $fw-regular;
              }
            }
            .claim-expand-key {
              @media all and (min-width: $small-medium) {
                font-weight: $fw-semibold;
                @include fontsize(14px);
                margin-bottom: 4px;
              }
            }
          }
          @media all and (min-width: 767px) {
            display: flex;
            width: 100%;
            gap: 43px;
            li {
              flex-direction: column;
              padding: 0;
              &:not(:last-child)::after {
                display: none;
              }
            }
            .claim-expand-value {
              text-align: initial;
            }
            .claim-expand-key {
              text-align: initial;
              color: #540be0;
            }
          }
        }
      }
      &.new-claim {
        @media all and (max-width: 767px) {
          display: flex;
          flex-direction: column;

          .claim-main-wrap {
            order: 2;
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;

            &.second-claim {
              order: 1;
              border-bottom: 1px solid rgba(158, 154, 166, 0.5);
              padding-bottom: 17px;
              margin-bottom: 17px;
            }
          }

          .service-date-wrp {
            display: block;

            .service {
              text-align: left;
            }
          }
        }
        .claim-expand-key {
          color: $blue !important;
        }
      }

      @media all and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        padding: 5px 21px;
        background: none;
        align-items: flex-start;
      }

      @media all and (min-width: $desktop) {
        padding: 5px 21px;
      }

      .claim-main-wrap {
        .claim-name {
          @media all and (min-width: 768px) {
            margin-right: 35px;
          }
        }

        @media all and (min-width: 768px) {
          margin-bottom: 0;
          order: 2;
          border-bottom: 0;
          width: 100%;
          background: #f9f7fe;
          padding: 21px 22px 27px;
          border-radius: 10px;
        }

        @media all and (min-width: $desktop) {
          width: 100%;
        }

        &.second-claim {
          @media all and (min-width: 768px) {
            order: 1;
          }
        }

        .payment-status {
          // text-transform: uppercase;
          @media all and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          @media all and (min-width: $desktop) {
            line-height: 1.3;
          }
        }
      }
    }

    .claim-container {
      @media all and (min-width: $small-medium) {
        max-width: 1160px;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
      }

      @media all and (min-width: $desktop-1440) {
        max-width: 1272px;
      }

      @media all and (min-width: $desktop-1600) {
        max-width: 1440px;
      }

      @media all and (min-width: $desktop-xl) {
        max-width: 1720px;
      }
    }
  }

  ul {
    margin-bottom: 20px;
    width: 100%;
    li {
      display: flex;
      padding: 8px 15px 9px;
      justify-content: space-between;
      position: relative;
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 1px;
        width: 88%;
        background: #eaeaea;

        @media all and (min-width: $small-medium) {
          width: 96%;
        }
      }
      &:nth-last-child(2)::after {
        display: none;
        @media all and (min-width: $small-medium) {
          display: block;
        }
      }
      &:last-child {
        background: #c8f7f9;
        @media all and (min-width: $small-medium) {
          background: none;
          .claim-expand-value {
            font-weight: $fw-semibold;
          }
          .claim-expand-key {
            font-weight: $fw-semibold;
          }
        }
      }
      &:first-child {
        .claim-expand-value {
          color: #360485;
          @media all and (min-width: $small-medium) {
            font-weight: $fw-semibold;
          }
        }
        .claim-expand-key {
          color: #360485;
          @media all and (min-width: $small-medium) {
            font-weight: $fw-semibold;
          }
        }
      }
      @media all and (min-width: $small-medium) {
        padding: 8px 23px 9px;
      }
    }
    .claim-expand-value {
      text-align: right;
      @include fontsize(14px);
      font-weight: $fw-medium;

      @media all and (min-width: $small-medium) {
        @include fontsize(18px);
        font-weight: $fw-regular;
      }
    }
    .claim-expand-key {
      text-align: left;
      @include fontsize(12px);
      font-weight: $fw-semibold;

      @media all and (min-width: $small-medium) {
        @include fontsize(18px);
        font-weight: $fw-regular;
      }
    }
    @media all and (min-width: $small-medium) {
      margin-bottom: 20;
    }
  }
  .insurance-head-wrppr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 15px 0;
    @media all and (min-width: $small-medium) {
      padding: 19px 18px 0;
    }
  }
  .payment-status {
    @include fontsize(12px);
    font-weight: $fw-semibold;
    background: #6fdd88;
    padding: 4px 5px;
    border-radius: 4px;
    color: #fff;

    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  .procedure-wrap {
    padding-top: 0px;
    @media all and (min-width: $small-medium) {
      border: 1px solid #eaeaea;
      box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
    }
    h2 {
      @include fontsize(16px);
    }

    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .claim-payment-wrap {
    align-items: center;
    gap: 4px;
    display: none;
    .status-wrap {
      @include fontsize(14px);
      font-weight: $fw-semibold;
    }
    @media all and (min-width: $small-medium) {
      display: flex;
      gap: 10px;
    }
  }
  .number-after {
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
}
