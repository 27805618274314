@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.care-benefits {
  .footer {
    @media all and (min-width: $medium) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media all and (min-width: $desktop-xl) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    margin-bottom: 23px;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
        margin: 0;
        padding-right: 0;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      background-color: transparent;
      padding: 57px 0 25px;
      margin-bottom: 20px;
    }
  }

  background: #e9e8ee;

  @media all and (min-width: $small-medium) {
    min-height: calc(100vh - 73.19px);
  }

  @media all and (min-width: $desktop) {
    min-height: calc(100vh - 100.6px);
  }

  @media all and (min-width: $desktop-xl) {
    min-height: calc(100vh - 101.4px);
  }

  .cover-table {
    @media all and (min-width: $small-medium) {
      margin-top: 16px;
    }

    @media all and (min-width: $desktop) {
      margin-top: 0;
    }
  }

  .claim-section-wrapper {
    @media all and (max-width: 767px) {
      background: transparent !important;
    }

    &.claim-expanded {
      @media all and (min-width: $small-medium) {
        background: rgba(0, 0, 0, 0.5) !important;
      }

      @media all and (min-width: $desktop) {
        padding: 0;
      }

      .claim-expand-wrp {
        @media all and (min-width: 768px) {
          border-radius: 8px;
          border: 1px solid #ececec;
          background: #fff;
          box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
          padding-top: 0;
        }

        .secondary-header {
          @media all and (min-width: 768px) {
            display: none;
          }
        }

        .head-insurnce {
          @media all and (min-width: 768px) {
            background: $white-color;
            padding: 40px 0 20px;
            border-radius: 8px 8px 0 0;
          }

          h2 {
            @media all and (min-width: 768px) {
              color: #053149;
              font-family: $primary-fontfamily;
              @include fontsize(18px);
              font-weight: $fw-bold;
              letter-spacing: 0.15px;
              margin-bottom: 20px;
              padding: 0 20px;
            }

            @media all and (min-width: $desktop) {
              padding: 0 40px;
              @include fontsize(24px);
              color: #281651;
            }

            @media all and (min-width: $desktop-xl) {
              @include fontsize(26px);
            }
          }
        }

        .claim-insuranced-wrp {
          @media all and (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            padding: 24px 40px;
            background: rgba(233, 232, 238, 0.3);
            align-items: flex-start;
          }

          @media all and (min-width: $desktop) {
            padding: 24px 80px;
          }

          .claim-main-wrap {
            .claim-name {
              @media all and (min-width: 768px) {
                margin-right: 35px;
              }
            }

            @media all and (min-width: 768px) {
              margin-bottom: 0;
              padding-bottom: 0;
              order: 2;
              border-bottom: 0;
            }

            &.second-claim {
              @media all and (min-width: 768px) {
                order: 1;
              }
            }

            .payment-status {
              @media all and (min-width: 768px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }

              @media all and (min-width: $desktop) {
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
  }

  .data-wrp {
    .net-panel {
      position: relative;

      @media all and (min-width: $small-medium) {
        &::after {
          content: "";
          position: absolute;
          top: 33px;
          left: -49px;
          width: 33px;
          height: 7px;
          background: url(../../assets/images/line-network.svg) no-repeat;
        }
      }
    }

    > .network-wrp {
      @media all and (min-width: $small-medium) {
        padding-left: 50px;
        position: relative;
        margin-bottom: 0;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          background: #281651;
          height: 100%;
        }
      }

      .health-plan-head {
        h3 {
          @include fontsize(16px);

          @media all and (min-width: $desktop) {
            @include fontsize(24px);
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(26px);
          }
        }
      }
    }
  }

  .container {
    padding: 0;

    @media all and (min-width: $small-medium) {
      padding: 20px 40px 0;
    }

    @media all and (min-width: $desktop) {
      padding: 20px 26px 0;
    }
  }

  .care-data {
    padding: 0px 20px 25px 20px;
    background-color: $white-color;

    @media all and (max-width: 767px) {
      position: relative;
      z-index: 0;
      min-height: 100vh;
    }

    // &::after {
    //   @media all and (max-width: 767px) {
    //     background: url(../../assets/images/mob-banner-plan.png) no-repeat;
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 287px;
    //     left: 0;
    //     top: 0;
    //     z-index: -1;
    //     border-radius: 0 0 40px 0;
    //     background-size: cover;
    //   }
    // }

    @media all and (max-width: 767px) {
      background: #e9e8ee;
    }

    @media all and (min-width: $small-medium) {
      border-radius: 30px 30px 0 0;

      padding: 20px 30px 0px;
      min-height: calc(100vh - 241px);
    }

    @media all and (min-width: $desktop) {
      padding: 12px 38px 0px 38px;
      // min-height: calc(100vh - 350px);
    }

    @media all and (min-width: $desktop-xl) {
      padding: 30px 60px 0px 60px;
      // min-height: calc(100vh - 400px) !important;
    }
  }

  .react-tabs__tab-list {
    border-bottom: 0;
    margin-bottom: 20px !important;
    padding-bottom: 0 !important;
  }

  .react-tabs__tab {
    color: $white-color;
    font-family: $primary-fontfamily;
    font-style: normal;
    font-weight: $fw-semibold;
    letter-spacing: 0.15px;
    @include fontsize(14px);
    border: 1px solid #fff;
    margin-bottom: 0 !important;
    margin-right: 6px;
    padding: 11px 22px;
    border-radius: 50px;
    background: transparent;

    @media all and (min-width: $small-medium) {
      // @include fontsize(16px);
      padding: 18px;
      background: #dddbe0;
      margin-right: 13px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
      // line-height: 1.4;
      padding: 14px 20px;
    }

    @media all and (min-width: $desktop-xl) {
      // margin-right: 100px;
      padding-bottom: 20px;
      @include fontsize(22px);
    }

    &.react-tabs__tab--selected {
      border: 0;
      background: $blue;
    }
  }

  .tab-content {
    padding: 20px;
    border-radius: 8px;
    background: #f9f9f9;

    &.claim-section-wrapper {
      @media all and (max-width: 767px) {
        padding: 0;
      }
    }

    @media all and (min-width: $small-medium) {
      background: transparent !important;
      padding: 0 !important;
    }
  }
}
