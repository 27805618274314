@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.claim-index {
  display: none;

  @media all and (min-width: $small-medium) {
    padding: 20px 18px;
    display: block;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    background: #fff;
    margin-bottom: 10px;
  }

  .claim-body {
    .claim-wrp {
      height: 100%;
      display: flex;
      flex-direction: column;
      @media all and (min-width: $small-medium) {
        border-bottom: 1px solid #cfccd3;
        padding: 15px 20px 10px 20px;

        &:last-child {
          border-bottom: 0;
        }
      }

      @media all and (min-width: $desktop) {
        padding: 12px 0 11px;
        margin: 0 20px;
      }

      @media all and (min-width: $desktop) {
        padding: 11px 0;
      }

      &.void-wrp {
        .payment-status {
          color: #1e82a3;

          border: 2px solid #1e82a3;
        }
      }

      &.denied-wrp {
        .payment-status {
          color: #ff451a;

          border: 2px solid #ff451a;
        }
      }
    }
  }
  .payment-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .payment-status {
      @include fontsize(12px);
      font-weight: $fw-semibold;
      padding: 5px 3px;
      background: #6fdd88;
      border-radius: 4px;
      color: $white-color;
    }
    .payment-value {
      @include fontsize(14px);
      font-weight: $fw-semibold;
      color: $voilet-blue;
    }
  }
  .btn-claim-new {
    padding-top: 25px;
    margin-top: auto;
  }
  .claim-btn {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }

    @media all and (min-width: $small-medium) {
      margin-top: 15px;
    }

    .btn {
      @media all and (min-width: $small-medium) {
        width: 186px;
        height: auto;
        margin-top: 0;
        color: #fff;
        padding: 10px;
        text-align: center;
        @include fontsize(14px);
        letter-spacing: 0.175px;

        &:hover {
          color: $blue;
        }

        // &:focus {
        //     color: $blue;
        // }

        @media all and (min-width: $desktop) {
          width: 200px;
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
          width: 300px;
          padding: 12px;
        }
      }
    }
  }

  .claim-name {
    display: flex;
    justify-content: space-between;

    @media all and (min-width: $small-medium) {
      margin-bottom: 5px;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 10px;
    }

    h3 {
      @media all and (min-width: $small-medium) {
        margin-bottom: 0;
        white-space: pre-wrap;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }
    }

    .payment-status {
      color: $light-green;
      font-family: $primary-fontfamily;
      @include fontsize(10px);
      font-weight: $fw-bold;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 2px 1px;
      border-radius: 2px;
      border: 2px solid #00d0be;
      min-width: 52px;

      @media all and (min-width: $desktop-xl) {
        @include fontsize(12px);
      }
    }
  }

  .claim-detail {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    h3 {
      color: $light-green;
      @media all and (min-width: $small-medium) {
        margin-bottom: 10px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }

    .claim-amt {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(12px);

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }
  }
}

.tab-hide {
  display: none;

  @media all and (min-width: $desktop) {
    display: block;
  }
}
.no-data-nw {
  width: 100%;
  display: flex;
  justify-content: center;
}
.slick-prev {
  left: -15px;
  &::before{
    color: #000;
  }
}

.slick-prev.slick-disabled {
  display: none !important;
}
.slick-next.slick-disabled {
  display: none !important;
}


.slick-next {
  right: -16px;
  &::before{
    color: #000;
  }
}