@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.auth-insight {
  .signin-wrp {
    @media all and (min-width: $small-medium) and (max-width: 1439px) {
      padding-top: 30px;
    }

    p {
      @media all and (min-width: $small-medium) and (max-width: 1279px) {
        margin-bottom: 20px;
      }

      @media all and (min-width: $desktop-xl) {
        margin-bottom: 40px;
      }
    }



    @media all and (min-width: $desktop-xl) {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  .select-dob {
    .form-control {
      height: 55px;
      background: #f9f9f9;

      .MuiInputBase-formControl {
        height: 100%;

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }
      }

      input {
        border: 2px solid transparent;
        border-radius: 6px;
        padding: 10px 55px 10px 13px;
        width: 100%;
        color: #053149;
        font-family: $primary-fontfamily;
        height: 100%;
        @include fontsize(12px);

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }
    }
  }

  .bck-btn-wrap {
    position: absolute;
    top: 25px;
    right: 21px;
    display: flex;
    align-items: center;
    @include fontsize(16px);
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
      top: 36px;
      right: 0;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
      top: 40px;
      right: -50px;
    }

    @media all and (min-width: $desktop-1440) {
      right: -57px;
      top: 50px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
      top: 90px;
      // right: -188px;
    }

    .icon-back {
      background: url(../../../assets/images/back.svg) no-repeat;
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      display: block;
      margin-right: 5px;
    }
  }

  h1 {
    @include fontsize(32px);
    line-height: 1.09;
    margin-bottom: 10px;

    @media all and (min-width: $small-medium) {
      @include fontsize(36px);
      line-height: 1;
    }

    @media all and (min-width: $small-medium) and (max-width: 1439px) {
      @include fontsize(30px);
      padding-right: 90px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(36px);
      padding-right: 140px;
      margin-bottom: 20px;
    }

    @media all and (min-width: $desktop-1440) {
      @include fontsize(36px);
      padding-right: 120px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(54px);
    }
  }

  // p {
  //     font-family: $primary-fontfamily;
  //     @include fontsize(14px);
  //     padding-right: 50px;
  //     letter-spacing: 0.25px;
  //     line-height: 1.3;
  //     margin-bottom: 44px;

  //     @media all and (min-width:$small-medium) {
  //         @include fontsize(12px);
  //         font-weight: $fw-semibold;
  //         word-spacing: 1px;
  //         padding-right: 61px;
  //         line-height: 1.4;
  //         margin-bottom: 103px;
  //     }

  //     @media all and (min-width:$desktop) {
  //         @include fontsize(16px);
  //         word-spacing: normal;
  //         padding-right: 30px;
  //         line-height: 1.48;
  //         margin-bottom: 31px;
  //     }

  //     @media all and (min-width:$desktop-1440) {
  //         padding-right: 112px;
  //         margin-bottom: 103px;
  //     }

  //     @media all and (min-width:$desktop-xl) {
  //         @include fontsize(20px);
  //         padding-right: 150px;
  //         margin-bottom: 96px;
  //     }
  // }

  .form-label {
    letter-spacing: 0.25px;
  }

  // .signin-wrp {
  //     padding-bottom: 22px;

  //     @media all and (min-width:$small-medium) {
  //         padding-top: 0;
  //         padding-bottom: 93px;
  //         margin-top: 96px;
  //     }

  //     @media all and (min-width:$desktop-xl) {
  //         margin-top: 106px;
  //     }

  //     .bottom-wrp {
  //         padding-top: 0;
  //         margin-top: 22px;

  //         @media all and (min-width:$small-medium) {
  //             padding-bottom: 65px;
  //         }

  //         @media all and (min-width:$desktop) {
  //             padding-top: 0;
  //             margin-top: 75px;
  //         }

  //         @media all and (min-width:$desktop-1440) {
  //             padding-top: 0;
  //             margin-top: 20px;
  //         }
  //     }
  // }
}