/*
 Font-family
 -------------------------*/
$primary-fontfamily: "Montserrat";
$assistant-fontfamily:  "Assistant";
$sourcesans-fontfamily:  "Source Sans 3";

/*
 Font-color
 -------------------------*/
$white-color: #ffffff;
$black: #000000;
$blue: #221F20;
$blue-secondary: #0D4E71;
$light-green: #240151;
$light-gray: #F2F2F2;
$gray: #D6D6D6;
$gray-text : #B2B2B2;
$light-purple: #E9E8EE;
$drk-gray: #9C9C9C;
$gray-1: #B6B6B6;
$orange : #F57D20;
$voilet-blue: #540BE0;
$fish-boy: #18D6DC;

/*
 Font-weight
 -------------------------*/
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: bold;
$fw-700: 700;
$fw-extrabold: 800;


/*
 Background color
 -------------------------*/

/*
 Border-color
 -------------------------*/

/*
 Alignment
 -------------------------*/
$align-center: center;
$align-left: left;

// media queries
$small: 320px;
$small-375: 375px;
$small-425: 425px;
$small-medium: 768px;
$medium: 1024px;
$desktop: 1280px;
$desktop-1440: 1440px;
$desktop-1500: 1500px;
$desktop-1600: 1600px;
$desktop-xl: 1920px;


// display Property
$block: block;
$inline-block: inline-block;
$flex: flex;
$none: none;
// text-transform Property
$capitalize: capitalize;
$uppercase: uppercase;