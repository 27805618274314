@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.provider-detail-wrp {
  .react-tabs__tab-list {
    border-bottom: none;
    margin-bottom: 24px;
    padding: 0 20px;

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }

    .react-tabs__tab {
      // border: none;
      background: #F2F2F2;
      border: 1px solid transparent;
      color: #D9D9D9;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 600;
      font-family: $primary-fontfamily;
      padding: 10px 24px;
      margin-right: 5px;

      @media (min-width: 768px) {
        color: #9F9F9F;
      }

      @media (min-width: 992px) {
        padding: 11px 49px;
        margin-right: 8px;
      }
    }

    .react-tabs__tab--selected {
      background: #240151;
      color: #fff;
      border: 1px solid transparent;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 600;
      font-family: $primary-fontfamily;
      padding: 10px 24px;

      @media (min-width: 992px) {
        padding: 11px 49px;
      }
    }
  }

  .provider-list-inner-contnt-wrap {
    display: flex;
    gap: 8px;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eaeaea;
    margin: 24px 20px 24px;
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    align-items: center;

    @media (min-width: 768px) {
      padding: 0px 0 16px;
      border-bottom: 2px dashed #eaeaea;
      margin: 0px 0 24px;
    }

    .provider-data-nickname {
      height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        background: #18D6DC;
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        // padding: 14px 12px;
        border-radius: 50%;
        height: 46px;
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .provider-list-data-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;

      .provider-list-data-name {
        font-size: 14px;
        font-weight: 500;
        color: #221f20;
        margin-bottom: 4px;

        @media (min-width: 768px) {
          font-size: 18px;
          font-weight: 600;
          padding-right: 8px;
          border-right: 1px solid #9F9F9F;
          margin-bottom: 0px;
        }
      }

      .provider-list-data-number {
        display: block;
        text-decoration: underline;
        color: #221f20;
        font-size: 12px;
        font-weight: 400;

        @media (min-width: 768px) {
          font-size: 18px;
          font-weight: 500;
        }
      }

      .provider-list-data-desg {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 8px;

        @media (min-width: 768px) {
          font-size: 18px;
          margin-bottom: 0px;
        }
      }

      .provider-list-data-address {
        font-size: 12px;
        font-weight: 400;
        color: #B3B3B3;

        @media (min-width: 768px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 18px;
        }
      }
    }

    .drop-arrow-right {
      background: url("../../assets/images/drop-arrow-right.svg") no-repeat center;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      right: 0;
      cursor: pointer;
      top: 0;
    }
  }

  .certify-tabbing-wrp {
    min-height: 56vh;
  }

  .provider-deatils-tabbing-wrap {
    margin: 0px 20px 24px;
    background: #fff;
    padding: 24px 16px;
    border-radius: 10px;

    @media (min-width: 768px) {
      padding: 0px;
    }

    .tabbing-wrap {
      padding: 0;
    }

    .overview-provider-details-data {
      padding: 8px 0 24px;

      @media (min-width: 768px) {
        padding: 0px;
      }

      .overview-basic-details-data {
        @media (min-width: 768px) {
          padding: 16px;
          border: 1px solid #eaeaea;
          border-radius: 10px;
          margin-bottom: 24px;
        }

        h2 {
          font-size: 16px;
          font-weight: 600;
          font-family: $primary-fontfamily;
          text-align: start;
          margin-bottom: 16px;

          @media (min-width: 768px) {
            font-size: 20px;
            margin-bottom: 24px;
          }
        }

        .ovev-details-data {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 8px;
          margin-bottom: 8px;

          span {
            display: block;
            font-size: 12px;
            font-weight: 600;
            font-family: $primary-fontfamily;

            @media (min-width: 768px) {
              font-size: 18px;
              font-weight: 400;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #EAEAEA;
          }

          &:last-child {
            margin-bottom: 16px;

            @media (min-width: 768px) {
              margin-bottom: 0px;
              padding-bottom: 0px;
            }
          }
        }

        .overview-expertise-details-data {
          display: flex;
          gap: 8px;
          align-items: center;
          flex-wrap: wrap;

          span {
            font-size: 12px;
            font-weight: 600;
            font-family: $primary-fontfamily;
            color: #fff;
            background: #18D6DC;
            padding: 3px;
            text-transform: uppercase;
            border-radius: 4px;

            @media (min-width: 768px) {
              padding: 5px;
            }
          }
        }
      }
    }

    .overview-provider-map-data {
      @media (min-width: 768px) {
        padding: 16px;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        margin-bottom: 24px;
      }

      .provider-detail-map-contnt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        @media (min-width: 768px) {
          margin-bottom: 24px;
        }

        h2 {
          font-size: 16px;
          font-weight: 600;
          font-family: $primary-fontfamily;
          text-align: start;

          @media (min-width: 768px) {
            font-size: 20px;
          }
        }

        .get-directions {
          font-size: 14px;
          font-weight: 500;
          font-family: $primary-fontfamily;
          display: block;
          color: #540BE0;
          text-decoration: none;

          @media (min-width: 768px) {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      .provider-detail-map-wrp {
        // width: 303px;
        // height: 209px;
        width: 100%;
        height: 100%;

        @media (min-width: 768px) {
          width: 100%;
          height: 100%;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .certifi-deatils-data {
      @media (min-width: 768px) {
        display: flex;
        gap: 24px;
        align-items: center;
      }

      .certifi-deatils-data-wrp {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 8px;
        padding-bottom: 8px;

        &:not(:last-child) {
          border-bottom: 1px solid #EAEAEA;
        }

        @media (min-width: 768px) {
          // padding: 24px;
          padding: 12px;
          border: 1px solid #ececec;
          // border-bottom: none;
          border-radius: 10px;
          box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
          align-items: center;
        }

        .certifi-deatils-data-img {
          width: 40px;
          height: 40px;
          flex: none;

          @media (min-width: 768px) {
            width: 64px;
            height: 64px;
          }

          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .certifi-deatils-data-cntnt {
          @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
          }

          .certifi-deatils-data-cntnt-heading {
            font-size: 14px;
            font-weight: 500;
            color: #221F20;
            // margin-bottom: 4px;

            @media (min-width: 768px) {
              font-size: 16px;
              font-weight: 600;
              line-height: 19.5px;
              // margin-bottom: 4px;
              // border-bottom: 1px solid #eaeaea;
              // padding-bottom: 4px;
            }
          }

          .certifi-deatils-data-cntnt-text {
            font-size: 12px;
            font-weight: 400;
            color: #B3B3B3;
            min-width: 135px;

            @media (min-width: 768px) {
              font-size: 14px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .provider-list-dsktp-wrpper {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 8px;
      align-items: center;
      margin-bottom: 0px;
      line-height: 1;
    }
  }

  .provider-detail-card {
    @media all and (min-width: $small-medium) {
      background-color: #fff;
      padding: 40px 40px 10px;
      border-radius: 30px 30px 0 0;
    }
  }
}

.provider-new-detail-wrapper {
  .medium-card-back {
    display: none;
    align-items: center;
    gap: 8px;
    @include fontsize(16px);
    font-weight: $fw-semibold;
    border: none;
    outline: none;
    background: none;

    .medium-card-icon {
      display: block;
      width: 17px;
      height: 14px;
      background: url("../../assets/images/back.svg") no-repeat;
      background-size: 17px 14px;
    }
    @media (min-width: $small-medium) {
      display: flex;
    }
  }
  @media (min-width: 768px) {

    .header-banner {
      background: transparent;
      min-height: auto;

      h1 {
        color: #240151;
      }
    }

    .provider-detail-wrp {
      margin-top: -8px;
    }
  }
}

.mbl-map-detail {
  display: block !important;

  @media (min-width: 768px) {
    display: none !important;
  }
}

.dkstp-map-detail {
  display: none !important;

  @media (min-width: 768px) {
    display: block !important;
  }
}

.lang-expertise-map-whole-wrap {
  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: end;
    gap: 24px;

    .lang-expertise-whole-wrap {
      width: calc(50% - 12px);
      padding: 16px 16px 24px;
      border: 1px solid #eaeaea;
      border-radius: 10px;

      .rmv-brdr-dsktp {
        padding: 0px !important;
        border: none !important;
        border-radius: 0px !important;
        margin-bottom: 40px !important;

        @media all and (max-width: 767.98px) {
          margin-bottom: 24px !important;
        }

        &:last-child {
          @media all and (max-width: 767.98px) {
            margin-bottom: 24px !important;
          }

          margin-bottom: 0px !important;
        }
      }
    }

    .overview-provider-map-data {
      width: calc(50% - 12px);
      margin-bottom: 0px !important;
      padding: 16px 16px 24px !important;
    }
  }

  .rmv-brdr-dsktp {
    @media all and (max-width: 767.98px) {
      margin-bottom: 24px !important;
    }

    &:last-child {
      @media all and (max-width: 767.98px) {
        margin-bottom: 24px !important;
      }
    }
  }

}
.wrp-new-fclty{
  width: 100%;
  gap: 24px;
  .ovev-detail-fclty-nm{
    width: calc(50% - 12px);
    text-align: left;
  }
  
  .ovev-detail-fclty-nm-info{
    width: calc(50% - 12px);
    text-align: right;
  }
}
.header-backbtn-wrrped{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-to-list{
    background: transparent;
    color: #240151;
    font-size: 16px;
    font-weight: 600;
  }
}
