@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.new-wrp-num-field {
  margin-bottom: 20px;

  @media all and (min-width: $small-medium) {
    margin-bottom: 40px;
  }

}

.form-number-field {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 20px;

  @media all and (min-width: $small-medium) {
    // margin-bottom: 40px;
  }

  // .input {
  //     width: calc(25% - 30px);
  //     padding: 5px;
  //     height: 58px;

  //     @media all and (min-width:$small-medium) {
  //         width: calc(25% - 22px);
  //         height: 75px;
  //     }

  //     @media all and (min-width:$desktop-xl) {
  //         width: calc(25% - 42px);
  //         height: 86px;
  //         height: 70px;
  //     }
  // }
  .code-input-wrp {
    width: 100% !important;

    >div {
      display: flex;
      justify-content: space-between;
    }

    input {
      width: 78px !important;
      padding: 5px;
      height: 64px !important;
      border: 0 !important;
      border-radius: 50px;
      background: #f2f2f2;
      color: $blue;
      caret-color: $blue;
      @include fontsize(32px);
      font-weight: $fw-semibold;
      letter-spacing: 0.25px;
      text-align: center;
      font-family: $primary-fontfamily;

      &:first-child{
        border-top-left-radius:50px;
        border-bottom-left-radius:50px;
      }
      &:last-child{
        border-top-right-radius:50px;
        border-bottom-right-radius:50px;
      }
      @media all and (min-width: $small-425) {
        width: 85px !important;
        height: 72px !important;
      }

      @media all and (min-width: $small-medium) {
        width: calc(23% - 0px) !important;
        height: 76px !important;
      }
      
      // @media all and (min-width:$desktop) {
      //     height: 75px !important;
      //     width: calc(25% - 26px) !important;
      // }

      @media all and (min-width: $desktop-xl) {
        width: calc(25% - 42px) !important;
        height: 86px !important;
      }

      &:focus {
        border: 1px solid #18D6DC !important;
        background: #C8F7F9;
        caret-color: #18D6DC;
      }
    }
  }
}

.verify-wrap {
  h1 {
    @include fontsize(32px);
    padding-right: 115px;
    line-height: 1.15;
    margin-bottom: 5px;

    @media all and (min-width: $small-medium) {
      @include fontsize(36px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(40px);
    }

    @media all and (min-width: $desktop-1440) {
      @include fontsize(44px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(54px);
    }
  }

  p {
    padding-right: 12px;

    @media all and (min-width: $small-medium) {
      font-family: $primary-fontfamily;
    }
  }

  .timer-wrp {
    @include fontsize(14px);
    letter-spacing: 0.25px;
    font-weight: $fw-semibold;

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }

    .timer {
      background: #d2f3ff;
      margin-left: 3px;
    }
  }

  .form-wrap {
    @media all and (min-width: $desktop) {
      padding-right: 115px;
    }

    @media all and (min-width: $desktop-xl) {
      padding-right: 132px;
    }
  }

  .bck-btn-wrap {
    @media all and (min-width: $desktop-xl) {
      top: 120px;
    }
  }
}

.verify-btn-wrp {
  .btn {
    &:disabled {
      cursor: default;
      background-color: #8080808c;
      border: 2px solid transparent;

      &:hover {
        color: $white-color;
      }

      .icon-arrow {
        display: none;
      }
    }
  }
}