@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";


.network-wrp {
    &:hover {
        @media all and (min-width:$small-medium) {
            // border: 1px solid #EAEAEA;
            // border-radius: 8px;
        }

        &.out-network {
            .health-plan-head {
                // border-bottom: 1px solid transparent;
            }
        }
    }

    &.out-network {
        @media all and (min-width:$small-medium) {
            margin-bottom: 0;
        }

        .health-plan-head {
            padding: 20px 15px;

            @media all and (min-width:$small-medium) {
                padding: 20px 18px;
            }

            @media all and (min-width:$desktop) {
                padding: 13px 15px;
            }

            @media all and (min-width:$desktop-1600) {
                padding: 15px;
            }

            // border: 1px solid transparent;
            @media all and (max-width:767px) {
                display: none;
            }
        }

        .icon-network {
            background: url(../../../assets/images/out-network.svg) no-repeat;
            width: 25px;
            height: 22px;
            background-size: 25px auto;
            margin-right: 14px;

            @media all and (min-width:$desktop) {
                width: 39px;
                height: 35px;
                background-size: 39px auto;
                margin-right: 20px;
            }

            @media all and (min-width:$desktop-xl) {
                width: 40px;
                height: 35px;
                background-size: 40px auto;
                margin-right: 20px;
            }
        }

        .health-range {
            // border: 1px solid transparent;
        }
    }

    // .health-range {
    //     border-radius: 0 0 8px 8px;
    //     border: 1px solid #281651;
    //     border-top: 0;
    //     background: #FFF;
    //     box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
    //     padding: 25px;

    //     @media all and (min-width:$desktop) {
    //         display: flex;

    //     }


    //     .individual-icon {
    //         background: url(../../../assets/images/individual-icon.svg) no-repeat;
    //         height: 16px;
    //         width: 13px;
    //         background-size: 13px auto;
    //         margin-right: 6px;
    //         display: block;
    //     }

    //     .family-icon {
    //         background: url(../../../assets/images/family-icon.svg) no-repeat;
    //         height: 16px;
    //         width: 16px;
    //         background-size: 16px auto;
    //         margin-right: 6px;
    //         display: block;
    //     }

    //     .user-type {
    //         color: $white-color;
    //         font-family: $primary-fontfamily;
    //         @include fontsize(14px);
    //         border-radius: 2px;
    //         background: #00D0BE;
    //         padding: 10px;
    //         width: 141px;
    //         display: flex;
    //         align-items: center;
    //         margin-bottom: 20px;

    //         @media all and (min-width:$desktop) {
    //             width: 165px;
    //             @include fontsize(16px);
    //         }
    //     }
    // }

    // .health-plan-head {
    //     border-radius: 8px 8px 0px 0px;
    //     border: 1px solid #281651;
    //     background: #E9E8EE;
    //     padding: 15px;
    //     display: flex;
    //     align-items: center;





    //     h3 {
    //         color: #281651;
    //         font-family: $primary-fontfamily;
    //         text-align: left;
    //         @include fontsize(16px);
    //         font-weight: $fw-bold;
    //         @include fontsize(14px);
    //         letter-spacing: 0.15px;
    //         margin-bottom: 0;

    //         @media all and (min-width:$desktop) {
    //             @include fontsize(18px);
    //         }

    //         @media all and (min-width:$desktop-xl) {
    //             @include fontsize(26px);
    //         }
    //     }
    // }

    // .type-plans {
    //     display: flex;
    //     justify-content: space-between;

    //     @media all and (min-width:$desktop) {
    //         flex-direction: column;
    //         padding-right: 31px;
    //     }

    //     .plan-wrp {
    //         width: 50%;

    //         @media all and (min-width:$desktop) {
    //             width: 100%;

    //         }

    //         &.plan-one {
    //             padding-right: 20px;

    //             @media all and (min-width:$desktop) {
    //                 padding-right: 0;

    //             }
    //         }

    //         .progress-main {
    //             width: 100%;

    //             .progress-bar {
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }

    // .individual-health-range {
    //     margin-bottom: 20px;

    //     @media all and (min-width:$desktop) {
    //         width: 28%;
    //     }

    //     @media all and (min-width:$desktop-xl) {
    //         width: 32%;
    //     }
    // }

    // .family-health-range {
    //     @media all and (min-width:$desktop) {
    //         width: 28%;
    //     }

    //     @media all and (min-width:$desktop-xl) {
    //         width: 32%;
    //     }
    // }

    // .cover-table {
    //     @media all and (min-width:$desktop) {
    //         width: 44%;
    //     }
    // }
}