@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.83);

  @media all and (min-width: $small-medium) {
    background: rgba(0, 0, 0, 0.4);
  }

  i {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    svg {
      width: 44px;
      height: 44px;

      @media all and (min-width: $small-medium) {
        width: 40px;
        height: 40px;
      }

      @media all and (min-width: $desktop) {
        width: 56px;
        height: 56px;
      }
    }
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(236, 236, 236, 0.54);
    z-index: -1;

    @media all and (min-width: $small-medium) {
      display: none;
    }
  }

  .popup-wrap {
    width: 100%;
  }

  .poup-content {
    border-radius: 8px;
    border: 1px solid #ececec;
    background: #fff;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    max-width: 325px;
    width: calc(100% - 50px);
    margin: 0 auto;
    min-height: 146px;
    padding: 44px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media all and (min-width: $small-medium) {
      max-width: 608px;
      min-height: 306px;
    }

    @media all and (min-width: $desktop) {
      max-width: 780px;
      min-height: 376px;
    }
    .auth {
      h2 {
        @include fontsize(20px);
        font-weight: $fw-semibold;
        color: #540be0;
      }
    }
    .auth-fail {
      padding: 0 15px;

      p {
        @media all and (min-width: $small-medium) {
          max-width: 380px;
        }

        @media all and (min-width: $small-medium) {
          max-width: 500px;
        }

        @media all and (min-width: $small-medium) {
          max-width: 400px;
        }
      }
    }

    p {
      text-align: center;
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: $fw-regular;
      letter-spacing: 0.25px;
      line-height: 1.45;

      @media all and (min-width: $small-medium) {
        @include fontsize(12px);
        max-width: 331px;
        font-weight: $fw-medium;
        letter-spacing: normal;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
        max-width: 445px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
        max-width: 490px;
      }
    }
  }
}

.delete-popup {
  .poup-content {
    position: relative;

    .close-popup {
      display: none;

      background: url(../../../assets/images/new-cancel.svg) no-repeat;
      width: 20px;
      height: 20px;
      background-size: 20px auto;
      padding: 0;
      position: absolute;
      top: 23px;
      right: 38px;
    }

    .auth-fail {
      p {
        max-width: 562px;
        color: $blue;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
          margin-bottom: 60px;
        }
        @media all and (min-width: $desktop) {
          @include fontsize(18px);
          font-weight: 500;
          max-width: 646px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }

      h3 {
        font-weight: $fw-bold;
        @include fontsize(20px);
        color: $blue;
        @media all and (min-width: $small-medium) {
          @include fontsize(24px);
        }
        @media all and (min-width: $desktop) {
          @include fontsize(37px);
          font-weight: 700;
        }
      }
    }
  }

  .btn-wrap {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: center;
    }

    .btn {
      padding: 5px 10px;
      height: 45px;
      width: 100%;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
        width: 200px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
        height: 50px;
      }
    }

    .yes-btn {
      margin-right: 16px;
      background: transparent;
      border: 1px solid #240151;
      color: #240151;
      margin-bottom: 20px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0;
      }

      &:hover {
        border: 1px solid #240151;
        background: #240151;
        color: #fff;
      }
    }

    .no-btn {
      background: #240151;
      border: 1px solid #240151;

      &:hover {
        color: #240151;
        background: transparent;
      }
    }
  }
}

.confirm-delete {
  .pass-wrp {
    .input-block {
      position: relative;

      .icon-eye {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        background: url(../../../assets/images/forget-pass-eye.svg) no-repeat;
        width: 18px;
        height: 14px;
        background-size: 18px auto;
        cursor: pointer;
        margin-bottom: 0;

        @media all and (min-width: $small-medium) {
          width: 20px;
          height: 13px;
          background-size: 20px auto;
          right: 20px;
        }

        // @media all and (min-width: $desktop) {
        //   width: 28px;
        //   height: 18px;
        //   background-size: 28px auto;
        //   right: 20px;
        // }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin: 0 auto;
          height: 20px;
          width: 1.3px;
          background-color: #989898;
          transform: translateY(-50%) rotate(45deg);

          // @media all and (min-width: $desktop) {
          //   height: 30px;
          // }
        }

        &.active {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .poup-content {
    position: relative;

    .close-popup {
      display: none;
      background: url(../../../assets/images/new-cancel.svg) no-repeat;
      width: 16px;
      height: 16px;
      background-size: 16px auto;
      padding: 0;
      position: absolute;
      top: 18px;
      right: 18px;
      @media all and (min-width: $desktop) {
        width: 18px;
        height: 18px;
        background-size: 18px auto;
        top: 23px;
        right: 38px;
      }
    }

    @media all and (min-width: $small-medium) {
      max-width: 600px;
    }

    @media all and (min-width: $desktop) {
      padding: 60px 20px;
      max-width: 780px;
    }

    .auth-fail {
      width: 100%;
      padding: 0;

      @media all and (min-width: $small-medium) {
        max-width: 450px;
      }

      @media all and (min-width: $desktop) {
        max-width: 480px;
      }

      .frm-cntrl-nw {
        .form-block {
          width: 100%;
        }
        .yes-btn {
          margin-right: 16px !important;
          background: transparent;
          border: 1px solid #240151;
          color: #240151;
          margin-bottom: 20px !important;

          @media all and (min-width: $small-medium) {
            margin-bottom: 0;
          }

          &:hover {
            border: 1px solid #240151;
            background: #240151;
            color: #fff;
          }
        }
      }

      h3 {
        font-weight: $fw-bold;
        text-align: left;
        color: $blue;
        @include fontsize(20px);
        @media all and (min-width: $small-medium) {
          @include fontsize(24px);
        }
        @media all and (min-width: $desktop) {
          @include fontsize(36px);
        }
      }

      p {
        max-width: none;
        width: 100%;
        text-align: left;
        font-weight: $fw-medium;
        color: $blue;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop) {
          margin-bottom: 40px;
          @include fontsize(18px);
        }
      }

      .btn-wrap {
        margin-top: 25px;

        .btn {
          height: 45px;
          padding: 5px 10px;

          @media all and (min-width: $small-medium) {
            @include fontsize(14px);
          }

          @media all and (min-width: $desktop) {
            @include fontsize(16px);
          }

          @media all and (min-width: $desktop) {
            @include fontsize(18px);
            height: 50px;
          }
        }

        @media all and (min-width: $desktop) {
          margin-top: 40px;
        }
      }
    }
  }
}
