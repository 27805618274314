@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

// .msz-scrol-wrp {
//     >div {
//         overflow: visible !important;

//         @media all and (max-width:767px) {
//             height: calc(100vh - 445px) !important;
//         }

//         @media all and (min-width:$small-medium) {
//             height: calc(100vh - 445px) !important;
//         }

//         &::-webkit-scrollbar {
//             display: none;
//         }

//         >div {
//             max-height: 100% !important;

//             &::-webkit-scrollbar {
//                 display: none;
//             }
//         }
//     }

//     .thumb-vertical {
//         position: relative;
//         display: block;
//         height: 200px !important;
//         cursor: pointer;
//         border-radius: inherit;
//         background-color: $blue;
//         width: 4px;
//         left: -1px;

//     }

//     .track-vertical {
//         background-color: #C6C3CA;
//         position: absolute;
//         width: 1px;
//         display: block !important;
//         right: -20px;
//         bottom: 2px;
//         top: 2px;
//         border-radius: 3px;

//         @media all and (max-width:767px) {
//             background-color: transparent;
//         }

//         @media all and (min-width:$desktop) {
//             right: -10px;
//         }

//         @media all and (min-width:$desktop-1440) {
//             right: -5px;
//         }
//     }
// }

.archive-wrpper {
  .inbox-card {
    background: transparent !important;
  }
  .bottom-new-msz {
    @media all and (min-width:768px) {
            width: 65%;
       }
      }
}
.top-new-msz {
  flex-direction: column-reverse;

  &.archive-time-wrap {
    align-items: flex-end;
    @media all and (max-width:767px) {
                  flex-direction: row-reverse;
                  align-items: center;
              }
  }
}

.bottom-new-msz {
  flex-direction: column;
  h3 {
    margin-bottom: 12px !important;
  }
}
