@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.apex-chart-wrp {
  // background-color: red;
  // height: 180px;

  .apexcharts-inner {
    transform: translate(18px, 30px);
  }

  .apexcharts-xaxis-tick {
    display: none;
  }

  .apexcharts-toolbar {
    display: none !important;
  }

  .apexcharts-tooltip {
    border-radius: 8px;
    border: 1px solid #eaeaea !important;
    background: #fff;
    box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
    padding: 15px 25px 15px 13px;

    .apexcharts-tooltip-title {
      background: transparent !important;
      border-bottom: 0 !important;
      padding: 0 !important;
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      margin-bottom: 0;
      display: none;
    }

    .apexcharts-tooltip-marker {
      display: none;
    }

    .apexcharts-tooltip-text {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(10px);
      font-weight: 400;
    }

    .apexcharts-tooltip-text-y-value {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: 700;
      margin-left: 0;
    }
    
    .apexcharts-tooltip-text-y-label{
      @include fontsize(14px);
    }

    .apexcharts-tooltip-series-group {
      padding: 0 !important;
    }
  }

  text {
    fill: #9e9aa6 !important;
    font-family: $primary-fontfamily !important;
    @include fontsize(10px);
    font-weight: $fw-semibold;

    @media all and (min-width: $desktop) {
      @include fontsize(14px);
    }
  }

  .apexcharts-tooltip-y-group {
    padding: 3px 0px 3px 8px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    font-family: $primary-fontfamily;
    position: relative;
    margin-bottom: 3px;

    span {
      font-weight: 700;
      @include fontsize(10px);
    }
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      bottom: 2px;
      margin: auto 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      left: 0;
      background-color: #18d6dc;
    }
  }
  .apexcharts-tooltip-goals-group {
    padding-left: 20px;
  }
  .apexcharts-tooltip-text-goals-label {
    @include fontsize(13px);
    font-weight: 400;
    color: $blue;

    div {
      div {
        margin-bottom: 2px;
      }
    }
  }
  .apexcharts-tooltip-text-goals-value {
    @include fontsize(13px);
    font-weight: 400;
    color: $blue;
    div {
      div {
        margin-bottom: 2px;
      }
    }
  }
  .apexcharts-tooltip-z-group {
    display: none;
  }
}
