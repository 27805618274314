@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.forget-password-form {

    .right-signin-wrp {
        position: relative;

        @media all and (min-width:$small-medium) {
            width: 80.55%;
        }

        @media all and (min-width:$desktop) {
            width: 76.55%;
        }

        @media all and (min-width:$desktop-1440) {
            width: 71.5%;

        }

        @media all and (min-width:$desktop-xl) {
            width: 76.55%;
        }
    }

    .bck-btn-wrap {
        position: absolute;
        top: 25px;
        right: 21px;
        display: flex;
        align-items: center;
        @include fontsize(14px);
        font-family: $primary-fontfamily;
        font-weight: $fw-semibold;
        color: $blue;

        @media all and (min-width:$small-medium) {
            @include fontsize(14px);
            top: 60px;
            right: -30px;
        }

        @media all and (min-width:$desktop) {
            @include fontsize(16px);
            right: -50px;
            top: 55px;
        }

        @media all and (min-width:$desktop-1440) {
            right: -57px;
        }

        @media all and (min-width: 1700px) {
            top: 80px;
        }

        @media all and (min-width:$desktop-xl) {
            @include fontsize(20px);
            top: 109px;
            // right: -188px;
        }

        .icon-back {
            background: url(../../../assets/images/back.svg) no-repeat;
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            display: block;
            margin-right: 5px;
            margin-top: 0px;

            @media all and (min-width:$small-medium) {
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
                margin-top: 0px;
            }

            @media all and (min-width:$desktop) {
                width: 24px;
                height: 24px;
                background-size: 24px 24px;
            }

        }
    }

    h1 {
        margin-bottom: 13px;
        letter-spacing: normal;
        line-height: 1.05;

        @media all and (min-width:$small-medium) {
            margin-bottom: 5px;
        }

        @media all and (min-width:$desktop) {
            margin-bottom: 18px;
        }

        @media all and (min-width:$desktop-xl) {
            margin-bottom: 10px;
        }

    }

    p {
        @include fontsize(16px);
        font-family: $primary-fontfamily;
        letter-spacing: 0.25px;
        line-height: 1.4;
        font-weight: $fw-medium;

        @media all and (min-width:$small-medium) {
            @include fontsize(16px);
            font-family: $primary-fontfamily;
            line-height: 1.5;
            padding-right: 100px;
        }

        @media all and (min-width:$desktop) {
            letter-spacing: normal;
            @include fontsize(18px);

        }

        @media all and (min-width:$desktop-xl) {
            @include fontsize(20px);
            padding-right: 120px;
            line-height: normal;
        }
    }

    // .signin-wrp {
    //     padding-bottom: 22px;
    //     margin-top: 0;

    //     @media all and (min-width:$small-medium) {
    //         padding-bottom: 106px;
    //         padding-top: 0;
    //         margin-top: 99px;
    //     }

    //     @media all and (min-width:$desktop) {
    //         padding: 0px 0 77px 0;
    //         margin-top: 96px;
    //     }


    // }

}