@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.account-setting {
  .form-block {
    @media all and (min-width: $desktop) {
      width: 49%;
    }
  }

  .form-main-wrppr {
    .form-check-wrp {
      .form-block {
        &:last-child {
          @media all and (min-width: $desktop) {
            margin-right: 0;
          }
        }

        @media all and (min-width: $desktop) {
          margin-right: 20px;
        }
      }
    }

    @media all and (min-width: $small-medium) {
      flex-direction: column;
    }
    @media all and (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
    }

    .form-block-display {
      @media all and (min-width: $desktop) {
        width: 100%;
      }
    }

    .form-display-wrap {
      @media all and (min-width: $desktop) {
        width: 100%;
      }
    }
  }

  .form-block-display {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: space-between;
    }

    .form-block {
      @media all and (min-width: $small-medium) {
        width: 49%;
      }
    }
  }

  .select-wrp {
    .select-option {
      border-radius: 25px;
      border: 1px solid #dedede;
      padding: 4px 10px;
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      width: 62px;
    }
  }

  .form-display-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media all and (min-width: $small-medium) {
      flex-direction: row;
    }

    .form-block {
      width: 100%;
      @media all and (min-width: $small-medium) {
        width: 49%;
      }
    }
  }
  .account-wrap {
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media all and (min-width: $small-medium) {
      padding-bottom: 2px;
      margin-bottom: 24px;
    }
  }
  .account-bottom-line {
    border-bottom: 1px solid #eaeaea;
  }
}
