@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.coverage-table-wrp {
  margin-top: 25px;
  border-radius: 25px 25px 25px 25px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);

  @media all and (min-width: $small-medium) {
    max-width: 448px;
    margin: 0 auto;
    border: 0;
    box-shadow: none;
    border-radius: 0;
  }

  @media all and (min-width: $desktop-1600) {
    margin: 0;
    max-width: 495px;
  }

  @media all and (min-width: $desktop) {
    margin-top: 0;
  }

  .cover-head {
    padding: 12px 11px;
    background-color: $light-green;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (min-width: $small-medium) {
      border: 1px solid #cfccd3;
      background-color: transparent;
      border-radius: 6px 6px 0 0;
      padding: 18px;

      h3 {
        display: block !important;
      }
    }

    @media all and (min-width: $desktop) {
      padding: 15px 18px;
      border-radius: 10px 10px 0 0;
    }

    @media all and (min-width: $desktop-1440) {
      padding: 15px 22px;
    }

    @media all and (min-width: $desktop-1600) {
      padding: 13px 18px;
    }

    .accordion-arrow {
      background: url(../../../assets/images/accor-arrow.svg) no-repeat;
      width: 15px;
      height: 12px;
      background-size: 15px auto;
      display: block;
      filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(796%)
        hue-rotate(189deg) brightness(117%) contrast(100%);
      margin-right: 12px;

      @media all and (max-width: 767px) {
        transform: rotate(180deg);
      }

      @media all and (min-width: $small-medium) {
        display: none;
      }

      &.active {
        transform: rotate(0);
      }
    }
  }

  .table {
    padding: 15px 3px 20px;

    @media all and (max-width: 767px) {
      display: none;
    }

    @media all and (min-width: $small-medium) {
      border: 1px solid #cfccd3;
      border-top: 0;
      padding: 20px 18px 10px;
      border-radius: 0 0 8px 8px;
    }

    @media all and (min-width: $desktop) {
      // padding: 20px 7px 10px 19px;
      padding: 10px 7px 18px 25px;
    }

    @media all and (min-width: $desktop-1600) {
      padding: 19px 0px 18px 17px;
    }

    &.active-accordion {
      @media all and (max-width: 767px) {
        display: block;
      }
    }

    .head {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-style: normal;
      font-weight: $fw-bold;

      @media all and (min-width: $desktop) {
        padding: 10px;
      }
    }

    .value {
      width: 42%;
      text-align: center;
      color: $blue;
      font-family: $primary-fontfamily;
      padding: 7px 4px;
      @include fontsize(12px);
      border-right: 1px solid rgba(158, 154, 166, 0.5);

      @media all and (min-width: $small-medium) {
        width: 36%;
        @include fontsize(14px);
        padding: 7px 8px 0 !important;
      }

      @media all and (min-width: $desktop) {
        width: 38%;
        padding: 9.5px 8px 0 !important;
      }

      @media all and (min-width: $desktop-1600) {
        padding: 9px 10px 11px !important;
      }

      @media all and (min-width: $desktop-1600) {
        width: 41%;
      }

      &:nth-child(2n) {
        width: 26%;

        @media all and (min-width: $small-medium) {
          width: 33%;
        }

        @media all and (min-width: $desktop) {
          width: 28%;
        }

        @media all and (min-width: $desktop-1600) {
          width: 31%;
        }
      }

      &:nth-child(3n) {
        width: 34%;

        @media all and (min-width: $small-medium) {
          width: 31%;
        }

        @media all and (min-width: $desktop) {
          width: 39%;
        }
      }

      @media all and (min-width: $desktop) {
        padding: 4px 15px;
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
        padding: 10px 20px;
      }

      &:last-child {
        border-right: 0;
      }

      &.primary {
        text-align: left;
        padding-left: 14px;

        @media all and (min-width: $small-medium) {
          padding: 8px 0;
        }

        @media all and (min-width: $desktop) {
          padding: 1.5px 0px;
        }
      }
    }

    .data {
      display: flex;
    }

    .main-head {
      .value {
        padding-bottom: 0;
        padding-top: 0;
        color: #540be0;

        @media all and (min-width: $small-medium) {
          // padding: 0 0 8px 0;
          // margin-bottom: 5px;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(18px);
          padding: 0 !important;
        }
      }
    }
  }
}

.cover-table {
  .coverage-table-wrp {
    .cover-head {
      h3 {
        color: $white-color;
        font-family: $primary-fontfamily;
        @include fontsize(16px);
        margin-bottom: 0;
        text-align: left;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
          font-weight: $fw-semibold;
          color: $blue;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
        }
      }
    }
  }
}
