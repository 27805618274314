@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";


.staff-table {
    .table {
        .data {
            display: flex;

            &.main-head {
                .serial-no {
                    border-radius: 10px 0 0 0;
                    @media all and (min-width: $small-medium) {
                        border-radius: 10px 0 0 10px;
                    }

                    .sub-text {
                        @media all and (max-width:767px) {
                            display: block;
                        }
                    }

                }

                .action {
                    border-radius: 0 10px 0 0;
                    @media all and (min-width: $small-medium) {
                        border-radius: 0 10px 10px 0;
                    }
                }
            }

        }

        .main-head {
            // background: $blue;
            // border-radius: 10px 10px 0 0;

            .head {
                padding: 12px;
                color: #FFF;
                font-family: $primary-fontfamily;
                @include fontsize(9px);
                font-style: normal;
                font-weight: $fw-bold;
                border-right: 0;
                flex-grow: 1;
                // text-transform: uppercase;
                background: $light-green;
                text-align: left;
                


                @media all and (min-width:$small-medium) {
                    padding: 20px 15px;
                    text-transform: capitalize;
                    @include fontsize(14px);
                    font-weight: $fw-semibold;
                    word-wrap: break-word;
                }



                @media all and (min-width:$desktop) {
                    @include fontsize(14px);
                    padding: 20px 12px;
                    text-align: center;

                }

                @media all and (min-width:$desktop-1440) {
                    padding: 20px;

                }


                @media all and (min-width:$desktop-xl) {
                    @include fontsize(20px);

                }

            }
        }

        .value-wrp {
            border-radius: 0 0 10px 10px;
            // background: #CEEBEB;
            margin-bottom: 20px;
            h2{
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                min-height: 80vh;
            }
            @media all and (min-width:$desktop-xl) {
                border-radius: 0;

            }

            .serial-no {
                text-align: left;
            }

            .edit {
                color: #281651;
                font-family: $primary-fontfamily;
                @include fontsize(12px);
                font-style: normal;
                font-weight: $fw-semibold;
                text-decoration-line: underline;

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }
            }

            .value {
                &:last-child {
                    border-right: 0;
                }

                &.serial-no {
                    border-right: 1px solid #fff;
                    background: #F9F7FE;
                    border-bottom: 1px solid transparent;
                    text-transform: uppercase;

                }

                &.action {
                    background: #E6EAED;
                    @media all and (min-width:$small-medium) {
                        background: #FFF;
                    }
                }
            }

            .data {
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: #fff;
                    content: "";

                    @media all and (min-width:$small-medium) {
                        background: #EAEAEA;
                        height: 2px;
                    }
                }

                &:last-child {
                    &::after {
                        // display: none;
                    }

                    .value {
                        &:first-child {
                            border-radius: 0 0 0 0px;
                        }

                        &:last-child {
                            border-radius: 0 0 0px 0;
                        }
                    }
                }
            }
        }

        .value {
            text-align: center;
            color: #737373;
            font-family: $primary-fontfamily;
            padding: 18px 10px;
            @include fontsize(12px);
            border-right: 1px solid #fff;
            background: #F2F2F2;
            font-weight: $fw-semibold;
            text-transform: capitalize;
            flex-shrink: 0;
            word-break: break-word;
            @media all and (min-width:$small-medium) {
                padding: 10px 20px;
                @include fontsize(14px);
                font-weight: $fw-regular;
                background: #FFF;
            }

            @media all and (min-width:$desktop) {
                padding: 16px;
                @include fontsize(16px);
            }

            @media all and (min-width:$desktop-xl) {
                @include fontsize(18px);
                padding: 20px;
            }
        }

        .action {
            width: 30%;
        }

        .name {
            width: 30%
        }

        .email {
            width: 22%
        }

        .serial-no {
            width: 40%;
            text-align: left !important;
            font-weight: $fw-bold;
            padding: 11px 10px 11px 18px;

            @media all and (min-width:$small-medium) {
                width: 40%;
                padding: 10px 20px;
                font-weight: $fw-regular;
            }

            @media all and (min-width:$desktop) {
                width: 40%;
                padding: 16px;
            }

            @media all and (min-width:$desktop-xl) {
                padding: 20px 10px;
            }
        }
    }


}