@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.secondary-header {
    background: #053149;
    padding: 18px 45px;
    display: flex;
    justify-content: center;
    position: relative;

    .back-btn {
        display: block;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
    }

    .back-icon {
        background: url(../../assets/images/back-arrow.svg);
        height: 25px;
        width: 25px;
        background-size: 25px auto;
        display: block;
    }

    h3 {
        color: #240151;
        font-family: $primary-fontfamily;
        @include fontsize(17px);
        font-weight: 400;
        margin-bottom: 0;

    }
}