@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";
// .header{
//     @media all and (max-width:767px){
//         display: none;
//     }

// }
.network-summary {
  @media all and (min-width: $small-medium) {
    background: #e9e8ee;
  }

  .header-banner {
    min-height: 250px;
    background: url("../../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }
    @media all and (min-width: $desktop) {
      padding: 44px 84px;
      .container {
        padding: 20px 0px;
      }
    }
    .container{
      @media all and (min-width: $small-medium) {
        padding: 0px 10px;
      }
      @media all and (min-width: 853px) {
        padding: 0px 30px;
      }
      @media all and (min-width: 1170px) {
        padding: 0px 38px;
      }
      @media all and (min-width: 1280px) {
        padding: 0px;
      }
    }
    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    margin-bottom: 15px;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  h3 {
    text-align: left;
    @include fontsize(18px);
    font-weight: $fw-semibold;
    font-family: $primary-fontfamily;
    margin-bottom: 18px;
  }
  .network-content {
    padding: 8px 0 0;

    @media all and (min-width: $small-medium) {
      margin-top: -194px;
    }
    .container {
      @media all and (min-width: 853px) {
        padding: 0 42px;
       }
      @media (min-width: $desktop) and (max-width: 1299px) {
       padding: 0 25px;
      }
      @media all and (min-width: 1300px) {
        padding: 0 10px;
       }
    }
  }
  .network-card {
    @media all and (min-width: $small-medium) {
      background-color: #fff;
      padding: 40px 40px 10px;
      border-radius: 30px 30px 0 0;
    }
  }
  .network-content-wrp {
    @media all and (min-width: $small-medium) {
      display: flex;
      flex-wrap: wrap;
    }
    
  }
  .benifits-wrap {
    border: 1px solid #eaeaea;
    padding: 19px 15px 16px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;

    @media all and (min-width: $small-medium) {
      border: 0;
      border-bottom: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea;
      border-radius: 0;
      box-shadow: none;
      order: 1;
      width: 31.8%;
      padding: 0 0px 15px 0px;
    }
    &.health-benefits-wrapper{
      padding-left: 20px;
      .head-wrp{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        overflow-y: auto;
        max-height: 110px;
        .benefit-text{
          text-decoration: underline;
          display: block;
        }
        .download-wrap{
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .download-wrap{
        display: flex;
        align-items: center;
      }
      .pdf-icon {
        background: url(../../../assets/images/adobe.svg) no-repeat;
        width: 20px;
        height: 21px;
        background-size: 20px auto;
        display: block;
        margin-right: 15px;

        @media all and (min-width: $desktop) {
          margin-right: 10px;
        }

        @media all and (min-width: $desktop-1600) {
          width: 18px;
          height: 18px;
          background-size: 18px auto;
        }

        @media all and (min-width: $desktop-xl) {
          width: 25px;
          height: 23px;
          background-size: 25px auto;
        }
      }
    }
  }
  .summary-wrap {
    padding: 12px 0;
    h3 {
      margin-bottom: 10px;
    }

    @media all and (min-width: $small-medium) {
      width: 100%;
      order: 3;
    }
  }
  .member-content {
    display: flex;

    h3 {
      @include fontsize(12px);
      font-weight: $fw-bold;
      display: flex;
      align-items: center;
    }
    .icon-sign {
      height: 10px;
      width: 8px;
      display: block;
      margin-right: 3px;
    }
    .head-wrp {
      width: 33.33%;
      position: relative;

      &:not(:last-child)::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        background: #ededed;
        right: 0;
      }

      &:not(:first-child) {
        text-align: center;
        padding: 0 10px;
        h3 {
          justify-content: center;
        }
      }
      h3 {
        margin-bottom: 5px;
      }
    }
  }
  .tab-wrp {
    .tab-btn-wrap {
      display: flex;
      gap: 6px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .btn {
      background-color: #f2f2f2;
      color: #9F9F9F;

      &.active {
        background-color: $light-green;
        color: #fff;
      }
    }
    .in-network-btn {
      width: 150px;
    }
    .out-network-btn {
      width: 175px;
    }
  }
  .data-network {
    h3 {
      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
  }
  .health-plan-head {
    h3 {
      display: block;
    }
  }
  .network-providers {
    padding: 20px 10px;

    @media all and (min-width: $small-medium) {
      order: 2;
      width: 30%;
      margin-bottom: 15px;
      border-bottom: 1px solid #eaeaea;
      padding: 0 20px 15px;
    }
    @media all and (min-width: $desktop) {
      padding: 0 40px 15px;
    }
  }
  .provider-block {
    display: flex;
  }
  .logo-wrp {
    border-radius: 20px;
    border: 1px solid #18d6dc;
    background-color: #c8f7f9;
    width: 140px;
    margin-right: 10px;

    @media all and (min-width: $small-medium) {
      background: none;
      border-radius: 10px 0 0 10px;
      margin-right: 0;

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: none;
      }
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left: none;
        border-left: 1px solid #18d6dc;
      }
    }
  }
  .logo-body {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    background-color: #fff;
    border-radius: 20px 20px 0 0;

    @media all and (min-width: $small-medium) {
      border-radius: 10px;
      height: 50px;
    }
    img{
      height: 100%;
      display: block;
      max-width: 100%;
    }
  }
  .logo-text {
    text-align: center;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #240151;
    font-family: "Montserrat";
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 0 0 20px 20px;

    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  .cigna-icon {
    background: url("../../../assets/images/cigna-logo.png") no-repeat;
    height: 38px;
    width: 100px;
    background-size: 100px auto;

    @media all and (min-width: $small-medium) {
      width: 85px;
      height: 32px;
      background-size: 85px auto;
    }
  }
  .phcs-icon {
    background: url("../../../assets/images/phcs-logo.png") no-repeat;
    height: 38px;
    width: 87px;
    background-size: 87px auto;

    @media all and (min-width: $small-medium) {
      width: 75px;
      height: 32px;
      background-size: 75px auto;
    }
  }
}
