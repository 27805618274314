@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.signin-wrp {
  padding: 25px 20px 25px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  position: relative;
  

  @media all and (min-width: $small-medium) {
    overflow: initial;
    height: auto;
    height: 100%;
    max-width: 410px;
    padding: 40px 0 20px;
    margin: 0 auto;

    justify-content: center;
  }

  @media all and (min-width: $desktop) {
    max-width: 500px;
    padding-bottom: 40px;
    padding-top: 40px;
    // margin-top: 95px;
  }

  @media all and (min-width: $desktop-1440) {
    max-width: 560px;
    // margin-top: 96px;
  }

  @media all and (min-width: $desktop-xl) {
    max-width: 700px;
    padding-top: 60px;
  }

  @media all and (min-width: 768px) and (max-height: 770px) {
    // padding-top: 50px;
    // margin-top: 50px;
  }

  .pass-wrp {
    .input-block {
      position: relative;

      .icon-eye {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        background: url(../../../assets/images/forget-pass-eye.svg) no-repeat;
        width: 19px;
        height: 19px;
        background-size: 19px 19px;
        cursor: pointer;

        @media all and (min-width: $small-medium) {
          right: 20px;
        }

        @media all and (min-width: $desktop) {
          right: 20px;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin: 0 auto;
          height: 19px;
          width: 1.3px;
          background-color: #989898;
          transform: translateY(-50%) rotate(45deg);
        }

        &.active {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .logo {
    margin-bottom: 30px;
    width: 90px;

    img {
      width: 100%;
      display: block;
    }

    @media all and (min-width: $small-medium) {
      display: none;
    }
  }

  h1 {
    margin-bottom: 10px;
    @media all and (min-width: $small-medium) {
      margin-bottom: 6px;
    }

    @media all and (min-width: $desktop) {
      letter-spacing: normal;
      margin-bottom: 12px;
    }

    @media all and (min-width: $desktop-xl) {
      margin-bottom: 5px;
    }
  }

  p {
    margin-bottom: 40px;
    @include fontsize(18px);
    font-family: $primary-fontfamily;
    font-weight: $fw-medium;

    @media all and (min-width: $small-medium) {
      @include fontsize(12px);
      letter-spacing: 0.25px;
      margin-bottom: 30px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(18px);
      letter-spacing: normal;
    }

    @media all and (min-width: $desktop-1440) {
      letter-spacing: normal;
      // margin-bottom: 102px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
      // margin-bottom: 92px;
    }

    // @media all and (min-width:768px) and (max-height:770px) {
    //     margin-bottom: 55px;
    // }
  }

  .forget-pass-block {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3px;

    .form-label {
      margin-bottom: 0;
    }

    @media all and (min-width: $small-medium) {
      margin-bottom: 10px;
    }
  }

  .forget-pass {
    color: $voilet-blue;
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    @include fontsize(14px);
    transition: all 0.5s;

    &:hover {
      opacity: 0.8;
    }

    @media all and (min-width: $small-medium) {
      letter-spacing: 0.25px;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
      // letter-spacing: 0.6px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }

  .btn {
    margin-bottom: 12px;
    position: relative;

    .icon-arrow {
      width: 22px;
      height: 22px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 34px;

      @media all and (min-width: $small-medium) {
        right: 40px;
        width: 33px;
        height: 33px;
      }

      @media all and (min-width: $desktop) {
        margin-bottom: 45px;
      }

      svg {
        @media all and (min-width: $small-medium) {
          width: 33px;
          height: 33px;
          display: block;
        }
      }
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 17px;
    }

    &:hover {
      .icon-arrow {
        svg {
          // width: 100%;

          path {
            fill: $blue;
          }
        }
      }
    }
  }

 

  .no-account {
    color: $blue;
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    @include fontsize(14px);
    text-align: center;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop) {
      letter-spacing: normal;
      @include fontsize(16px);
      margin-left: 60px;
    }

    @media all and (min-width: $desktop-1440) {
      margin-left: 0;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }

  .signup-now {
    color: $voilet-blue;
    font-family: $primary-fontfamily;
    transition: all 0.5s;
    font-weight: 600;
    &:hover {
      opacity: 0.8;
    }
  }

  .bottom-wrp {
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 30px;

    // @media all and (min-width:$small-medium) {
    //     padding-top: 60px;
    //     padding-bottom: 60px;
    // }
  }

  .save-detail-wrp {
    padding-top: 8px;
    display: flex;
    align-items: center;

    @media all and (min-width: $small-medium) {
      padding-top: 2px;
    }

    @media all and (min-width: $desktop) {
      letter-spacing: 0.25px;
      padding-top: 0;
    }

    .save-text {
      color: $voilet-blue;
      font-family: $primary-fontfamily;
      font-weight: $fw-semibold;
      @include fontsize(14px);
      margin-left: 8px;

      @media all and (min-width: $small-medium) {
        @include fontsize(12px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
        margin-left: 10px;
      }

      @media all and (min-width: $desktop-1440) {
        margin-left: 11px;
        letter-spacing: 0.1px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 26px;

      @media all and (min-width: $desktop) {
        width: 55px;
        height: 28px;
      }

      @media all and (min-width: $desktop-xl) {
        width: 70px;
        height: 35px;
      }
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      background-color: transparent;
      border: 1px solid #dedede;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #dedede;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      @media all and (min-width: $desktop) {
        width: 20px;
        height: 20px;
      }

      @media all and (min-width: $desktop-xl) {
        width: 27px;
        height: 27px;
      }
    }

    input:checked + .slider {
      background-color: $light-green;
      border: 1px solid transparent;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $light-green;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px) translateY(-50%);
      -ms-transform: translateX(26px) translateY(-50%);
      transform: translateX(26px) translateY(-50%);
      background-color: $white-color;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.wave-wrap {
  display: none;

  @media all and (min-width: $small-medium) {
    position: absolute;
    // width: 62%;
    width: 100%;
    top: 0;
    left: -99px;
    display: block;
    // mix-blend-mode: screen;
    height: 100%;
  }

  @media all and (min-width: $desktop) {
    width: 62%;
    left: 0;
  }

  @media all and (min-width: $desktop-1500) {
    width: 62%;
  }

  // @media all and (min-width: 2000px) {
  //   width: 40%;
  // }

  // @media all and (min-width: 2200px) {
  //   width: 38%;
  //   top: 42%;
  //   margin-left: 0;
  // }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.colored-background {
  @media all and (min-width: $small-medium) {
    width: 49%;
  }

  @media all and (min-width: $small-medium) {
    width: 46%;
  }

  &::before {
    @media all and (min-width: $small-medium) {
      background: linear-gradient(221deg, #00d0be 0%, #281651 100%);
    }
  }

  &::after {
    @media all and (min-width: $small-medium) {
      // content: "";
      position: absolute;
      background: url(../../../assets/images/wave.svg) no-repeat;
      top: 50%;
      left: -199px;
      height: 437px;
      width: 640px;
      transform: translateY(-50%);
      background-size: cover;
      // opacity: 0.7;
      mix-blend-mode: screen;
      margin-top: 80px;
    }

    @media all and (min-width: $desktop) {
      left: -120px;
      height: 400px;
      width: 620px;
    }

    @media all and (min-width: $desktop-1440) {
      // height: 630px;
      // width: 800px;

      height: 600px;
      width: 750px;
      margin-top: 50px;
    }

    @media all and (min-width: $desktop-1600) {
      width: 900px;
      height: 700px;
    }

    @media all and (min-width: $desktop-xl) {
      left: -120px;
      height: 740px;
      width: 1044px;
    }
  }

  .logo {
    display: none;
position: relative;
    @media all and (min-width: $small-medium) {
      display: block;
      width: 110px;
      // margin: 50px 0 0 40px;
      margin: 53px 0 0 108px;

    }
    @media all and (min-width: $medium) {
      width: 140px;
    }
    @media all and (min-width: $desktop) {
      margin: 53px 0 0 108px;
    }

    @media all and (min-width: $desktop-xl) {
      // width: 100px;
      // margin: 102px 0 0 102px;
    }

    // @media all and (min-width:768px) and (max-height:770px) {
    //     margin-top: 50px;
    // }
    img {
      display: block;
      width: 100%;
    }
  }
}

.signin-form-wrapper {
  position: relative;

  @media all and (min-width: $small-medium) {
    display: flex;
    border-radius: 0;
    // background: linear-gradient(221deg, #00D0BE 0%, #281651 100%);
    // box-shadow: 0px 0px 8px 2px rgba(123, 77, 237, 0.05);
    // border-radius: 20px;
    // background: linear-gradient(90deg, #540BE0 0%, #240151 83.3%);
  }

  .right-signin-wrp {
    @media all and (min-width: $small-medium) {
      height: 100vh;
      overflow: auto;
      // width: 81.55%;
      width: 69.5%;
      // border-radius: 100px 0 0 100px;
      border-radius: 50px 0 0 50px;
      // border: 1px solid #ECECEC;
      background: #fff;
      box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
      position: relative;
    }
    @media all and (min-width: $medium) {
            border-radius: 100px 0 0 100px;
            width:  70.5%;
    }
    // @media all and (min-width: $desktop) {
    //   width: 76.5%;
    // }

    @media all and (min-width: $desktop-1440) {
      width: 71.5%;
    }

    // @media all and (min-width: $desktop-xl) {
    //   width: 76.5%;
    // }

    form {
      height: 100%;
    }
  }
}

.link-check {
  font-size: 2.25rem;
  text-align: center;
  display: block;
  margin-top: 400px;
  height: 100%;
}

.block-signin-wrp {
  @media all and (min-width: $small-medium) {
    padding-top: 50px;
  }

  @media all and (min-width: 1700px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media all and (min-width: $desktop-xl) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .signin-form-wrp {
    h1 {
      @media all and (min-width: $small-medium) {
        margin-bottom: 10px;
      }

      @media all and (min-width: $desktop) {
        margin-bottom: 12px;
      }

      @media all and (min-width: $desktop-xl) {
        margin-bottom: 16px;
      }
    }

    p {
      @media all and (min-width: $small-medium) {
        margin-bottom: 50px;
      }

      @media all and (min-width: $desktop) {
        margin-bottom: 70px;
      }

      @media all and (min-width: $desktop-xl) {
      }
    }
  }
  .sign-btn-divider {
    display: block;
    text-align: center;
    margin: 10px 0 27px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 45%;
      height: 1px;
      background-color: #d4d4d4;
      right: 0;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 45%;
      height: 1px;
      background-color: #d4d4d4;
      left: 0;
    }
  }

  @media all and (min-width: $desktop) {
  }

  @media all and (min-width: $desktop-xl) {
  }
}
