@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.network-wrp {
  transition: none;

  &:hover {
    @media all and (min-width: $small-medium) {
      // border: 1px solid #281651;
      // border-radius: 8px;
    }

    &.inner-netwrk {
      .health-plan-head {
        // border-bottom: 1px solid #281651;
      }
    }
  }

  @media all and (max-width: 767px) {
    border-radius: 0 0 20px 20px;
    background: #fff;
  }

  @media all and (min-width: $small-medium) {
    margin-bottom: 38px;
  }

  @media all and (min-width: $desktop) {
    margin-bottom: 42px;
  }

  @media all and (min-width: $desktop-1600) {
    margin-bottom: 40px;
  }

  .health-range {
    border-radius: 0 0 8px 8px;
    border: 1px solid transparent;
    border-top: 0;
    background: #fff;
    box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
    padding: 30px 20px 20px;

    @media all and (min-width: $small-medium) {
      padding: 40px;
      border-radius: 0 0 8px 8px;
      box-shadow: none;
      border-color: #eaeaea;
    }

    @media all and (min-width: $desktop) {
      padding: 40px 37px 28px;
      display: flex;
      justify-content: space-between;
    }

    @media all and (min-width: $desktop-1440) {
      padding: 30px 37px 28px;
    }

    @media all and (max-width: 767px) {
      background-color: transparent;
      box-shadow: none;
      padding: 32px 0 0;
    }

    @media all and (min-width: $desktop-1600) {
      padding: 35px 50px 20px;
    }

    .individual-icon {
      background: url(../../../assets/images/individual-icon.svg) no-repeat;
      height: 10px;
      width: 7px;
      background-size: 7px auto;
      margin-right: 6px;
      display: block;

      @media all and (min-width: $small-medium) {
        width: 10px;
        height: 12px;
        background-size: 10px auto;
      }
    }

    .family-icon {
      background: url(../../../assets/images/family-icon.svg) no-repeat;
      height: 12px;
      width: 12px;
      background-size: 12px auto;
      margin-right: 6px;
      display: block;

      @media all and (min-width: $small-medium) {
        width: 14px;
        height: 14px;
        background-size: 14px auto;
      }
    }

    .user-type {
      color: $white-color;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      border-radius: 4px;
      background: $blue;
      padding: 4px;
      width: 135px;
      display: flex;
      align-items: center;
      margin-bottom: 19px;
      justify-content: center;
      font-weight: $fw-semibold;

      @media all and (min-width: $small-medium) {
        width: 151px;
        @include fontsize(14px);
        padding: 7px;
        margin-bottom: 20px;
      }

      @media all and (min-width: $desktop) {
        width: 165px;
        @include fontsize(14px);
        margin-bottom: 25px;
        padding: 7px 10px 7px 12px;
      }

      @media all and (min-width: $desktop-1600) {
        margin-bottom: 30px;
        width: 203px;
        @include fontsize(20px);
      }
    }
  }

  .health-plan-head {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid transparent;
    background: #18d6dc;
    padding: 13px;
    display: flex;
    align-items: center;

    @media all and (max-width: 767px) {
      display: none;
    }

    @media all and (min-width: $small-medium) {
      padding: 20px 18px;
    }

    @media all and (min-width: $desktop) {
      padding: 13px 15px;
    }

    @media all and (min-width: $desktop-1600) {
      padding: 15px;
    }

    .icon-network {
      background: url(../../../assets/images/network-icon.svg) no-repeat;
      width: 25px;
      height: 22px;
      background-size: 25px auto;
      margin-right: 14px;

      @media all and (min-width: $desktop) {
        width: 39px;
        height: 35px;
        background-size: 39px auto;
        margin-right: 20px;
      }

      @media all and (min-width: $desktop-xl) {
        width: 40px;
        height: 35px;
        background-size: 40px auto;
        margin-right: 20px;
      }
    }

    h3 {
      color: #281651;
      font-family: $primary-fontfamily;
      text-align: left;
      @include fontsize(16px);
      font-weight: $fw-bold;
      @include fontsize(14px);
      letter-spacing: 0.15px;
      margin-bottom: 0;

      @media all and (min-width: $small-medium) {
        @include fontsize(20px);
        font-weight: $fw-semibold;
        margin-bottom: 0 !important;
      }
      @media all and (min-width: $desktop) {
        @include fontsize(20px);
        font-weight: $fw-semibold;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(26px);
      }
    }
  }

  .type-plans {
    // display: flex;
    // justify-content: space-between;

    @media all and (min-width: $desktop) {
      flex-direction: column;
      padding-right: 31px;
    }

    @media all and (min-width: $desktop-1440) {
      padding-right: 50px;
    }

    @media all and (min-width: $desktop-1600) {
      padding-right: 50px;
    }

    .plan-wrp {
      @media all and (min-width: $desktop) {
        width: 100%;
      }

      &.plan-one {
        @media all and (min-width: $small-medium) {
          // padding-right: 20px;
        }

        @media all and (min-width: $desktop) {
          padding-right: 0;
        }
      }

      .progress-main {
        width: 100%;

        @media all and (min-width: $small-medium) {
          display: flex;
          justify-content: space-between;
        }

        @media all and (min-width: $desktop) {
          flex-direction: column;
        }

        .progress-bar {
          width: 100%;

          @media all and (min-width: $small-medium) {
            width: 43.7%;
          }

          @media all and (min-width: $desktop) {
            width: 100%;
            margin-bottom: 15px;
          }

          @media all and (min-width: $desktop-1600) {
            margin-bottom: 48px;
          }
        }
      }
    }
  }

  .individual-health-range {
    margin-bottom: 26px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 17px;
    }

    @media all and (min-width: $desktop) {
      width: 31%;
      margin-bottom: 0;
    }

    @media all and (min-width: $desktop-1440) {
      width: 30.5%;
      margin-top: 10px;
    }

    @media all and (min-width: $desktop-1600) {
      // width: 33.33%;
      width: 32%;
    }
  }

  .family-health-range {
    @media all and (min-width: $desktop) {
      width: 33%;
    }

    @media all and (min-width: $desktop-1440) {
      width: 31%;
      margin-top: 10px;
    }

    @media all and (min-width: $desktop-1600) {
      // width: 33.33%;
      width: 32%;
    }

    .type-plans {
      @media all and (min-width: $desktop) {
        padding-right: 48px;
      }

      @media all and (min-width: $desktop-1440) {
        padding-right: 58px;
      }
    }

    .user-type {
      @media all and (min-width: $desktop) {
        padding: 7px 7px 7px 12px;
        width: 148px;
      }

      @media all and (min-width: $desktop-1600) {
        width: 190px;
      }
    }
  }

  .cover-table {
    @media all and (min-width: $desktop) {
      width: 39%;
    }

    @media all and (min-width: $desktop-1600) {
      width: 33.33%;
      padding-left: 15px;
    }
  }
}
//new-ui
.new-ui-health-range {
  .individual-health-range {
    flex-grow: 1;
  }
  .cover-table {
    flex-grow: 1;
    .coverage-table-wrp {
      max-width: none;
    }
  }
}
