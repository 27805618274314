@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";


.auth {
    a {
        color: $blue;
        margin-bottom: 25px;
        display: block;
    }
}