@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.claim-detail-wrapper {
  &.expand-claim-detail {
    .cost-breakdown {
      padding: 18px 0px 0;

      @media all and (min-width: $small-medium) {
        padding: 20px 40px 0;
      }

      @media all and (min-width: $desktop) {
        padding: 0px 19px 0;
      }

      h2 {
        @include fontsize(18px);
        font-weight: $fw-semibold;
        color: $blue;
        margin-bottom: 4px;
        padding: 0 15px;

        @media all and (min-width: $small-medium) {
          margin-bottom: 20px;
          padding: 25px 23px;
          @include fontsize(20px);
          background: #18d6dc;

          border-radius: 10px 10px 0 0;
        }
      }

      .final-column {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(158, 154, 166, 0.5);
      }

      .cost-breakdown-columns {
        padding-left: 0;

        li {
          &:first-child {
            justify-content: space-between;
            .claim-expand-value {
              color: $blue;
              font-weight: 500;
              @media all and (min-width: $small-medium) {
                font-weight: 400;
              }
            }
            .claim-expand-key {
              color: $blue;
              font-weight: 600;
              @media all and (min-width: $small-medium) {
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .claims-wrppr {
      border-left: 2px solid #00d0be;
    }

    .service-date-wrp {
      padding: 2px 4px;
    }
  }

  .insurance-head-wrppr {
    position: relative;

    .bck-btn-wrap {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 21px;
      @include fontsize(14px);
      font-family: $primary-fontfamily;
      display: none;

      @media all and (min-width: $small-medium) {
        display: flex;
        margin-top: -10px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
        margin-top: -12px;
      }

      .icon-back {
        background: url(../../../assets/images/back.svg) no-repeat;
        width: 14px;
        height: 9px;
        background-size: 14px auto;
        display: block;
        margin-right: 5px;
        margin-top: 4px;

        @media all and (min-width: $small-medium) {
          width: 12px;
          height: 8px;
          background-size: 12px auto;
        }

        @media all and (min-width: $desktop) {
          width: 17px;
          height: 12px;
          background-size: 17px auto;
        }

        @media all and (min-width: $desktop-xl) {
          width: 25px;
          height: 20px;
          background-size: 25px auto;
        }
      }
    }
  }

  .head-insurnce {
    background: $white-color;
    box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
    border: 1px solid #eaeaea;
    border-radius: 10px;
    margin-bottom: 24px;

    h2 {
      padding-left: 0;
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(18px);
      font-weight: $fw-bold;
      margin-bottom: 0px;
      padding-right: 10px;
    }

    .claim-icon-nw {
      background-color: rgba(5, 49, 73, 0.12);
      height: 24px;
      width: 24px;
      border-radius: 50%;
      margin-right: 11px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media all and (min-width: $desktop) {
        height: 30px;
        width: 30px;
      }

      @media all and (min-width: $desktop-xl) {
        height: 34px;
        width: 34px;
      }
    }

    .icon-claim {
      background: url(../../../assets/images/claim-icon.svg) no-repeat;
      height: 14px;
      width: 11px;
      background-size: 11px auto;
      display: block;
      margin-right: 0;

      @media all and (min-width: $desktop) {
        height: 18px;
        width: 14px;
        background-size: 14px auto;
      }

      @media all and (min-width: $desktop-xl) {
        height: 20px;
        width: 15px;
        background-size: 15px auto;
      }
    }

    .icon-member {
      background: url(../../../assets/images/personal-icon.svg) no-repeat;
      height: 24px;
      width: 24px;
      background-size: 24px auto;
      display: block;
      margin-right: 11px;

      @media all and (min-width: $desktop) {
        height: 30px;
        width: 30px;
        background-size: 30px auto;
      }
    }

    .claim-main-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid rgba(158, 154, 166, 0.5);

      @media all and (min-width: $small-medium) {
        display: block;
        ul {
          margin-bottom: 20px !important;
        }
      }
      @media all and (min-width: $medium) {
        display: flex;
        ul {
          margin-bottom: 0px !important;
        }
      }
      @media all and (min-width: $desktop) {
        width: 40%;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      .claim-icon-wrp {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h4 {
          color: #053149;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: $fw-bold;
          letter-spacing: 0.25px;

          @media all and (min-width: $small-medium) {
            color: #053149;
          }

          @media all and (min-width: $desktop) {
            @include fontsize(14px);
            color: #053149;
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(18px);
          }
        }

        h5 {
          color: #053149;
          font-family: $primary-fontfamily;
          @include fontsize(16px);
          font-weight: $fw-regular;
          letter-spacing: 0.25px;

          @media all and (min-width: $small-medium) {
            @include fontsize(14px);
            color: #053149;
          }

          @media all and (min-width: $desktop) {
            @include fontsize(16px);
            color: #053149;
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(20px);
          }
        }
      }

      .payment-status {
        @include fontsize(16px);
        font-weight: 600;
        background: #6fdd88;
        padding: 4px 5px;
        border-radius: 4px;
        color: #fff;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(16px);
        }
      }

      .service-date-wrp {
        border-radius: 2px;
        border: 1px solid #053149;
        padding: 4px;
        display: flex;
        align-items: center;

        .service {
          color: #053149;
          font-family: $primary-fontfamily;
          @include fontsize(8px);
          font-weight: $fw-bold;
          letter-spacing: 0.5px;
          display: block;
          text-transform: capitalize;
          margin-bottom: 2px;
          text-align: left;
          width: 100%;

          @media all and (min-width: $small-medium) {
            @include fontsize(10px);
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(12px);
          }
        }

        .date {
          color: #053149;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-style: normal;
          font-weight: $fw-bold;
          letter-spacing: 0.5px;
          text-transform: uppercase;

          @media all and (min-width: $desktop) {
            @include fontsize(14px);
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(16px);
          }
        }
      }
    }
  }

  .paid-btn {
    color: #00d0be;
    font-family: $primary-fontfamily;
    @include fontsize(12px);
    border: 2px solid #00d0be;
    background-color: transparent;
    text-transform: uppercase;
    padding: 2px;

    @media all and (min-width: $desktop) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(16px);
    }
  }

  h2 {
    color: $blue;
    font-family: $primary-fontfamily;
    @include fontsize(16px);
    font-weight: 700;
    letter-spacing: 0.15px;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 20px;

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
      margin-bottom: 12px;
    }

    @media all and (min-width: $desktop-xl) {
      padding-left: 35px;
      @include fontsize(26px);
    }
  }

  .member-head {
    background: rgba(233, 232, 238, 0.3);
    padding: 12px 25px 0;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;

    @media all and (min-width: $medium) {
      padding: 20px 30px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 20px 43px 20px 37px;
      margin-bottom: 40px;
    }

    .member-detail {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding-right: 10px;

      .detail {
        width: 33.33%;
        margin-bottom: 10px;

        &.small {
          width: 10%;
        }

        @media all and (min-width: $medium) {
          margin-bottom: 20px;
        }

        @media all and (min-width: $desktop) {
          width: 20%;
          margin-bottom: 0;
        }
      }

      h3 {
        font-family: $primary-fontfamily;
        color: $blue;
        @include fontsize(14px);
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.25px;
        text-align: left;
        margin-bottom: 2px;

        @media all and (min-width: $medium) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop-1440) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }

      h4 {
        font-family: $primary-fontfamily;
        color: $blue;
        @include fontsize(14px);
        font-style: normal;
        text-align: left;
        letter-spacing: 0.25px;
        margin-bottom: 0;

        @media all and (min-width: $medium) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-1440) {
          @include fontsize(18px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }
    }
  }

  .cost-breakdown {
    padding: 0 20px;
    border: 1px solid #eaeaea;
    box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
    border-radius: 10px;

    @media all and (min-width: $small-medium) {
      border: none;
      box-shadow: none;
    }
    @media all and (min-width: $desktop-xl) {
      padding: 0 44px 0 35px;
    }

    h2 {
      @include fontsize(14px);

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
        padding-left: 22px;
      }

      .summary {
        text-decoration: underline;
      }
    }

    .cost-breakdown-columns {
      border-left: 0;
      padding-left: 0;
      overflow: auto;
    }

    .product-wrp {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(158, 154, 166, 0.5);
      padding-bottom: 7px;
      margin-bottom: 7px;

      .name {
        color: #737373;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: 400;

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
        }
      }

      .price {
        color: #281651;
        text-align: right;
        font-family: $primary-fontfamily;
        @include fontsize(14px);

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
        }
      }
    }

    .final-column {
      text-transform: uppercase;
      display: flex;
      margin-top: 10px;
      align-items: center;

      @media all and (min-width: $desktop-xl) {
        margin-top: 8px;
      }

      .icon-member {
        background: url(../../../assets/images/member-response-icon.svg)
          no-repeat;
        height: 20px;
        width: 20px;
        background-size: 20px auto;
        display: block;
        margin-right: 8px;
        border-radius: 50%;

        @media all and (min-width: $desktop) {
          height: 28px;
          width: 28px;
          background-size: 28px auto;
        }

        @media all and (min-width: $desktop-xl) {
          height: 35px;
          width: 35px;
          background-size: 35px auto;
        }
      }

      h3 {
        color: #737373;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        margin-bottom: 0;

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }

      .price {
        margin-left: auto;
        color: #240151;
        font-family: $primary-fontfamily;
        @include fontsize(14px);

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }
    }
  }
  .breakdown-wrap {
    @media all and (min-width: $small-medium) {
      border: 1px solid #eaeaea;
      box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      margin-bottom: 30px;

      h2 {
        padding: 25px 23px !important;
      }
    }
  }
}
