@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.provider-search-wrap {

  // background: #e9e8ee;
  .footer {
    @media all and (min-width: $medium) {
      margin-left: 40px;
      margin-right: 40px;
    }

    @media all and (min-width: $desktop-xl) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }

  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }

    @media all and (min-width: $desktop) {
      padding: 44px 84px;

    }

    .container {
      padding: 20px 0px;

      @media all and (min-width: 768px) {
        padding: 20px 10px 0;
      }

      @media all and (min-width: 853px) {
        padding: 20px 30px 0;
      }

      @media all and (min-width: 1160px) {
        padding: 20px 38px 0;
      }

      @media all and (min-width: 1280px) {
        padding: 20px 0 0;
      }
    }

    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }

  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;

    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }

    span {
      display: block;
    }

    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }

    .back-dots-wrap {
      border: 1px solid #fff;
    }

    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }

    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }

    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }

    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }

      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }

    @media all and (min-width: $small-medium) {
      display: none;
    }
  }






  .container {
    padding: 0;

    @media all and (min-width: $small-medium) {
      padding: 20px 40px 0;
    }

    @media all and (min-width: $desktop) {
      padding: 20px 34px 0px;
    }
  }

  .provider-search-form-block {
    margin-bottom: 24px;
    position: relative;

    &:last-child {
      margin-bottom: 80px;
      @media (min-width: 768px) {
        margin-bottom: 0px;
      }
    }
    @media (min-width: 768px) {
      width: calc(50% - 12px);
      margin-bottom: 0px;
    }
  }

  .provider-search-card {
    background: #fff;

    @media all and (min-width: $small-medium) {
      background-color: #fff;
      padding: 40px 40px 10px;
      border-radius: 30px 30px 0 0;
    }

    .signin-wrp {
      background: #fff;

      // @media (min-width: 768px) {
      //   max-width: 100%;
      // }
      .provider-search-in {
       
        h1 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 56px;
        }

        .provider-search-label {
          margin-bottom: 8px;
        
            @media (min-width: 1280px) and (max-width: 1440px){
              font-size: 14px;
            }
          
        }
      }
    }
  }

}

.provider-list-wrp-whole {
  display: none;
  &.search-active{
    display: block;
  }
  @media (min-width: 768px) {
    display: block;
    margin-bottom: 24px;
    // min-height: calc(100vh - 395px);
  }
  @media (min-width: 1024px) {
    // min-height: calc(100vh - 405px);
  }
}

.list-invisible{
  form{
    .new-signin-wrp{
      @media (min-width:768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom: none;
      }
      .provider-search-in{
        @media (min-width: 768px) {
          width: 100% !important;
        }
        p{
          @media (min-width: 768px) {
            margin-bottom: 50px !important;
          }
        }
        .mbl-blck-searching{
          @media (min-width: 768px) {
            display: block !important;
            width: calc(40% - 12px);
            margin: 0 auto 15px
          }
        }
        .provider-search-frm-wrp{
          @media (min-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 35px;
          }
          .provider-search-form-block{
            @media (min-width: 768px) {
              width: calc(40% - 12px);
            }
          }
        }
      }
      .listing-submit-btn-wrp{
        @media (min-width: 768px) {
          width: calc(40% - 12px);
          margin-top: 30px !important;
        }
      }
    }
  }
}

.listing-wrap{
  display: none;
  &.list-active{
    display: block;
  }
  @media (min-width: 768px) {
    display: block;
  }
}

.provider-list-inner-wrp{
  @media (min-width: 768px) {
    min-height: calc(100vh - 395px);
  }
  @media (min-width: 1024px) {
    min-height: calc(100vh - 405px);
  }
  @media (min-width: 1400px) {
    min-height: calc(100vh - 356px);
  }
}

.mbl-blck-searching {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
.prov-list-err{
  position: absolute;
  top: 100%;
  left: 0;
}
.new-signin-wrp {
  @media (min-width: 768px) {
    max-width: 100% !important;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
    padding: 0 0 24px;
    border-bottom: 2px dashed rgba(234, 234, 234, 1);
    &.error-active{
      padding-bottom: 40px;
    }
  }

  @media (min-width: 1280px) {
    max-width: 100% !important;
    padding: 0 0 24px;
  }

  @media (min-width: 1440px) {
    max-width: 100% !important;
    padding: 0 0 24px;
  }

  .provider-search-frm-wrp {
    @media (min-width: 768px) {
      display: flex;
      gap: 24px;
    }
  }
  .listing-submit-btn-wrp{
    @media (min-width: 768px) {
      width: auto;
      padding: 0px;
    }
    @media (min-width: 1200px) {
      padding: 0px;
      width: calc(30% - 12px);
    }
    .btn{
      @media (min-width: 768px) {
        padding: 16px 65px;
        display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0px;
            width: 100%;
            font-size: 16px;
      }
      @media (min-width: 1280px) {
        font-size: 18px;
      }
      @media (min-width: 1200px) and (max-width: 1440px){
        font-size: 16px;
      }
    }
    .icon-arrow{
      display: block;
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
}
.provider-search-in {
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 1200px) {
    width: calc(70% - 12px);
  }
}

.provider-search-form-block {
  margin-bottom: 24px;
  position: relative;

  &:last-child {
    margin-bottom: 80px;
    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }
  @media (min-width: 768px) {
    width: calc(50% - 12px);
    margin-bottom: 0px;
  }
 
}

.provider-search-label {
  @media (min-width: 992px) and (max-width: 1279.98px){
    font-size: 16px;
  }
    @media (min-width: 1280px) and (max-width: 1440px){
      font-size: 14px;
    }
  
}