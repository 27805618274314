@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.claim-card-wrap {
  .claim-card-nw {
    &.claim-wrp-main {
      width: 100%;
      min-height: 150px;

      .claim-head-wrp {
        @media all and (max-width: 1199px) {
          width: 100%;
        }
      }
    }

    @media all and (min-width: 1200px) {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      cursor: pointer;
    }

    .claim-wrp {
      @media all and (min-width: 1200px) {
        margin-bottom: 0;
        height: 100%;
      }
    }
  }

  .claim-head-wrp {
    // @media all and (min-width: $desktop) {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-between;
    //   cursor: pointer;
    // }
    @media all and (min-width: 1200px) {
      width: calc(50% - 28px);
      margin-right: 26px;
      margin-bottom: 26px;
      // min-height: 130px;
    }

    &:nth-child(2n) {
      @media all and (min-width: 1200px) {
        margin-right: 20px;
      }

      @media all and (min-width: 1440px) {
        margin-right: 23px;
      }
    }

    &:nth-child(3n) {
      @media all and (min-width: 1440px) {
        margin-right: 0px;
      }
    }

    @media all and (min-width: $desktop-1440) {
      width: calc(33.33% - 17px);
    }

    // &:nth-child(2n) {
    //   @media all and (min-width: $desktop) {
    //     margin-right: 20px;
    //   }

    //   @media all and (min-width: $desktop-1440) {
    //     margin-right: 40px;
    //   }
    // }

    // &:nth-child(3n) {
    //   @media all and (min-width: $desktop-1440) {
    //     margin-right: 28px;
    //   }
    // }

    .claim-wrp {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media all and (min-width: $small-medium) {
        min-height: 105px;
      }

      @media all and (min-width: 1200px) {
        min-height: 130px;
      }

      // @media all and (min-width: $desktop) {
      //   width: calc(50% - 35px);
      //   margin-right: 40px;
      //   margin-bottom: 45px;
      // }

      // @media all and (min-width: $desktop-1440) {
      //   width: calc(33.33% - 40px);
      // }

      // &:nth-child(2n) {
      //   @media all and (min-width: $desktop) {
      //     margin-right: 20px;
      //   }

      //   @media all and (min-width: $desktop-1440) {
      //     margin-right: 40px;
      //   }
      // }

      // &:nth-child(3n) {
      //   @media all and (min-width: $desktop-1440) {
      //     margin-right: 28px;
      //   }
      // }
    }
  }

  > div {
    overflow: visible !important;

    // @media all and (min-width: $desktop-xl) {
    //   height: calc(100vh - 306px) !important;
    // }

    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      max-height: 100% !important;
      // opacity: 1 !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .thumb-vertical {
    position: relative;
    display: block;
    // height: 1680px;
    height: 200px;
    cursor: pointer;
    border-radius: inherit;
    background-color: $blue;
    width: 4px;
    left: -1px;

    @media all and (max-width: 767px) {
      height: auto !important;
    }

    // @media all and (min-width:$desktop) {
    //     height: 980px !important;
    // }

    // @media all and (min-width:$desktop-1440) {
    //     height: 800px !important;
    // }
  }

  .track-vertical {
    background-color: #c6c3ca;
    position: absolute;
    width: 1px;
    display: block !important;
    right: -20px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;

    @media all and (max-width: 767px) {
      background-color: transparent;
    }

    @media all and (min-width: $desktop) {
      right: -10px;
    }

    @media all and (min-width: $desktop-1440) {
      right: -5px;
    }
  }

  .claim-wrp {
    background-color: $white-color;
    padding: 17px 14px 10px;
    margin-bottom: 17px;
    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0px 0px 8px 2px rgba(123, 77, 237, 0.05);

    @media all and (min-width: $small-medium) {
      margin-bottom: 24px;
      padding: 14px 0 0;
    }

    &:hover {
      border: 1px solid #281651;
    }
  }

  .date {
    color: #9f9f9f;
    font-family: $primary-fontfamily;
    @include fontsize(12px);
    font-weight: $fw-semibold;

    @media all and (min-width: $small-medium) {
      @include fontsize(10px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(12px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(14px);
    }
  }

  .claim-main-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 15px;
      padding: 0 14px;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 14px;
    }

    @media all and (min-width: $desktop-xl) {
      margin-bottom: 14px;
    }
  }

  .claim-detail {
    &.member {
      display: none;

      @media all and (min-width: $small-medium) {
        display: flex;
        justify-content: space-between;
        // margin-top: 20px;
        padding: 15px;
        border-top: 1px solid #eaeaea;
        background-color: #f9f7fe;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .claim-main-wrap {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    @media all and (min-width: $small-medium) {
      padding: 0 14px;
      margin-bottom: 20px;
    }
  }

  h4 {
    color: $blue;
    font-family: $primary-fontfamily;
    @include fontsize(13px);
    font-style: normal;
    font-weight: 400;
    margin-top: 6px;

    @media all and (min-width: $small-medium) {
      font-weight: $fw-bold;
      color: #240151;
      @include fontsize(12px);
      margin-bottom: 12px;
    }

    @media all and (min-width: $desktop) {
      font-weight: $fw-regular;
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(18px);
      margin-bottom: 14px;
    }
  }

  .arrow-right {
    display: none;
    background: url(../../../assets/images/claim-arrow.svg) no-repeat;
    height: 11px;
    width: 7px;
    background-size: 7px auto;
    margin-right: 5px;
    filter: brightness(0) saturate(100%) invert(11%) sepia(11%) saturate(255%)
      hue-rotate(289deg) brightness(94%) contrast(96%);
  }

  .claim-name {
    display: flex;
    flex-direction: column;
    @media all and (min-width: $small-medium) {
      flex-direction: column-reverse;
    }
    h3 {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: $fw-medium;
      text-align: left;
      margin-bottom: auto;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
        font-weight: 600;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }
    }
  }

  .claim-detail {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    .payment-status {
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      letter-spacing: 0.5px;
      // text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 4px;
      // border: 2px solid #6FDD88;
      color: #fff;
      min-width: 52px;
      background-color: #6fdd88;
      margin-bottom: 4px;
      white-space: nowrap;
      @media all and (min-width: $small-medium) {
        margin-bottom: 0;
      }

      @media all and (max-width: 375px) {
        @include fontsize(10px);
      }

      &.success {
        color: #00d0be;
        border: 2px solid #00d0be;
      }

      &.fail {
        color: #ff451a;
        border: 2px solid #ff451a;
      }

      &.hold {
        color: #fff229;
        border: 2px solid #fff229;
      }

      &.void {
        color: #1e82a3;
        border: 2px solid #1e82a3;
      }

      &.adjust {
        color: #685ca3;
        border: 2px solid #685ca3;
      }

      &.dup {
        color: #ffa894;
        border: 2px solid #ffa894;
      }

      &.comp {
        color: #b4ac37;
        border: 2px solid #b4ac37;
      }

      &.vouch {
        color: #00ffe9;
        border: 2px solid #00ffe9;
      }
    }

    h3 {
      color: $light-green;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: 400;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0;
        @include fontsize(14px);
        font-weight: 600;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }

    .claim-amt {
      color: $light-green;
      font-family: $primary-fontfamily;
      @include fontsize(12px);

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
        font-weight: $fw-semibold;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(18px);
      }
    }
  }
}

.claim-expand-wrp {
  background: #fff;
  box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.04);
  padding: 20px 0;

  @media all and (max-width: 767px) {
    border-top: 0;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    overflow: auto;
  }

  &::-webkit-scrollbar {
    @media all and (max-width: 767px) {
      display: none;
    }
  }

  @media all and (min-width: $desktop) {
    padding: 35px 0 0;
  }

  .sub-claim-wrap {
    padding: 30px 20px;

    @media all and (min-width: $small-medium) {
      padding: 40px;
    }

    @media all and (min-width: $desktop) {
      padding: 33px 50px;
    }

    @media all and (min-width: $desktop) {
      padding: 40px 80px 40px;
    }

    .summary {
      text-decoration: underline;
    }

    .member-head {
      margin-bottom: 12px;

      @media all and (min-width: $desktop) {
        padding: 20px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 25px;
      }
    }

    .paid-btn {
      display: none;
    }

    .claims-wrppr {
      padding-left: 20px;
      border-left: 2px solid rgba(252, 178, 21, 1);
    }

    h2 {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(16px);
      font-weight: 700;
      letter-spacing: 0.15px;
      text-align: left;
      margin-bottom: 10px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 20px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
        padding-left: 27px;
      }
    }

    .cost-breakdown {
      padding: 0;

      h2 {
        display: none;
      }
    }

    .claim-detail-wrapper {
      .cost-breakdown-columns {
        padding-bottom: 30px;
        border-left: 0;
        padding-left: 0;
      }

      h2 {
        display: none;
      }
    }
  }
}

.no-data-scrollbar {
  display: none;
}


//pagination
.claim-card-wrap {
  .pagintion {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }

    .pagination-list {
      display: flex;

      li {
        @media all and (min-width: $small-medium) {
          margin-right: 6px;
        }

        a {
          color: #9f9f9f;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: $fw-semibold;
          width: auto;
          padding: 10px;
          height: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid #9f9f9f;
          margin-right: 4px;

          @media all and (min-width: $desktop) {
            width: auto;
            height: 28px;
            font-size: 0.875rem;
            padding: 10px;
            border-radius: 4px;
          }
        }

        &.selected {
          a {
            border: 1px solid $light-green;
            color: #fff;
            background: $light-green;
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        &.previous {
          a {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              top: 50%;
              background: url(../../../assets/images/arrow-right.svg) no-repeat;
              width: 6px;
              height: 10px;
              background-size: 6px auto;
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        &.next {
          a {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              top: 50%;
              width: 6px;
              height: 10px;
              background: url(../../../assets/images/arrow-right.svg) no-repeat;
              background-size: 6px auto;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .entry-wrp {
      display: flex;
      align-items: center;

      @media all and (min-width: $small-medium) {
        margin-right: 40px;
      }
    }

    .entry-text {
      color: $blue;
      text-align: center;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      margin-right: 10px;
      text-transform: capitalize;

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }
    }

    .select-wrap {
      position: relative;
      border-radius: 14px;
      border: 1px solid #281651;
      width: 45px;
      height: 24px;
      cursor: pointer;

      @media all and (min-width: $desktop) {
        width: 56px;
        height: 29px;
      }

      .icon-arrow {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background: url(../../../assets/images/down-arrow.svg) no-repeat;
        width: 8px;
        height: 5px;
        background-size: 8px auto;
        pointer-events: none;
      }

      select {
        color: #281651;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-semibold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        background: transparent;
        border: 1px solid transparent;
        width: 100%;
        padding: 4px 10px;
        cursor: pointer;

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
        }
      }
    }
  }
}