@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.progress-main {
  .progress-bar {
    @media all and (min-width: $small-medium) {
      width: 47%;
      margin-bottom: 18px;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 38px;
    }

    &.progress-left {
      h4 {
        text-align: left;
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-semibold;
        margin-bottom: 10px;

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
          margin-bottom: 8px;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
          margin-bottom: 6px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(16px);
        }
      }
    }
  }

  .progress-value {
    display: flex;
    justify-content: space-between;

    .value {
      @include fontsize(16px);
      color: $blue;
      font-family: $primary-fontfamily;
      font-weight: $fw-regular;
      letter-spacing: 0.15px;

      @media all and (min-width: $small-medium) {
        @include fontsize(14px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(18px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }
    }
  }

  .progress-wrp {
    border-radius: 50px;
    background: rgba(40, 22, 81, 0.05);
    height: 12px;
    margin-bottom: 11px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 13px;
    }

    @media all and (min-width: $desktop-1600) {
      margin-bottom: 5px;
    }
  }

  .actual-progress {
    background: #18d6dc;
    border-radius: 50px;
    height: 12px;
  }

  .progress-left {
    margin-bottom: 20px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 20px;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 35px;
    }

    @media all and (min-width: $desktop-xl) {
      margin-bottom: 45px;
    }
  }

  .progress-right {
    margin-bottom: 20px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 24px;
    }
  }

  .btn {
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }

  .chart-wrp {
    display: none;

    .chart-head {
      display: flex;
      justify-content: space-between;
    }

    @media all and (min-width: $small-medium) {
      display: block;
    }

    .select-wrp {
      display: flex;
      align-items: center;

      label {
        color: #737373;
        text-align: right;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        margin-right: 5px;
      }

      .select-option {
        border-radius: 25px;
        border: 1px solid #dedede;
        padding: 4px 10px;
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-semibold;
        width: 62px;
      }

      .select-main {
        position: relative;

        .icon-arrow {
          background: url(../../../assets/images/down-arrow.svg) no-repeat;
          height: 8px;
          width: 13px;
          background-size: 13px auto;
          display: block;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .open-chart {
      .icon-open {
        background: url(../../../assets/images/icon-chart-open.svg) no-repeat;
        width: 20px;
        height: 20px;
        background-size: 20px auto;
        display: block;
      }
    }
  }
}
