@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.plan-content {
  @media all and (min-width: $small-medium) {
    background: #fff;
    border-radius: 30px 30px 0px 0;
  }

  .card-line {
    margin: auto;
    width: 1px;
    background: #ceeae8;
    display: none;

    @media all and (min-width: $desktop) {
      height: 1px;
      width: auto;
    }
  }

  .card-box {
    background: url(../../../assets/images/card-background.png) no-repeat;
    // height: 190px;
    width: 100%;
    padding: 10px 12px 12px;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
    margin-bottom: 25px;

    @media all and (min-width: $small-medium) {
      padding: 30px 19px 31px;
      margin-bottom: 40px;
    }

    @media all and (min-width: $desktop) {
      width: 100%;
      margin-bottom: 0;
      padding: 25px 24px 25px;
    }

    @media all and (min-width: $desktop-1600) {
      padding: 20px 18px 30px 30px;
    }

    @media all and (min-width: $desktop-xl) {
      width: 100%;
    }

    .card-logo-wrp {
      width: 50px;

      @media all and (min-width: $small-medium) {
        display: block;
        width: 40px;
        margin-left: auto;
      }

      @media all and (min-width: $desktop) {
        width: 45px;
        display: none;
      }

      @media all and (min-width: $desktop-1600) {
        width: 60px;
        margin-top: 8px;
        margin-right: 10px;
      }
    }

    .card-head {
      margin-bottom: 51px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 45px;
      }

      @media all and (min-width: $desktop) {
        margin-bottom: 35px;
        min-height: 41.4px;
      }

      @media all and (min-width: $desktop-1600) {
        margin-bottom: 30px;
      }

      @media all and (min-width: $desktop-xl) {
        margin-bottom: 54px;
      }

      h3 {
        color: $white-color;
        font-family: $primary-fontfamily;
        @include fontsize(20px);
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
        margin-top: 2px;

        @media all and (min-width: $small-medium) {
          margin-bottom: 5px;
          margin-top: 0;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
          margin-bottom: 3px;
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(36px);
          margin-bottom: 2px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(42px);
        }
      }

      p {
        color: $white-color;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: 500;
        letter-spacing: 0px;

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
          font-weight: $fw-semibold;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }
    }

    .card-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      // @media all and (min-width:$small-medium) {
      //     margin-right: 30px;
      // }

      @media all and (min-width: $desktop) {
        justify-content: flex-start;
        margin-bottom: 34px;
        gap: 18px;
      }

      @media all and (min-width: $desktop-1600) {
        margin-bottom: 45px;
      }

      @media all and (min-width: $desktop-xl) {
        margin-bottom: 75px;
      }

      &.first-card-content {
        @media all and (min-width: $desktop) {
          margin-bottom: 0px;
        }
      }

      h3 {
        color: $white-color;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: 400;
        text-align: left;
        margin-bottom: 7px;

        @media all and (min-width: $small-medium) {
          @include fontsize(12px);
          margin-bottom: 5px;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
          margin-bottom: 9px;
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(16px);
          margin-bottom: 5px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(18px);
        }
      }

      p {
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        // text-align: center;
        color: $white-color;
        font-weight: $fw-medium;

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
          text-align: left;
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(18px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }
    }

    // display: none;
    &.card-one {
      position: relative;
      background: #fff;
      border: 0;
      margin-bottom: 0;
      border-radius: 30px 30px 0 0;

      h2 {
        text-align: left;
        margin-bottom: 20px;
        @media all and (max-width: $small-medium) {
          display: none;
        }
        @media all and (min-width: 786px) {
          font-weight: 700;
          font-size: 16px;
        }
        @media all and (min-width: $desktop) {
          margin-bottom: 17px;
          @include fontsize(20px);
                font-weight: $fw-semibold;
        }
        @media all and (min-width:1600px) {
              font-size: 26px;
              font-weight: 600;
      }
      }

      .card-content-wrpper {
        @media all and (min-width: $desktop) {
          display: flex !important;
          flex-direction: column !important;
          align-items: normal !important;
          justify-content: flex-end !important;
        }
      }

      .card-content-wrpper {
        @media all and (min-width: $small-medium) {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          // width: 100%;

          h3 {
            color: $blue !important;
          }
          p {
            color: $blue !important;
            font-weight: 400;
          }
          h2 {
            color: $blue !important;
          }
        }

        @media all and (min-width: $desktop) {
          display: block;
        }
      }

      .card-logo-wrp {
        display: none;

        @media all and (min-width: $small-medium) {
          display: block;
          width: 40px;
          margin-left: auto;
          margin-bottom: 50px;
        }

        @media all and (min-width: $desktop) {
          position: absolute;
          top: 20px;
          right: 22px;
        }

        @media all and (min-width: $desktop-1600) {
          width: 60px;
          right: 18px;
        }
      }

      @media all and (max-width: 767px) {
        height: auto;
        background: rgba(255, 255, 255, 0.85);
        padding: 42px 20px 0;
      }

      @media all and (min-width: $small-medium) {
        background: transparent;
        border-radius: 20px;
        border: 1px solid #c8f7f9;
        background-position: center;
        background-size: cover;
        margin-bottom: 40px !important;
        padding: 30px 20px 30px;
      }

      @media all and (min-width: $desktop) {
        margin-bottom: 0px;
        width: 28%;
        padding: 22px 22px 18px;
        margin-bottom: 0px !important;
      }

      @media all and (min-width: $desktop-1600) {
        padding: 25px 30px 30px;
      }

      // &::after {
      //     position: absolute;
      //     content: "";
      //     left: 0;
      //     top: 0;
      //     width: 100%;
      //     background: url(../../../assets/images/card-one.png) no-repeat;
      //     height: 80px;

      //     @media all and (min-width:$small-medium) {
      //         display: none;
      //     }
      // }

      .card-content {
        @media all and (max-width: 767px) {
          margin-bottom: 30px;
        }

        @media all and (min-width: $small-medium) {
          margin-right: 30px;
        }

        @media all and (min-width: $desktop) {
          margin-right: 0;
        }

        h3 {
          @media all and (max-width: 767px) {
            color: $blue;
            font-family: $primary-fontfamily;
            @include fontsize(18px);
            font-weight: $fw-bold;
            margin-bottom: 11px;
          }
        }

        p {
          @media all and (max-width: 767px) {
            color: $blue;
            font-family: $primary-fontfamily;
            @include fontsize(16px);
            font-weight: $fw-semibold;
            letter-spacing: 0.25px;
          }
        }
        .head-wrp {
          h3 {
            @media all and (min-width: $desktop) {
              @include fontsize(20px);
              font-weight: $fw-semibold;
            }
          }
        }
      }

      .member-content {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 24px;

        @media all and (max-width: 767px) {
          // margin-top: 15px;
        }
        @media all and (min-width: 768px) {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 10px;
        }

        h3 {
          @media all and (max-width: 767px) {
            color: $blue;
            font-family: $primary-fontfamily;
            @include fontsize(16px);
            font-weight: $fw-bold;
          }
        }

        p {
          @media all and (max-width: 767px) {
            color: $blue;
            font-family: $primary-fontfamily;
            @include fontsize(14px);
            font-weight: $fw-medium;
          }
        }

        .head-wrp {
          width: 31%;
          position: relative;

          &:not(:first-child) {
            padding-left: 20px;
          }

          @media all and (min-width: $desktop) {
            width: 31%;
          }

          @media all and (min-width: $desktop-1440) {
            width: 31%;
          }

          &.last-head {
            @media all and (min-width: $desktop) {
              margin-right: 0;
              width: 31%;
            }

            @media all and (min-width: $desktop-1440) {
              width: 31%;
            }
          }

          &.first-head {
            @media all and (min-width: $desktop) {
              width: 31%;
            }

            @media all and (min-width: $desktop-1440) {
              width: 31%;
            }

            @media all and (min-width: $desktop-1600) {
              width: 31%;
            }
          }

          &::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 1px;
            top: 50%;
            transform: translateY(-50%);
            background: #ededed;
            right: 0;

            @media all and (min-width: $small-medium) {
              display: none;
            }
          }

          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }

      .card-one-wrp {
        @media all and (min-width: $small-medium) {
          width: 43%;
        }

        @media all and (min-width: $desktop) {
          width: 100%;
          margin-bottom: 0;
        }

        @media all and (min-width: $desktop-1600) {
          margin-bottom: 0;
        }

        @media all and (min-width: $desktop-xl) {
          margin-bottom: 15px;
        }

        h2 {
          color: $blue;
          font-family: $primary-fontfamily;
          @include fontsize(18px);
          font-weight: $fw-bold;
          text-align: left;
          letter-spacing: 0.15px;
          display: block;

          @media all and (min-width: 786px) {
            // display: none;
            font-size: 16px;
            font-weight: 700;
          }

          @media all and (min-width: 1200px) {
            @include fontsize(20px);
            font-weight: $fw-semibold;
          }
         

          @media all and (min-width: 1600px) {
            // display: none;
            font-size: 26px;
            font-weight: 600;
          }
        }

        h3 {
          color: $blue;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: 600;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          line-height: 1;

          @media all and (min-width: $small-medium) {
            color: $white-color;
            font-family: $primary-fontfamily;
            margin-bottom: 8px;
          }

          @media all and (min-width: $desktop) {
            margin-bottom: 12px;
            @include fontsize(14px);
          }

          @media all and (min-width: $desktop-1600) {
            @include fontsize(16px);
            margin-bottom: 4px;
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(18px);
          }
        }

        p {
          @media all and (min-width: $small-medium) {
            color: $white-color;
            font-family: $primary-fontfamily;
            @include fontsize(14px);
            font-weight: 400;
            letter-spacing: 0.25px;
          }

          @media all and (min-width: $desktop) {
            @include fontsize(16px);
          }

          @media all and (min-width: $desktop-1600) {
            @include fontsize(18px);
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(20px);
          }
        }

        .icon-sign {
          height: 10px;
          width: 8px;
          display: block;
          margin-right: 3px;

          @media all and (min-width: $small-medium) {
            height: 11px;
            width: 8px;
            margin-right: 3px;
          }

          @media all and (min-width: $desktop) {
            height: 15px;
            width: 14px;
            margin-right: 7px;
          }

          @media all and (min-width: $desktop-1600) {
            height: 14px;
            margin-right: 5px;
          }

          svg {
            display: block;
            height: 100%;
            width: 100%;

            path {
              @media all and (min-width: $small-medium) {
                fill: $blue;
              }
            }
          }
        }
      }

      .card-provider-wrp {
        margin-top: 26px;

        @media all and (min-width: $small-medium) {
          margin-top: 0;
          display: none;
        }

        @media all and (min-width: $desktop) {
          // margin-top: auto;
        }

        .member-content {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12px;

          @media all and (min-width: $small-medium) {
            margin-bottom: 0;
          }

          .desk-logo {
            display: none;

            @media all and (min-width: $small-medium) {
              display: block;
              width: 125px;

              img {
                width: 100%;
                display: block;
              }
            }

            @media all and (min-width: $desktop) {
              width: 160px;
            }

            @media all and (min-width: $desktop-1600) {
              width: 200px;
            }
          }
        }

        h2 {
          color: $blue;
          font-family: $primary-fontfamily;
          @include fontsize(18px);
          font-weight: $fw-bold;
          text-align: left;
          letter-spacing: 0.15px;
          display: block;
          margin-bottom: 10px;

          @media all and (min-width: $small-medium) {
            color: $white-color;
            font-family: $primary-fontfamily;
            @include fontsize(12px);
            font-weight: 400;
            letter-spacing: 0.25px;
            margin-bottom: 8px;
          }

          @media all and (min-width: $desktop) {
            margin-bottom: 5px;
          }

          @media all and (min-width: $desktop-1600) {
            @include fontsize(16px);
            margin-bottom: 2px;
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(18px);
          }
        }

        .logo-wrap {
          border-radius: 20px;
          border: 1px solid #c8f7f9;
          background-color: #c8f7f9;
          width: 140px;
          margin-right: 10px;

          @media all and (min-width: $small-medium) {
            display: none;
          }

          .logo-head {
            height: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px;
            background-color: #fff;
            border-radius: 20px 20px 0 0;

            @media all and (min-width: $small-medium) {
              border: 0;
              border-radius: 0;
              padding: 0;
              display: block;
              height: auto;
            }

            .icon-health-smart {
              background: url(../../../assets/images/cigna-logo.png) no-repeat;
              height: 38px;
              width: 100px;
              background-size: 100px auto;
            }

            .icon-atena {
              background: url(../../../assets/images/phcs-logo.png) no-repeat;
              height: 38px;
              width: 87px;
              background-size: 87px auto;
            }
          }

          .logo-body {
            text-align: center;
            min-height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $light-green;
            font-family: $primary-fontfamily;
            @include fontsize(14px);
            font-weight: $fw-semibold;
            border-radius: 0 0 20px 20px;

            @media all and (min-width: $small-medium) {
              padding: 0;
            }
          }
        }
      }
    }

    &.card-two {
      display: none;
      background: transparent;

      @media all and (min-width: $small-medium) {
        display: block;
        padding: 22px 20px 34px;
        margin-bottom: 0;
        border: 1px solid #ececec;
        box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      }

      @media all and (min-width: $desktop) {
        width: 23%;
        padding: 22px 23px 19px;
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
      }

      @media all and (min-width: $desktop-1440) {
        padding: 22px 23px 19px;
      }

      @media all and (min-width: $desktop-1600) {
        padding: 30px 20px;
      }

      @media all and (min-width: $desktop-xl) {
        width: 367px;
        padding: 30px;
      }

      h3 {
        color: #fff;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        letter-spacing: 0.25px;
        margin-bottom: 20px;
        font-weight: 700;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
          margin-bottom: 10px;
          color: $blue;
          font-weight: 700;
        }
        // @media all and (min-width:$desktop) {
        //     @include fontsize(14px);
        //     margin-bottom: 10px;
        //     color: $blue;
        // }

        @media all and (min-width: $desktop) {
          margin-bottom: 24px;
          @include fontsize(20px);
          font-weight: $fw-semibold;
        }
        @media all and (min-width: $desktop-1600) {
          @include fontsize(26px);
          font-weight: 600;
          margin-bottom: 18px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(26px);
          margin-bottom: 26px;
        }
      }

      .card-head {
        display: block;
        margin-bottom: 0;
      }

      .download-wrap {
        border-radius: 50px;
        border: 2px solid $light-green;
        background: $light-green;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        color: $white-color;
        font-family: $primary-fontfamily;
        letter-spacing: 0.15px;
        @include fontsize(14px);
        padding: 9px 12px;
        width: 260px;
        text-align: center;
        margin-right: 15px;
        @media all and (min-width: $desktop){
          margin-left: auto;
          margin-right: 0;
        }

        @media all and (min-width: $small-medium) {
          text-align: left;
          padding: 10px 21px;
          @include fontsize(12px);
          position: relative;
        }
        @media all and (min-width: $desktop) {
          text-align: left;
          padding: 10px 21px;
          @include fontsize(12px);
          position: relative;
          margin-bottom: 11px;
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(16px);
          width: 100%;
          margin-bottom: 15px;
          padding: 10px 15px;
        }

        @media all and (min-width: $desktop-xl) {
          margin-bottom: 24px;
          padding: 10px 18px;
        }

        &:first-of-type {
          margin-left: 0;
          width: 219px;

          @media all and (min-width: $desktop) {
            width: 100%;
          }
        }

        &:last-of-type {
          background-color: $light-green;
          color: $white-color;
          position: relative;
          @media all and (min-width: $desktop) {
            margin-left: auto;
            margin-bottom: 0;
          }
        }
      }

      .download-main-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media all and (min-width: $desktop){
          justify-content: space-between;
          max-width: 500px;
          max-height: 198px;
          overflow: auto;
        }
        @media all and (min-width: $desktop-1600){
          max-height: 230px;
        }
        @media all and (min-width: $desktop-xl){
          max-height: 290px;
        }
        a {
          @media all and (min-width: $desktop) {
            font-weight: 600;
          }
        }
        .pdf-icon {
          background: url(../../../assets/images/adobe.svg) no-repeat;
          width: 20px;
          height: 21px;
          background-size: 20px auto;
          display: block;
          margin-right: 15px;

          @media all and (min-width: $desktop) {
            margin-right: 10px;
          }

          @media all and (min-width: $desktop-1600) {
            width: 18px;
            height: 18px;
            background-size: 18px auto;
          }

          @media all and (min-width: $desktop-xl) {
            width: 25px;
            height: 23px;
            background-size: 25px auto;
          }
        }

        .download-icon {
          background: url(../../../assets/images/download-icon.svg) no-repeat;
          width: 20px;
          height: 21px;
          background-size: 20px auto;
          display: block;
          margin-right: 15px;

          @media all and (min-width: $desktop) {
            margin-right: 10px;
          }

          @media all and (min-width: $desktop-1600) {
            width: 18px;
            height: 18px;
            background-size: 18px auto;
          }

          @media all and (min-width: $desktop-xl) {
            width: 25px;
            height: 23px;
            background-size: 25px auto;
          }
        }

        .arrow-icon {
          background: url(../../../assets/images/arrow-down.svg) no-repeat;
          width: 15px;
          height: 15px;
          background-size: 15px auto;
          display: none;
          margin-left: 15px;

          @media all and (min-width: $desktop) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 9px;
          }

          @media all and (min-width: $desktop-1600) {
            right: 10px;
          }

          @media all and (min-width: $desktop-xl) {
            right: 17px;
            width: 20px;
            height: 20px;
            background-size: 20px auto;
          }
        }

        .arrow-right {
          background: url(../../../assets/images/arrow-rgt.svg) no-repeat;
          width: 15px;
          height: 15px;
          background-size: 15px auto;
          display: block;
          margin-left: 15px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);

          @media all and (min-width: $desktop-xl) {
            right: 17px;
            width: 20px;
            height: 20px;
            background-size: 20px auto;
          }
        }
      }
    }

    @media all and (min-width: $small-medium) {
      margin-bottom: 0 !important;
    }

    .data-main-wrp {
      @media all and (min-width: $small-medium) {
        // justify-content: space-between;
        // display: flex;
        // width: 100%;
      }

      .head-wrp {
        @media all and (min-width: $small-medium) {
          // width: 16.66%;
          // flex-grow: 1;
          // flex-shrink: 0;
          padding-right: 15px;
        }

        @media all and (min-width: $desktop) {
          margin-bottom: 5px;
          width: 129px;
          min-height: 47.4px;
        }

        @media all and (min-width: $desktop-1600) {
          margin-bottom: 30px;
          width: 160px;
        }

        @media all and (min-width: $desktop-xl) {
          margin-bottom: 58px;
        }

        &.person {
          @media all and (max-width: 767px) {
            width: 13%;
          }

          @media all and (min-width: $desktop) {
            width: 50px;
            text-align: right;
          }

          @media all and (min-width: $desktop-1600) {
            width: 65px;
          }

          p {
            text-align: left;
          }
        }

        &.dob {
          @media all and (max-width: 767px) {
            width: 28%;
          }

          @media all and (min-width: $desktop) {
            width: 131px;
          }
          @media all and (min-width: $desktop-xl) {
            width: 140px;
          }
        }

        &.effective-date {
          @media all and (max-width: 767px) {
            width: 27%;
          }

          @media all and (min-width: $desktop) {
            width: 123px;
          }

          @media all and (min-width: $desktop-1600) {
            width: 130px;
          }
          @media all and (min-width: $desktop-xl) {
            width: 150px;
          }
        }

        &.coverage {
          @media all and (max-width: 767px) {
            width: 21%;
          }

          @media all and (min-width: $desktop) {
            width: 90px;
          }

          @media all and (min-width: $desktop-1600) {
            width: 96px;
          }

          @media all and (min-width: $desktop-xl) {
            width: 170px;
          }
        }

        &.grp-no {
          margin-bottom: 0;

          @media all and (min-width: $small-medium) {
            padding-right: 15px;
          }
        }
      }

      .grp-no {
        // display: flex;
        // justify-content: space-between;

        h3 {
          color: $white-color;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: 400;
          text-align: left;
          margin-bottom: 7px;

          @media all and (min-width: $small-medium) {
            @include fontsize(12px);
            margin-bottom: 5px;
          }

          @media all and (min-width: $desktop) {
            @include fontsize(14px);
            margin-bottom: 13px;
          }

          @media all and (min-width: $desktop-1600) {
            @include fontsize(16px);
            margin-bottom: 2px;
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(18px);
          }
        }

        p {
          font-family: $primary-fontfamily;
          @include fontsize(14px);
          text-align: left;
          color: $white-color;
          font-weight: $fw-medium;

          @media all and (min-width: $small-medium) {
            @include fontsize(14px);
          }

          @media all and (min-width: $desktop) {
            @include fontsize(16px);
          }

          @media all and (min-width: $desktop-1600) {
            @include fontsize(18px);
          }

          @media all and (min-width: $desktop-xl) {
            @include fontsize(20px);
          }
        }
      }
    }
  }

  .card-banner {
    padding: 24px 20px 0 20px;
    background-size: cover;
    width: calc(100% + 40px);
    margin-left: -20px;
    position: relative;
    display: flex;
    flex-direction: column;

    @media all and (min-width: $small-medium) {
      background-size: cover;
      width: 100%;
      margin-left: 0;
      border-radius: 8px 8px 40px 0;
      padding: 40px 40px 20px;
    }

    @media all and (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: auto;
      background-size: cover;
      padding: 30px;
    }

    @media all and (min-width: $desktop) {
      padding: 40px 40px 0;
    }

    @media all and (min-width: $desktop-xl) {
      // height: 452px;
      padding: 60px;
    }

    // &::after {
    //     // background: url(../../../assets/images/mob-banner-plan.png) no-repeat;
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 305px;
    //     left: 0;
    //     top: 0;
    //     z-index: -1;
    //     border-radius: 0 0 40px 0;
    //     background-size: cover;

    //     @media all and (min-width:$small-medium) {
    //         display: none;
    //     }
    // }
  }

  .network-block {
    padding-top: 20px;
    background-color: #fff;
  }

  .card-head {
    display: flex;
    justify-content: space-between;

    @media all and (min-width: $small-medium) {
      align-items: flex-start;
    }
  }
}

@media all and (max-width: 767px) {
  .initial-box {
    .head-wrp {
      width: 29%;
      margin-bottom: 31px;

      &.person {
        width: 13%;
      }

      &.grp-one {
        width: 35%;
        margin-bottom: 0;
      }

      &.grp-two {
        width: 40%;
        margin-bottom: 0;
        padding-right: 0;
        margin-right: 46px;
      }
    }
  }
}

.grp-one {
  @media all and (min-width: $desktop) {
    width: 129px !important;
    padding-right: 0 !important;
  }

  @media all and (min-width: $desktop-1600) {
    width: 182px !important;
  }
}

.card-content{
  &.new-card-content{
    padding-top: 20px;
    margin-bottom: 0 !important;
    @media all and (min-width:1200px) {
      padding-top: 0px;
    }
    .head-wrp{
      width: 100%;
      h2 {
        display: block;
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(18px);
        font-weight: $fw-bold;
        text-align: left;
        letter-spacing: 0.15px;
        display: block;
        margin-bottom: 10px;

        @media all and (min-width: $desktop) {
          font-weight: 600;
          margin-bottom: 17px;
          @include fontsize(20px);
        }

        @media all and (min-width: $desktop-1600) {
          @include fontsize(20px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(30px);
        }
      }
    }
    .logo-wrap{
      border-radius: 20px;
      border: 1px solid #C8F7F9;
      background-color: #C8F7F9;
      margin-bottom: 0;
      width: 140px;

      @media all and (min-width:$small-medium) {
        width: 45%;
    }
  
      @media all and (min-width:$desktop) {
          width: 149px;
      }
    }
    .logo-head{
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 20px 20px 0 0;
        @media all and (min-width:$small-medium) {
          height: 70px;
        }
        @media all and (min-width:$desktop) {
          height: 45px;
        }
        img{
            height: 100%;
            display: block;
            max-width: 100%;
        }
        @media all and (min-width:$desktop-1600){
          height: 76px;
        }
    }
  
    .logo-body{
      text-align: center;
      min-height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #240151;
      font-family: "Montserrat";
      font-size: 0.875rem;
      font-weight: 600;
      border-radius: 0 0 20px 20px;
      @media all and (min-width:$desktop) {
        @include fontsize(12px);
        font-family: $primary-fontfamily;
        min-height: 39px;
      }
  }
  }
}

.mobile-id-card{
  margin-bottom: 20px;
  position: relative;
  @media all and (min-width:768px){
    margin-bottom: 0;
  }
  .id-btns{
    display: flex;
    justify-content: space-between;
    @media all and (min-width:768px){
      display: none;
    }
  }
  .download-btn{
    &.btn {
      border: 2px solid $light-green;
      background: #fff;
      color: $light-green;
      &:hover{
        background: $light-green;
        color: #fff;
      }
    }
  }
  .btn{
    line-height: 1;
    width: calc(50% - 10px);
  }
  .img-id-card{
    width: 100%;
    margin-bottom: 20px;
    display: block;
    @media all and (min-width:768px){
      margin-bottom: 0;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
}

.no-docs-text{
  @include fontsize(18px);
  &.mob{
    @include fontsize(10px);
  }
}
.plan-content{
  .card-box.card-one.card-box-main{
    h2{
      @media all and (max-width:768px) {
        display: block;
        font-size: 18px;
        font-weight: 700;
      }
      @media all and (min-width:$desktop) {
        margin: 0 0 17px 0;
      }
      @media all and (min-width:$desktop-1600) {
        margin: 0 0 19px 0;
      }
    }
    .card-content-wrpper{
      &.above-card{
        h2{
          margin: 20px 0 0;
          font-size: 16px;
          font-weight: 700;
          @media all and (min-width:1200px) {
            margin: 20px 0;
          }
          @media all and (min-width:$desktop) {
            font-size: 20px;
            font-weight: 600;
            margin: 20px 0;
          }
          @media all and (min-width:$desktop-1600) {
            font-size: 26px;
            margin: 20px 0;
          }
          @media all and (min-width:$desktop-xl) {
            margin: 20px 0 7px;
          }
        }
      }
      .card-provider-wrp{
        h2{
          @media all and (max-width:767px) {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
.icons-wrap-card{
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: row-reverse;
  bottom: 0;
  left: 0;
  .reverse-icon{
    background: url(../../../assets/images/reverse-icon.svg);
    width: 64px;
    height: 64px;
    display: inline-block;
  }
  .downld-icon{
    background: url(../../../assets/images/downld-icon.svg);
    width: 64px;
    height: 64px;
    display: inline-block;
  }
  .download-card-btn{
    background: transparent
  }
}

