@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.health-plan-wrp {
  .text-dec {
    @media all and (max-width: 767px) {
      text-decoration: underline;
    }
  }

  &.paln-wrp {
    @media all and (max-width: 767px) {
      padding-left: 20px;
      position: relative;
    }

    &::after {
      @media all and (max-width: 767px) {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #240151;
      }
    }

    @media all and (min-width: $small-medium) {
      border-radius: 8px;
      border: 1px solid #ececec;
      box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
      background: #fff;
      margin-bottom: 26px;
      border-top: 0;
    }

    @media all and (min-width: $desktop) {
      height: 100%;
    }

    h3 {
      text-align: left;
      @include fontsize(18px);
      font-weight: $fw-bold;
      margin-bottom: 17px;

      @media all and (min-width: $small-medium) {
        color: $blue;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: $fw-semibold;
        letter-spacing: 0.15px;
        margin-bottom: 0;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(20px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(26px);
      }
    }

    h4 {
      text-align: left;
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      margin-bottom: 10px;

      @media all and (min-width: $small-medium) {
        @include fontsize(10px);
        margin-bottom: 8px;
      }

      @media all and (min-width: $desktop) {
        @include fontsize(14px);
        margin-bottom: 6px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(16px);
      }
    }

    .progress-value {
      display: flex;
      justify-content: space-between;

      .value {
        @include fontsize(16px);
        color: $blue;
        font-family: $primary-fontfamily;
        font-weight: $fw-semibold;
        letter-spacing: 0.15px;

        @media all and (min-width: $small-medium) {
          @include fontsize(14px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(18px);
          font-weight: $fw-regular;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(22px);
        }
      }
    }

    .progress-wrp {
      border-radius: 50px;
      background: rgba(40, 22, 81, 0.05);
      height: 12px;
      margin-bottom: 12px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 8px;
      }
      @media all and (min-width: $desktop) {
        margin-bottom: 11px;
      }
    }

    .actual-progress {
      background: #18d6dc;
      border-radius: 50px;
      height: 12px;
    }

    .progress-left {
      margin-bottom: 22px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 20px;
      }
      &.deductible{
        margin-bottom: 30px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 0px;
      }
      }
    }

    .progress-right {
      margin-bottom: 56px;

      @media all and (min-width: $small-medium) {
        margin-bottom: 24px;
      }
    }

    .health-plan-head {
      @media all and (min-width: $small-medium) {
        padding: 20px;
        border-radius: 8px 8px 0 0;
        background: none;
      }

      @media all and (min-width: $small-medium) {
        padding: 20px 17px 20px 17px;
      }

      @media all and (min-width: $desktop) {
        padding: 17px;
      }
    }

    .health-body {
      @media all and (min-width: $small-medium) {
        padding: 22px 18px;
      }

      @media all and (min-width: $desktop) {
        padding: 25px 18px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 37px 18px;
      }
    }

    .progress-main {
      @media all and (min-width: $small-medium) {
        display: flex;
        justify-content: space-between;
        border-bottom: 0;
        padding-bottom: 20px;
        // border-bottom: 1px solid #CFCCD3;
        margin-bottom: 20px;
      }

      @media all and (min-width: $desktop) {
        padding-bottom: 22px;
        flex-direction: column;
        width: 36%;
        border-bottom: 0;
        padding-top: 6px;
        margin-bottom: 0px;
        justify-content: space-evenly;
      }
    }

    .progress-bar {
      @media all and (min-width: $small-medium) {
        width: 47.5%;
        margin-bottom: 0;
        padding: 11px 0;
      }
      @media all and (min-width: $desktop) {
        width: 100%;
        margin-bottom: 0;
      }

      // @media all and (min-width:$desktop) {
      //     margin-bottom: 38px;
      // }
    }
  }
}

.care-data {
  .tab-content {
    @media all and (min-width: $small-medium) {
      padding: 30px 35px 30px 30px;
      border-radius: 8px;
      background: #f9f9f9;
      min-height: calc(100vh - 414px) !important;
    }

    @media all and (min-width: $desktop) {
      padding: 30px 40px 20px;
      min-height: calc(100vh - 450px) !important;
    }

    @media all and (min-width: $desktop-xl) {
      min-height: calc(100vh - 270px) !important;
    }
  }
}

.benefit-summary-tab {
  .main-netwrk {
    @media all and (max-width: 767px) {
      padding: 0;
      background: transparent;
      padding-left: 20px;
      position: relative;
    }

    @media all and (min-width: $small-medium) {
      margin-bottom: 0;
    }

    &::after {
      @media all and (max-width: 767px) {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: #240151;
      }
    }

    .tab-wrp {
      > h3 {
        display: none;

        @media all and (max-width: 767px) {
          display: block;
          color: #281651;
          font-family: $primary-fontfamily;
          @include fontsize(16px);
          font-style: normal;
          font-weight: $fw-bold;
          letter-spacing: 0.15px;
          text-align: left;
          margin-bottom: 0;
        }

        .text-dec {
          @media all and (max-width: 767px) {
            text-decoration: underline;
          }
        }
      }
    }

    &:hover {
      border: 1px solid transparent;
    }

    .network-wrp {
      @media all and (max-width: 767px) {
        background: transparent;
      }
    }

    .tab-head {
      @media all and (max-width: 767px) {
        justify-content: flex-start;
        padding: 12px 0 30px;
      }
    }

    .inner-netwrk {
      @media all and (max-width: 767px) {
        padding: 0;
      }
    }

    .out-network {
      @media all and (max-width: 767px) {
        padding: 0;
      }
    }

    .health-range {
      @media all and (max-width: 767px) {
        padding: 0;
      }
    }

    .tab-wrp {
      @media all and (max-width: 767px) {
        padding: 0;
        box-shadow: none;
      }
    }
  }

  .tab-text {
    &.active {
      @media all and (max-width: 767px) {
        background: #281651;
      }
    }

    @media all and (max-width: 767px) {
      color: #ffffff;
      font-family: "Assistant", sans-serif;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.15px;
      font-size: 0.875rem;
      border: 0;
      margin-bottom: 0 !important;
      margin-right: 6px;
      min-width: 120px;
      padding: 13px 19px;
      border-radius: 50px;
      background: #dddbe0;
    }
  }

  .tab-wrp {
    @media all and (max-width: 767px) {
      box-shadow: 0px 0px 17px 7px rgb(0 0 0 / 4%);
      border-radius: 9px;
    }

    .in {
      @media all and (max-width: 767px) {
        display: none;
      }
    }

    .out {
      @media all and (max-width: 767px) {
        display: none;
      }
    }

    .active {
      @media all and (max-width: 767px) {
        display: block;
      }
    }
  }

  .tab-head {
    display: none;

    @media all and (max-width: 767px) {
      display: flex;
      justify-content: center;
      padding: 26px 0;
    }
  }
}
