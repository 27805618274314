@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.verify-email {
}
.timer-new {
  background: #221f20;
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 10px;
  @media all and (min-width: $small-medium) {
    margin-bottom: 12px;
  }
  .show-count {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.5px;
    color: #fff;
    padding: 10px 20px;
    display: block;
    @media all and (min-width: $small-medium) {
      font-size: 16px;
      font-weight: 600;
      padding: 17.3px 28.3px;
    }
  }
}
