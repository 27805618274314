@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.signup-wrp {
  .btn-edit{
    @media all and (max-width: 767px) {
      .btn:hover {
        background: #240151;
        border: 1px solid #240151;
        color: #fff;
        i{
          svg{
            path{
              fill: #fff !important;
            }
          }
        }
    }
    }
  }
  .signin-wrp {
    @media all and (min-width: $desktop) {
      padding-top: 80px;

      h1 {
        // @include fontsize(30px);
        line-height: 1.1;
      }

      p {
        margin-bottom: 20px;
        @media all and (min-width: $desktop) {
          margin-bottom: 70px;
        }
      }
    }

    @media all and (min-width: 1700px) {
      padding-top: 60px;
    }

    @media all and (min-width: $desktop-xl) {
      padding-top: 60px;
    }

    h1 {
      @media all and (min-width: 1700px) {
        // @include fontsize(44px);
        margin-bottom: 15px;
      }
    }

    p {
      @media all and (min-width: 1700px) {
        margin-bottom: 45px;
      }
    }
  }

  .right-signin-wrp {
    position: relative;
    min-height: 100vh;
    height: auto;
  }

  .bck-btn-wrap {
    position: absolute;
    top: 25px;
    right: 21px;
    display: flex;
    @include fontsize(16px);
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    align-items: center;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
      top: 55px;
      right: 0;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
      top: 43px;
      right: -50px;
    }

    @media all and (min-width: $desktop-1440) {
      right: -57px;
      top: 82px;
    }

    @media all and (min-width: 1700px) {
      top: 80px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
      top: 80px;
      // right: -188px;
    }

    .icon-back {
      background: url(../../../assets/images/back.svg) no-repeat;
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      display: block;
      margin-right: 5px;
      margin-top: 0px;

      @media all and (min-width: $small-medium) {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        margin-top: 0px;
      }

      @media all and (min-width: $desktop) {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
      }

      @media all and (min-width: $desktop-xl) {
        width: 26px;
        height: 17px;
        background-size: 26px auto;
      }
    }
  }

  .form-display-wrap {
    @media all and (min-width: $small-medium) {
      display: flex;
      justify-content: space-between;
    }

    .form-block {
      @media all and (min-width: $small-medium) {
        width: 48%;
      }
    }
  }

  // p {
  //     @media all and (min-width:$desktop-1440) {
  //         margin-bottom: 112px;
  //     }

  //     @media all and (min-width:$desktop-xl) {
  //         margin-bottom: 85px;
  //     }
  // }

  .signin-wrp {
    @media all and (min-width: $desktop) {
      padding-bottom: 50px;
    }
  }
  .no-account.agreed {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $blue;
    position: relative;
    font-family: $primary-fontfamily;
    font-weight: $fw-semibold;
    @include fontsize(14px);
    text-align: start;

    @media all and (min-width: $small-medium) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop) {
      letter-spacing: normal;
      @include fontsize(16px);
      margin-left: 0;
      margin-bottom: 36px;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
    .abs-error{
      position: absolute;
      top: 100%;
      left: 0;
      @media all and (min-width: $desktop){
        transform: translateY(10px);
      }
    }
    input[type="checkbox"] {
      appearance: auto;
      width: 18px;
      height: 18px;
      display: inline-block;
      @media all and (min-width: $desktop) {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.popup{
  &.terms-popup{
    .poup-content{
      display: block;
      @media all and (max-width: 767px){
        padding: 44px 12px;
        width: calc(100% - 20px);
        max-width: 100%;
      }
      @media all and (min-width: $desktop) {
        max-width: 70vw;
        min-height: 80vh;
      }
      .close-popup{
        display: block;
        @media all and (max-width: 767px){
          right: 31px;
        }
      }
      .terms-content{
        margin-top: 30px;
        padding: 0 20px;
        width: 100%;
        max-height: calc(80vh - 118px);
        overflow: auto;
        h1{
          text-align: left;
          @include fontsize(23px);
        }
        p{
          text-align: left;
          width: 100%;
          max-width: 100%;
          line-height: 1.2;
          @include fontsize(16px);
        }
      }
    }
  }
}