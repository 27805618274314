@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";


.loading-screen {
    @media all and (min-width:$small-medium) {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    header {
        height: 58px;
        background: #f2f2f2;
        opacity: 0.5;
        // margin-bottom: 27px;
        position: static;

        @media all and (min-width:$small-medium) {
            opacity: 1;
            // background: #DEDEDE;
            height: 72px;
            flex-shrink: 0;
        }

        @media all and (min-width:$desktop) {
            height: 100px;
        }

        .container {
            @media all and (min-width:$small-medium) {
                padding: 0;
            }
        }

        @media all and (min-width:$small-medium) {
            // background: #BDBDBD;
            padding: 16px 40px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

        }

        .left-block {
            border-radius: 6px;
            background: #fff;
            opacity: 0.5;
            height: 24px;
            width: 79px;

            @media all and (min-width:$small-medium) {
                opacity: 1;
                height: 36px;
                width: 90px;
            }

            @media all and (min-width:$small-medium) {

                width: 95px;
            }
        }

        .right-block {
            @media all and (min-width:$small-medium) {

                border-radius: 6px;
                background: #fff;
                width: 28px;
                height: 28px;

            }

            @media all and (min-width:$small-medium) {
                width: 634px;
                height: 26px
            }


        }
    }

    .content {

        .container {
            @media all and (min-width:$small-medium) {
                padding: 0;
            }
        }

        @media all and (min-width:$small-medium) {
            background: #BDBDBD;
            padding: 20px 40px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

        }


        .content-inner {
            background-color: $white-color;
            padding: 27px 0;
            height: 100%;
            border-radius: 8px;

            @media all and (min-width:$small-medium) {
                padding: 40px;
                display: flex;
                flex-direction: column;
            }

            @media all and (min-width:$desktop) {
                padding: 45px;

            }
        }


        .left-block {
            border-radius: 6px;
            background: #D9D9D9;
            opacity: 0.5;
            height: 43px;
            width: 178px;

            @media all and (min-width:$small-medium) {
                opacity: 1;
                height: 52px;
                width: 148px;
            }

            @media all and (min-width:$small-medium) {

                width: 195px;
            }
        }

        .right-block {
            @media all and (min-width:$small-medium) {

                border-radius: 6px;
                background: #D9D9D9;
                width: 135.958px;
                height: 15.542px;

            }

            @media all and (min-width:$small-medium) {
                width: 174px;
            }


        }
    }

    .info-head {
        margin-bottom: 42px;

        @media all and (min-width:$small-medium) {
            margin-bottom: 60px;
        }

        .container {
            padding: 0;

            @media all and (min-width:$small-medium) {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

            }


        }
    }

    .id-info {
        margin-bottom: 25px;

        @media all and (min-width:$small-medium) {
            margin-bottom: 60px;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0;


        }

        .left-block {
            border-radius: 6px;
            background: #D9D9D9;
            opacity: 0.5;
            height: 60px;
            width: 47%;

            @media all and (min-width:$small-medium) {
                opacity: 1;
                width: 19%;
                height: 52px;
            }

            @media all and (min-width:$desktop) {
                width: 13%;
                height: 76px;
            }

        }

        .middle-block {
            @media all and (min-width:$small-medium) {
                border-radius: 6px;
                background: #D9D9D9;
                opacity: 1;
                height: 52px;
                width: 25%;
            }

            @media all and (min-width:$desktop) {
                height: 76px;
                width: 17%;
            }
        }

        .right-block {
            border-radius: 6px;
            background: #D9D9D9;
            opacity: 0.5;
            height: 60px;
            width: 47%;

            @media all and (min-width:$small-medium) {
                opacity: 1;
                width: 49%;
                height: 52px;
            }

            @media all and (min-width:$desktop) {
                width: 62%;
                height: 76px;
            }
        }
    }

    .banner-wrap {
        margin-bottom: 25px;

        @media all and (min-width:$small-medium) {
            display: none;
        }

        .banner-block {
            height: 60px;
            border-radius: 6px;
            background: #D9D9D9;
            opacity: 0.5;

            @media all and (min-width:$small-medium) {
                display: none;
            }
        }

        .container {
            padding: 0;
        }
    }

    .quick-access {
        .container {
            padding: 0;

            @media all and (min-width:$small-medium) {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        @media all and (min-width:$small-medium) {
            flex-grow: 1;
        }
    }

    .quick-access-box {
        padding: 15px 27.5px;
        height: 296px;
        border-radius: 6px;
        background: rgba(217, 217, 217, 0.5);
        text-align: center;

        @media all and (min-width:$small-medium) {
            border-radius: 8px;
            background: #F7F7F7;
            height: auto;
            padding: 40px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

        }

        .wait-box {
            .sub-text {
                display: block;
            }

            @media all and (min-width:$small-medium) {
                height: 406px;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(217, 217, 217, 1);
                margin-bottom: 36px;
            }

            @media all and (min-width:$desktop) {
                width: 65%;

            }

            @media all and (min-width:$desktop-1440) {
                height: 535px;
            }

            p {
                line-height: 1.5;
            }
        }



        p {
            margin-bottom: 20px;
            @include fontsize(14px);


            @media all and (min-width:$desktop) {
                position: fixed;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 99;

            }

            @media all and (min-width:$small-medium) {
                @include fontsize(18px);
            }

            @media all and (min-width:$desktop) {
                @include fontsize(20px);
            }

            @media all and (min-width:$desktop-xl) {
                @include fontsize(30px);
            }
        }

        .sub-text {
            display: block;
        }

        .quick-line {
            height: 8px;
            border-radius: 6px;
            margin-bottom: 72px;
            background-color: rgba(217, 217, 217, 1);
            display: flex;


            @media all and (min-width:$small-medium) {
                padding: 20px;

                &.line-one {
                    display: none;
                }

                margin-bottom: 36px;
                height: 380.265px;
            }

            @media all and (min-width:$desktop) {
                height: 406px;
                width: 30%;
                padding: 40px;
            }

            @media all and (min-width:$desktop-1440) {
                height: 535px;

            }

            .quick-btn {
                width: 186px;
                margin: 0 auto;
                height: 38px;
                border-radius: 10px;
                background: linear-gradient(0deg, #BDBDBD 0%, #BDBDBD 100%), linear-gradient(265deg, #C7FFF7 -22.3%, #FFF229 100%);
                margin-top: auto;
            }
        }

        .btn-box {
            border-radius: 6px;
            background: #BDBDBD;
            height: 42px;
            opacity: 0.5;
            margin-bottom: 6px;

            @media all and (min-width:$small-medium) {
                height: 244.01px;
                flex-grow: 1;
            }

            @media all and (min-width:$desktop) {
                margin-bottom: 40px;
                height: 333px;
            }
        }

        .quick-flow {
            @media all and (min-width:$desktop) {
                display: flex;
                justify-content: space-between;
            }
        }

    }

    p {
        color: $blue;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.25px;
    }

    .footer-banner {
        display: none;

        @media all and (min-width:$desktop) {
            display: block;
            background-color: rgba(217, 217, 217, 1);
            margin: 0 auto;
            width: 806.5px;
            height: 126.38px;
        }

    }

}