@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 56px;
  border-top: 1px solid #ECECEC;
  background: #FFF;
  padding: 8px 30px;
  z-index: 99999;
  @media all and (max-width: 350px) {
    padding: 8px 10px;
  }
  @media all and (min-width:$small-medium) {
    display: none;
  }

  .fixed-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      line-height: 1.2;

      &:hover {
        i {
          svg {
            fill: rgba(199, 255, 247, 1);

            path {
              stroke: #281651;
            }
          }

          &.member {
            svg {
              fill: transparent;

              path {
                stroke: #281651;

                &:first-child {
                  fill: rgb(199, 255, 247);
                }
              }
            }

          }
        }
      }

      &.active {
        i {
          svg {
            fill: #D2F3FF;

            path {
              stroke: #281651;
            }
          }
        }
      }

      a {
        color: #BBB8C1;
        font-family: $primary-fontfamily;
        @include fontsize(10px);
        font-weight: $fw-semibold;
        letter-spacing: 0.5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      i {
        margin-bottom: 5px;
      }

      svg {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
  }

  .icon-card {
    width: 26px;
    height: 26px;
  }

  .icon-provider {
    width: 26px;
    height: 26px;
  }

  .icon-home {
    width: 26px;
    height: 26px;
  }

  .my-care {
    width: 26px;
    height: 26px;
  }

  .member {
    width: 26px;
    height: 26px;
  }
}