@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

//acc
.change-password {
  @media all and (min-width: $small-medium) {
    position: relative;
  }
  .toggle-container {
    display: flex;
    align-items: center;
    p{
      margin: 0 0 0 11px;
      width: auto;
    }
    .toggle-button {
      width: 60px;
      height: 30px;
      background-color: #ccc;
      border-radius: 30px;
      position: relative;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &.toggled{
        background-color: #240151;
        .toggle-circle {
          left: 32px;
        }
      }
    }
    
    .toggle-circle {
      width: 26px;
      height: 26px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: left 0.3s ease;
    }
  }
  

  .form-block {
    @media all and (min-width: $desktop) {
      width: 100%;
    }
  }

  .change-pass-head {
    display: none;
    @media all and (min-width: $small-medium) {
      display: block;
    }
  }
  .bck-btn-wrap {
    position: absolute;
    top: 60px;
    transform: translateY(-50%);
    right: 40px;
    @include fontsize(10px);
    font-family: $primary-fontfamily;
    display: none;

    @media all and (min-width: $small-medium) {
      display: flex;
      align-items: center;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(14px);
    }

    .icon-back {
      background: url(../../../assets/images/back.svg) no-repeat;
      width: 14px;
      height: 9px;
      background-size: 14px auto;
      display: block;
      margin-right: 5px;
      margin-top: 4px;

      @media all and (min-width: $small-medium) {
        width: 12px;
        height: 8px;
        background-size: 12px auto;
        margin-top: 2px;
      }

      @media all and (min-width: $desktop) {
        width: 17px;
        height: 12px;
        background-size: 17px auto;
      }

      @media all and (min-width: $desktop-xl) {
        width: 26px;
        height: 17px;
        background-size: 26px auto;
      }
    }
  }

  &.account-resource-content {
    @media all and (min-width: $small-medium) {
      padding: 40px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 60px;
    }

    .form-main-block {
      @media all and (min-width: $small-medium) {
        padding: 0;
      }
    }
  }

  .form-main-block {
    padding: 20px 20px 62px 20px;

    @media all and (min-width: $small-medium) {
      max-width: 666px;
      margin: 0 auto;
    }
  }

  h2 {
    color: #281651;
    font-family: $primary-fontfamily;
    @include fontsize(20px);
    font-weight: $fw-semibold;
    margin-bottom: 10px;
    margin-bottom: 40px;

    @media all and (min-width: $small-medium) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(24px);
    }
  }

  p {
    color: #281651;
    font-family: $primary-fontfamily;
    @include fontsize(13px);
    font-weight: $fw-regular;
    letter-spacing: 0.25px;
    margin-bottom: 20px;

    @media all and (min-width: $small-medium) {
      @include fontsize(12px);
      font-family: $primary-fontfamily;
      font-weight: $fw-semibold;
      width: 64%;
      letter-spacing: 0.25px;
    }

    @media all and (min-width: $desktop) {
      width: 100%;
      @include fontsize(16px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
    }
  }

  .update-wrp {
    @media all and (min-width: $small-medium) {
      // max-width: 410px;
      margin: 0 auto;
    }

    .btn {
      height: auto;
      margin-top: 50px;
      position: relative;

      @media all and (min-width: $small-medium) {
        margin-top: 20px;
      }

      @media all and (min-width: $desktop) {
        padding: 20px;
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(24px);
      }

      .update-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 34px;

        @media all and (min-width: $small-medium) {
          right: 40px;
        }

        @media all and (min-width: $desktop) {
          height: 32px;
          width: 32px;
        }

        svg {
          @media all and (min-width: $small-medium) {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }

      @media all and (min-width: $desktop-xl) {
        margin-bottom: 17px;
      }

      &:hover {
        .update-icon {
          svg {
            width: 100%;

            path {
              fill: $blue;
            }
          }
        }
      }
    }
  }

  .pass-wrp {
    .input-block {
      position: relative;

      .icon-eye {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        background: url(../../../assets/images/forget-pass-eye.svg) no-repeat;
        width: 18px;
        height: 14px;
        background-size: 18px auto;
        cursor: pointer;

        @media all and (min-width: $small-medium) {
          width: 20px;
          height: 13px;
          background-size: 20px auto;
          right: 20px;
        }

        // @media all and (min-width:$desktop) {
        //     width: 32px;
        //     height: 22px;
        //     background-size: 32px auto;
        //     right: 20px;
        // }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          margin: 0 auto;
          height: 20px;
          width: 1.3px;
          background-color: #989898;
          transform: translateY(-50%) rotate(45deg);

          //   @media all and (min-width: $desktop) {
          //     height: 30px;
          //   }
        }

        &.active {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
