@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";

.benefits-wrap {
  background: #e9e8ee;
  .footer {
    @media all and (min-width: $medium) {
      margin-left: 40px;
      margin-right: 40px;
    }
    @media all and (min-width: $desktop-xl) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
  .header-banner {
    min-height: 250px;
    background: url("../../assets/images/banner-background.png");
    background-size: cover;
    padding: 84px 20px;
    display: none;

    @media all and (min-width: $small-medium) {
      padding: 60px 10px;
      min-height: 350px;
      display: block;
    }
    @media all and (min-width: $desktop) {
      padding: 44px 84px;
      
    }
    .container {
      padding: 20px 0px;
      @media all and (min-width: 768px) {
        padding: 20px 10px 0;
      }
      @media all and (min-width: 853px) {
        padding: 20px 30px 0;
      }
      @media all and (min-width: 1160px) {
        padding: 20px 38px 0;
      }
      @media all and (min-width: 1280px) {
        padding: 20px 0 0;
      }
    }
    h1 {
      @include fontsize(24px);
      font-weight: $fw-regular;
      font-family: $primary-fontfamily;
      color: $white-color;

      @media all and (min-width: $small-medium) {
        font-weight: $fw-semibold;
        @include fontsize(30px);
      }
    }
  }
  .card-headings {
    background-color: $light-green;
    padding: 14px 0px;
    position: relative;
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      align-items: center;

      @media all and (min-width: $small-medium) {
        padding: 0 24px;
      }
    }
    span {
      display: block;
    }
    .card-head-text {
      color: $white-color;
      @include fontsize(18px);
      font-weight: $fw-medium;
      font-family: $primary-fontfamily;
      display: block;
      margin: 0 auto;
      padding-right: 30px;

      @media all and (min-width: $small-medium) {
        @include fontsize(30px);
        font-weight: $fw-semibold;
        color: $light-green;
      }
    }
    .back-dots-wrap {
      border: 1px solid #fff;
    }
    .back-heading {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      @media all and (min-width: $small-medium) {
        display: none;
      }
    }
    .card-back-icon {
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/card-back.svg");
      background-size: 28px 28px;
    }
    .heading-dot {
      width: 4px;
      height: 4px;
      border: 1px solid #fff;
      border-radius: 50%;
    }
    .medium-card-back {
      display: none;
      align-items: center;
      gap: 8px;
      @include fontsize(16px);
      font-weight: $fw-semibold;
      border: none;
      outline: none;
      background: none;

      .medium-card-icon {
        display: block;
        width: 17px;
        height: 14px;
        background: url("../../assets/images/back.svg") no-repeat;
        background-size: 17px 14px;
      }
      @media all and (min-width: $small-medium) {
        display: flex;
      }
    }
    @media all and (min-width: $small-medium) {
      display: none;
    }
  }
  .external-link {
    color: $light-green;
    font-family: $primary-fontfamily;
    @include fontsize(12px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media all and (min-width: $small-medium) {
      @include fontsize(12px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(16px);
      display: none;
    }

    @media all and (min-width: $desktop-1440) {
      display: block;
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
      margin-bottom: 20px;
    }
  }

  .benefit-data-wrap {
    @media all and (min-width: $desktop) {
      display: flex;
      justify-content: space-between;
    }

    .copayment-table {
      @media all and (min-width: $desktop) {
        width: 100%;
        padding-right: 0px;
      }
    }

    .benefit-popup {
      @media all and (min-width: $desktop) {
        width: 26.5%;
      }

      @media all and (min-width: $desktop-1440) {
        width: 29.5%;
      }

      @media all and (min-width: $desktop-xl) {
        width: 35.5%;
      }
    }
  }

  .btn-wrp {
    @media all and (min-width: $desktop) {
      display: flex;
      justify-content: center;
    }
  }

  .popup-wrap {
    @media all and (min-width: $small-medium) {
      height: 100%;
    }

    .poup-content {
      @media all and (min-width: $small-medium) {
        max-width: none;
        min-height: none;
        border-radius: 0;
        border: 0;
        background: transparent;
        display: block;
        padding: 0;
        width: 100%;
        margin: 0;
        box-shadow: none;
      }

      @media all and (min-width: $small-medium) {
        height: 100%;
      }
    }
  }

  .benefit-popup {
    display: none;

    @media all and (min-width: $small-medium) {
      display: block;
      position: static;
      height: auto;
      width: auto;
      background: transparent;
    }

    &.active {
      display: flex;
    }

    &::after {
      background-color: rgba(236, 236, 236, 0.8);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 25px;
      height: 25px;
      background: transparent;

      @media all and (min-width: $small-medium) {
        display: none;
      }

      .icon-close {
        background: url(../../assets/images/popup-close.svg) no-repeat;
        width: 25px;
        height: 25px;
        background-size: 25px auto;
        margin-bottom: 0;
      }
    }
  }

  .container {
    padding: 0;

    @media all and (min-width: $small-medium) {
      padding: 20px 40px 0;
    }

    @media all and (min-width: $desktop) {
      padding: 20px 34px 0px;
    }
  }

  .search-main-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
    row-gap: 20px;

    @media all and (min-width: $small-medium) {
      margin-bottom: 38px;
      flex-direction: row;
      align-items: center;
    }

    @media all and (min-width: $desktop) {
      margin-bottom: 42px;
    }
  }

  .benefits-card {
    background: $white-color;
    padding-bottom: 15px;
    @media all and (min-width: $small-medium) {
      border-radius: 30px 30px 0 0;
    }
  }
  .benefits-data {
    padding-top: 30px;
    margin: 0px 20px 10px;
    position: relative;
    border-bottom: 2px solid #eaeaea;
    @media all and (min-width: $small-medium) {
      padding-top: 40px;
      margin: 0px 40px 10px;
    }

    @media all and (min-width: $desktop-xl) {
      padding-top: 60px;
      margin: 0px 60px 10px;
    }
  }

  .bck-btn-wrap {
    @include fontsize(14px);
    font-family: $primary-fontfamily;
    display: none;

    @media all and (min-width: $small-medium) {
      @include fontsize(10px);
      display: flex;
    }

    @media all and (min-width: $desktop) {
      @include fontsize(14px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(20px);
      top: 99px;
      right: 60px;
    }

    .icon-back {
      background: url(../../assets/images/back.svg) no-repeat;
      width: 14px;
      height: 9px;
      background-size: 14px auto;
      display: block;
      margin-right: 5px;
      margin-top: 4px;

      @media all and (min-width: $small-medium) {
        width: 12px;
        height: 8px;
        background-size: 12px auto;
        margin-top: 2px;
      }

      @media all and (min-width: $desktop) {
        width: 17px;
        height: 12px;
        background-size: 17px auto;
      }

      @media all and (min-width: $desktop-xl) {
        width: 25px;
        height: 20px;
        background-size: 25px auto;
      }
    }
  }

  .search-wrp {
    border-radius: 50px;
    border: 0.1rem solid $light-green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0;
    width: 100%;

    @media all and (min-width: $small-medium) {
      width: 45%;
    }

    // @media all and (min-width: $desktop) {
    //   width: 55.5%;
    // }

    // @media all and (min-width: $desktop-1440) {
    //   width: 48.5%;
    // }

    .search-input {
      padding: 13px 64px 13px 20px;
      width: 100%;
      height: 100%;
      border-radius: 50px;

      @media all and (min-width: $small-medium) {
        padding: 14px 45px 14px 20px;
      }
      @media all and (min-width: $desktop) {
        padding: 14px 71px 14px 20px;
      }

      @media all and (min-width: $desktop-xl) {
        padding: 20px 60px 20px 20px;
      }
    }

    input {
      color: $blue;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: $fw-semibold;
      border: 1px solid transparent;

      @media all and (min-width: $small-medium) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(20px);
      }

      &::placeholder {
        color: #b3b3b3;
        font-family: $primary-fontfamily;
        @include fontsize(14px);
        font-weight: $fw-semibold;

        @media all and (min-width: $small-medium) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }
    }
  }

  .copayment-table {
    margin-bottom: 10px;
    @media all and (min-width: $small-medium) {
      margin-bottom: 20px;
    }
  }
  .benifit-search-wrap {
    @media all and (min-width: $small-medium) {
      margin-top: -216px;
    }
    .container{
      @media all and (min-width: $small-medium) {
        padding: 20px 20px 0;
      }
      @media all and (min-width: 853px) {
        padding: 20px 40px 0;
      }
      @media (min-width: 1280px) and (max-width: 1299px) {
        padding: 20px 22px 0;
      }
      @media (min-width: 1300px) and (max-width: 1315px){
        padding: 20px 15px 0;
      }
      @media (min-width: 1316px) and (max-width: 1439px){
        padding: 20px 8px 0;
      }
      @media (min-width: 1440px) and (max-width: 1450px){
        padding: 20px 25px 0;
      }
      @media (min-width: 1451px) and (max-width: 1479px){
        padding: 20px 18px 0;
      }
      @media all and (min-width: 1480px) {
        padding: 20px 0px 0;
      }
    }
  }

  .copayment-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-left: auto;
    flex-direction: row-reverse;
    width: 249px;
    padding: 4px 4px 4px 18px;
    background: #c8f7f9;
    border-radius: 50px;

    @media all and (min-width: $small-medium) {
      width: 307px;
      margin-bottom: 0px;
      padding: 4px 18px 4px 4px;
      flex-direction: row;
    }

    .info-wrp {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $light-green;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .info-icon {
      background: url(../../assets/images/copayment-icon.svg);
      height: 28px;
      width: 28px;
      display: block;
      background-size: 28px auto;
    }

    p {
      @include fontsize(12px);
      font-weight: $fw-semibold;
      color: $light-green;
      @media all and (min-width: $small-medium) {
        @include fontsize(16px);
      }
      @media all and (min-width: $desktop) {
        @include fontsize(20px);
      }
    }
  }

  .copayment-summary {
    border: 1px solid #d4d4d4;
    border-radius: 8px;

    // @media all and (min-width:$small-medium) {
    //     height: 100%;
    // }

    .copayment-head {
      padding: 10px 20px 0;

      @media all and (min-width: $small-medium) {
        padding: 20px;
        background: #fcfcfc;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #d4d4d4;
      }
    }

    .copayment-content {
      padding: 20px;

      @media all and (min-width: $desktop) {
        padding: 40px 22px 20px 25px;
      }

      @media all and (min-width: $desktop-1440) {
        padding: 29px 24px 35px 18px;
      }

      @media all and (min-width: $desktop-1440) {
        padding: 45px 24px 35px 18px;
      }

      .external-page {
        color: #00d0be;
        display: block;
      }

      p {
        color: #9c9c9c;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
        text-align: left;
        max-width: none;

        &:nth-of-type(3) {
          margin-bottom: 0;

          @media all and (min-width: $desktop) {
            display: none;
          }

          @media all and (min-width: $desktop-1440) {
            display: block;
          }
        }

        &:last-of-type {
          display: block;

          @media all and (min-width: $desktop-xl) {
            display: block;
          }
        }

        @media all and (min-width: $desktop) {
          @include fontsize(16px);
          margin-bottom: 23px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
        }
      }

      .btn {
        padding: 12px;
        display: inline-block;
        width: 183px;
        height: auto;
        @include fontsize(14px);
        margin-top: 20px;
        background: #240151;

        &:hover {
          background: transparent;
        }

        @media all and (min-width: $desktop) {
          margin-top: 40px;
          @include fontsize(16px);
          width: 200px;
        }

        @media all and (min-width: $desktop-1440) {
          margin-top: 53px;
        }

        @media all and (min-width: $desktop-xl) {
          @include fontsize(20px);
          width: 300px;
          margin-top: 25px;
        }
      }
    }

    h2 {
      color: $blue;
      text-align: left;
      font-family: $primary-fontfamily;
      @include fontsize(14px);
      font-weight: $fw-bold;
      letter-spacing: 0.15px;

      @media all and (min-width: $desktop) {
        @include fontsize(20px);
      }

      @media all and (min-width: $desktop-xl) {
        @include fontsize(22px);
      }
    }
  }
}

//pagination
.benefit-data-wrap {
  .pagintion {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
    }

    .pagination-list {
      display: flex;

      li {
        // width: 20px;
        // height: 20px;
        // border-radius: 50%;
        // border: 1px solid #bbb8c1;
        // margin-right: 4px;
        // display: flex;
        // align-items: center;
        // cursor: pointer;
        // justify-content: center;

        @media all and (min-width: $small-medium) {
          //   width: 26px;
          //   height: 26px;
          margin-right: 6px;
        }

        @media all and (min-width: $desktop) {
          //   width: 28px;
          //   height: 28px;
        }

        a {
          color: #9f9f9f;
          font-family: $primary-fontfamily;
          @include fontsize(12px);
          font-weight: $fw-semibold;
          width: auto;
          padding: 10px;
          height: 20px;
          display: flex;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid #9f9f9f;
          margin-right: 4px;

          @media all and (min-width: $desktop) {
            width: auto;
            height: 28px;
            font-size: 0.875rem;
            padding: 10px;
            border-radius: 4px;
          }
        }

        &.selected {
          a {
            border: 1px solid $light-green;
            color: #fff;
            background: $light-green;
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        &.previous {
          a {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              top: 50%;
              background: url(../../assets/images/arrow-right.svg) no-repeat;
              width: 6px;
              height: 10px;
              background-size: 6px auto;
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        &.next {
          a {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              top: 50%;
              width: 6px;
              height: 10px;
              background: url(../../assets/images/arrow-right.svg) no-repeat;
              background-size: 6px auto;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .entry-wrp {
      display: flex;
      align-items: center;
      flex: none;

      @media all and (min-width: $small-medium) {
        margin-right: 40px;
      }
    }

    .entry-text {
      color: $blue;
      text-align: center;
      font-family: $primary-fontfamily;
      @include fontsize(12px);
      font-weight: $fw-semibold;
      margin-right: 10px;
      text-transform: capitalize;

      @media all and (min-width: $desktop) {
        @include fontsize(16px);
      }
    }

    .select-wrap {
      position: relative;
      border-radius: 14px;
      border: 1px solid #281651;
      width: 45px;
      height: 24px;
      cursor: pointer;

      @media all and (min-width: $desktop) {
        width: 56px;
        height: 29px;
      }

      .icon-arrow {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background: url(../../assets/images/down-arrow.svg) no-repeat;
        width: 8px;
        height: 5px;
        background-size: 8px auto;
        pointer-events: none;
      }

      select {
        color: #281651;
        font-family: $primary-fontfamily;
        @include fontsize(12px);
        font-weight: $fw-semibold;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        background: transparent;
        border: 1px solid transparent;
        width: 100%;
        padding: 4px 10px;
        cursor: pointer;

        @media all and (min-width: $desktop) {
          @include fontsize(14px);
        }
      }
    }
  }
}
