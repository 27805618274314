@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";

.claim-perm-tab {
  h1 {
    color: #281651;
    font-family: $primary-fontfamily;
    @include fontsize(20px);
    font-weight: $fw-semibold;
    margin-bottom: 25px;
    padding: 0;

    @media all and (min-width: $small-medium) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop) {
      @include fontsize(20px);
    }

    @media all and (min-width: $desktop-xl) {
      @include fontsize(24px);
    }
  }
  .container-claims {
    padding: 30px 20px 130px;

    @media all and (min-width: $small-medium) {
      padding: 20px 40px 50px;
      max-width: 630px;
    }

    @media all and (min-width: $desktop) {
      padding: 30px 40px 20px 40px;
    }

    @media all and (min-width: $desktop-xl) {
      padding: 40px 62px 174px 60px;
    }
  }
  input {
    appearance: auto;
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  .update-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 34px;

    @media all and (min-width: $small-medium) {
      right: 40px;
    }

    @media all and (min-width: $desktop) {
      height: 32px;
      width: 32px;
    }

    svg {
      @media all and (min-width: $small-medium) {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .save {
    margin-top: 20px;
    position: relative;
    &:hover {
      .update-icon {
        svg {
          width: 100%;

          path {
            fill: $blue;
          }
        }
      }
    }
  }
}
