@import "../../assets/styles/mixin.scss";
@import "../../assets/styles/variable.scss";
.footer {
  display: none;
  background: #fff;
  @media all and (min-width: $small-medium) {
    display: block;
    padding: 15px 0;
  }
  h6 {
    color: #9f9f9f;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
    letter-spacing: 0.02500000037252903px;
    .copy-right {
      font-size: 20px;
    }
  }
  .footer-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    gap: 15px;
    @media all and (min-width: $medium) {
      flex-direction: row;
      gap: 0;
    }
  }
  .footer-link-wrap{
    display: flex;
    gap: 20px;
    align-items: center;
    a{
      color: #9f9f9f;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02500000037252903px;
    }
  }
}
