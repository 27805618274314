@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";
.security-setting-wrap{
    @media all and (min-width:$small-medium){
        display: none;
    }
    .card-headings{
        background-color: $light-green;
        padding: 14px 0px;
        .container{
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            align-items: center;
    
            @media all and (min-width:$small-medium){
                padding: 0 24px;
            }
        }
        span{
            display: block;
        }
        .card-head-text{
            color: $white-color;
            @include fontsize(18px);
            font-weight: $fw-medium;
            font-family: $primary-fontfamily;
            display: block;
            margin: 0 auto;
            padding-right: 30px;
    
            @media all and (min-width:$small-medium){
                @include fontsize(30px);
                font-weight: $fw-semibold;
                color: $light-green;
            }
        }
        .back-dots-wrap{
            border: 1px solid #fff;
        }
        .back-heading{
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
    
            @media all and (min-width:$small-medium){
                display: none;
            }
        }
        .card-back-icon{
            display: block;
            width: 100%;
            height: 100%;
            background: url('../../../assets/images/card-back.svg');
            background-size: 28px 28px;
        }
        .heading-dot{
            width: 4px;
            height: 4px;
            border: 1px solid #fff;
            border-radius: 50%;
        }
        .medium-card-back{
            display: none;
            align-items: center;
            gap: 8px;
            @include fontsize(16px);
            font-weight: $fw-semibold;
            border: none;
            outline: none;
            background: none;
    
            .medium-card-icon{
                display: block;
                width: 17px;
                height: 14px;
                background: url('../../../assets/images/back.svg') no-repeat;
                background-size: 17px 14px;
            }
            @media all and (min-width:$small-medium){
                display: flex;
            }
    
        }
        @media all and (min-width:$small-medium){
            display: none;
        }
    }
    .security-switch{
        position: relative;
        display: inline-block;
        width: 53px;
        height: 29px;
        input{
            opacity: 0;
            width: 0;
            height: 0;
        }
        .slider{
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.4s;
            background-color: transparent;
            border: 1px solid #dedede;
            &::before{
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                left: 4px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #dedede;
                transition: 0.4s;
            }

            &.round{
                border-radius: 34px;
                &::before{
                    border-radius: 50%;
                }
            }
        }
    }
    
    .setting-logout{
        padding: 20px;
        background: #C8F7F9;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        @include fontsize(16px);
        font-weight: $fw-medium;
        color: $blue;
    }
    .security-text-block{
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
    .face-id-text{
        @include fontsize(16px);
        font-weight: $fw-medium;
        color: $blue;
    }
    .security-text{
        padding: 0 20px;
        width: 72%;

        p{
            @include fontsize(14px);
            font-weight: $fw-medium;
            color: $blue;
        }
    }
}