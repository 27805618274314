@import "../../../assets/styles/mixin.scss";
@import "../../../assets/styles/variable.scss";


.summary-table {
    .email {
        width: 33%;
        display: flex;
        align-items: center;

        @media all and (min-width:$small-medium) {
            justify-content: flex-start;
            width: 36%;
        }

        @media all and (min-width:$desktop) {
            width: 20%;
        }

        @media all and (min-width:$desktop-xl) {
            width: 15%;
        }
    }


    .action {
        width: 67%;
        text-align: left !important;

        @media all and (min-width:$small-medium) {
            width: 64%;
        }

        @media all and (min-width:$desktop) {
            width: 80%;
        }

        @media all and (min-width:$desktop-xl) {
            width: 85%;
        }
    }

    .table {
        .data {
            display: flex;

            &.main-head {
                .email {
                    border-radius: 10px 0 0 0;

                    @media all and (min-width:$desktop) {
                        padding: 18px 20px 20px 39px;
                        border-radius: 10px 0 0 10px;

                    }
                }

                .action {
                    border-radius: 0 10px 0 0;
                    @media all and (min-width:$desktop) {
                        border-radius: 0 10px 10px 0;

                    }
                }
            }

        }

        .main-head {
            // background: $blue;
            // border-radius: 10px 10px 0 0;

            .head {
                padding: 18px 16px;
                color: #FFF;
                font-family: $primary-fontfamily;
                @include fontsize(12px);
                font-style: normal;
                font-weight: $fw-bold;
                border-right: 0;
                flex-grow: 1;
                text-transform: uppercase;
                background: $light-green;
                text-align: left;

                &.serial-no {
                    text-align: left;
                }

                &.action {
                    padding: 17px 10px;

                    @media all and (min-width:$small-medium) {
                        padding: 17px 20px;
                    }

                    @media all and (min-width:$desktop) {
                        padding: 18px 20px 20px 20px;

                    }

                    @media all and (min-width:$desktop-xl) {
                        padding: 20px 20px 20px 70px;

                    }
                }

                @media all and (min-width:$small-medium) {
                    padding: 20px;
                    @include fontsize(14px);
                    font-weight: $fw-semibold;
                    text-transform: capitalize;
                    justify-content: flex-start;
                }



                @media all and (min-width:$desktop) {
                    @include fontsize(14px);
                    padding: 20px 20px 20px 57px;

                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(22px);
                    padding: 20px 10px;

                }

            }
        }

        .value-wrp {
            background: #CEEBEB;

            .serial-no {
                text-align: left;
            }

            .edit {
                color: #281651;
                font-family: $primary-fontfamily;
                @include fontsize(12px);
                font-style: normal;
                font-weight: $fw-semibold;
                text-decoration-line: underline;

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                }
            }

            .value {
                text-align: left;
                @include fontsize(12px);

                @media all and (min-width:$desktop) {
                    @include fontsize(16px);
                    font-weight: $fw-regular;

                }

                @media all and (min-width:$desktop-xl) {
                    @include fontsize(18px);
                }

                &:last-child {
                    border-right: 0;
                }

                &.serial-no {
                    border-right: 1px solid #ADC0CC;
                    background: #CEEBEB;

                }

                &.action {
                    background: #F2F2F2;
                    padding: 10px;

                    @media all and (min-width:$small-medium) {
                        padding: 11px 20px;
                        background: #fff;
                    }

                    @media all and (min-width:$desktop) {
                        padding: 23px 16px 23px 32px;
                    }

                    @media all and (min-width:$desktop-xl) {
                        padding: 18px 16px 18px 70px;
                    }
                }
            }

            .data {
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: #fff;
                    content: "";

                    @media all and (min-width:$small-medium) {
                        background: #EAEAEA;
                    }
                }

                &:last-child {
                    &::after {
                        display: none;
                        @media all and (min-width:$small-medium) {
                           display: block;
                        }
                    }


                    .value {
                        &:first-child {
                            border-radius: 0 0 0 0px;
                        }

                        &:last-child {
                            border-radius: 0 0 0px 0;
                        }

                        &.action {

                            @media all and (min-width:$small-medium) {
                                padding-right: 5px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .value {
            text-align: center;
            color: $blue;
            font-family: $primary-fontfamily;
            padding: 19px 16px;
            @include fontsize(12px);
            border-right: 1px solid #fff;
            background: #F2F0F5;
            font-weight: $fw-semibold;

            @media all and (min-width:$small-medium) {
                padding: 10px 16px;
                border-right: 0;
            }

            @media all and (min-width:$desktop) {
                @include fontsize(16px);
                padding: 23px 40px;
            }

            @media all and (min-width:$desktop-xl) {
                @include fontsize(18px);
            }
        }


    }

}